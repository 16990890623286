import React from "react";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Stack, IconButton, Tooltip } from "@mui/material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import axios from "axios";

const TopicTools = ({ state, dispatch, isMobile }) => {
  const handleDelete = () => {
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/api/qna/delete/topic/${state.selectedTopicItem._id}`,
      )
      .then((response) => {
        if (response.status === 200) {
          toast.success(`Topic Deleted Successfully`);
          dispatch({
            type: "set_topic_refresh_flag",
            payload: !state.topicRefreshFlag,
          });
        } else {
          toast.error("Error deleting Topics, Please Try after sometime");
        }
      })
      .catch((error) => {
        console.error("Error deleting Topics", error);

        if (error.response && [400, 404, 409, 500].includes(error.response.status)) {
          toast.error("Error deleting Topics, Please Try after sometime");
        } else {
          toast.error("Error deleting Topics, Please Contact Developer");
        }
      });
  };

  const handleDeleteConfirmation = () => {
    Swal.fire({
      title: "Confirm Deletion",
      text: "Are you sure you want to delete this topic?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete();
      } else {
        // example codes to clear up
      }
    });
  };

  const dispatchEditTopic = () => {
    dispatch({ type: "set_topic_modal", payload: true });
    dispatch({ type: "set_topic_action", payload: "edit" });
  };

  const showToastNotification = (action) => {
    toast.warning(`Please select a topic before ${action}.`);
  };

  return (
    <Stack
      direction="row"
      spacing={1}
      justifyContent="center"
      sx={{ marginLeft: isMobile ? "auto" : "75%" }}
    >
      <Tooltip title="Add Topic" arrow>
        <IconButton
          edge="end"
          aria-label="add"
          onClick={() => {
            dispatch({ type: "set_topic_modal", payload: true });
            dispatch({ type: "set_topic_action", payload: "add" });
          }}
          sx={{ color: "black" }}
          size="large"
        >
          <AddIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Edit Topic" arrow>
        <IconButton
          edge="end"
          aria-label="edit"
          onClick={() => {
            state.selectedTopicItem ? dispatchEditTopic() : showToastNotification("editing");
          }}
          sx={{ color: "black" }}
          size="large"
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete Topic" arrow>
        <IconButton
          edge="end"
          aria-label="delete"
          onClick={() => {
            state.selectedTopicItem
              ? handleDeleteConfirmation()
              : showToastNotification("deleting");
          }}
          sx={{ color: "black" }}
          size="large"
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};

export default TopicTools;

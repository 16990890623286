import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import dayjs from "dayjs";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton, Tooltip } from "@mui/material";
import styles from "../../../../commonStyles.module.css";

const HolidayTable = ({ isMobile, holidays, handleUpdate, handleDelete }) => {
  const columns = [
    {
      field: "id",
      headerName: "id",
      width: isMobile ? 120 : 150,
      headerClassName: styles["custom-header"],
    },
    {
      field: "holidayName",
      headerName: "Holiday Name",
      width: isMobile ? undefined : 220,
      flex: isMobile ? undefined : 1,
      headerClassName: styles["custom-header"],
    },
    {
      field: "date",
      headerName: "Date",
      width: 220,
      flex: isMobile ? undefined : 1,
      headerClassName: styles["custom-header"],
      valueGetter: (params) => dayjs(params.row.date).format("YYYY-MM-DD"),
    },
    {
      field: "update",
      headerName: "Actions",
      width: 200,
      flex: isMobile ? undefined : 1,
      headerClassName: styles["custom-header"],
      renderCell: (params) => (
        <div>
          <Tooltip title="Edit Holiday" arrow>
            <IconButton
              edge="end"
              aria-label="edit"
              size="large"
              onClick={() => handleUpdate(params.row)}
            >
              <EditIcon sx={{ color: "black" }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete Holiday" arrow>
            <IconButton
              edge="end"
              aria-label="edit"
              size="large"
              onClick={() => handleDelete(params.row._id)}
            >
              <DeleteIcon sx={{ color: "black" }} />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  const rows =
    holidays &&
    holidays.map((holiday, index) => ({
      // id: index + 1,
      // holidayId: holiday._id,
      // holidayName: holiday.holidayName,
      // date: holiday.date,
      ...holiday,
    }));

  console.log("holiday rows", holidays);

  return (
    <div
      style={{
        height: 400,
        width: "90%",
        margin: "auto",
        justifyContent: "center",
        background: "white",
      }}
    >
      <DataGrid
        rows={rows || [{ id: "no data available", noData: true }]}
        columns={columns}
        initialState={
          {
            // pagination: {
            //   paginationModel: { page: 0, pageSize: 5 },
            // },
          }
        }
        sx={{
          backgroundColor: "white", // Set transparent background color
          "& .MuiDataGrid-columnHeader, & .MuiDataGrid-row": {
            fontWeight: 600,
          },
          "& .MuiDataGrid-cell": {
            color: "black", // Set text color of cell data
          },
        }}
        pageSizeOptions={[6]}
      />
    </div>
  );
};

export default HolidayTable;

// const headerStyles = `
//   .custom-header {
//     background-color: #CE3C73; /* Set the background color for the header */
//     color: white; /* Set the text color for the header */
//     font-weight: bold; /* Set the font weight for the header text */
//     font-size: 16px; /* Set the font size for the header text */
//   }
// `;

// // Inject the styles into the component
// const styleElement = document.createElement("style");
// styleElement.innerHTML = headerStyles;
// document.head.appendChild(styleElement);

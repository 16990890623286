import React from "react";
import { TextField, Button, Grid, FormControl, InputAdornment, Link } from "@mui/material";
import "react-perfect-scrollbar/dist/css/styles.css";
import PhonelinkLockRoundedIcon from "@mui/icons-material/PhonelinkLockRounded";
import { toast } from "react-toastify";
import axios from "axios";
import { DNA } from "react-loader-spinner";
import { motion } from "framer-motion";
import countries from "../../../../../country.json";

const AddNewOfflineUserOTP = ({
  userData,
  setUserData,
  otp,
  setOtp,
  loading,
  setLoading,
  setComponent,
  modalClose,
  variants,
  isMobile,
}) => {
  const handleLogin = async () => {
    if (!otp) {
      toast.error("Please enter the valid verification code sent");

      return;
    } else if (!/^\d{6}$/.test(otp)) {
      toast.error("Please ensure the verification code consists of 6 digits.");
      return;
    }

    setLoading(true);

    try {
      await axios
        .post(`${process.env.REACT_APP_API_URL}/api/users/post/new/user/otp/verification`, {
          userDetails: {
            name: userData.name,
            countryCode: userData.countryCode,
            mobileNumber: userData.mobileNumber,
            email: userData.email,
            gender: userData.gender,
            age: userData.age,
            otpCode: otp,
            mode: "offline",
          },
        })
        .then((response) => {
          if (response && response.status === 201) {
            setLoading(false);

            toast.success("Congratulations! You've successfully added user");

            setUserData({
              name: "",
              mobileNumber: "",
              email: "",
              gender: "",
              age: "",
              countryCode: "+91",
              selectedCountry: countries.find((country) => country.name === "India"),
            });

            modalClose();
          }
          return response;
        })
        .catch((error) => {
          if (error && error.response && error.response.status === 400) {
            toast.error("Verification code did not match. Please try again and consider resending");
          } else {
            console.error("Error sending OTP:", error);
            toast.error("An error occurred while sending OTP. Please try again later.");
          }
        });
      setLoading(false);
    } catch (error) {
      setLoading(false);

      console.error("Error sending OTP:", error);
      toast.error("An error occurred while sending OTP. Please try again later.");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleLogin();
  };

  const handleResendOTP = async () => {
    try {
      await axios
        .post(`${process.env.REACT_APP_API_URL}/api/users/post/request/otp`, {
          userDetail: { countryCode: userData.countryCode, mobileNumber: userData.mobileNumber },
        })
        .then((response) => {
          if (response && response.status === 200) {
            toast.success("The verification code has been sent to your mobile number");
            setOtp("");
          }

          return response;
        })
        .catch((error) => {
          toast.error("An error occurred while sending OTP. Please try again later.");
        });
    } catch (error) {
      console.error("Error sending OTP:", error);
    }
  };

  const handlePreviousStep = () => {
    setComponent("fields component");
  };

  if (loading) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
          <Grid item>
            <DNA
              visible={true}
              height={isMobile ? "120" : "200"}
              width={isMobile ? "200" : "250"}
              color="#2B2A29"
              ariaLabel="dna-loading"
              wrapperStyle={{}}
              wrapperClass="dna-wrapper"
            />
          </Grid>
        </Grid>
      </motion.div>
    );
  }

  return (
    <FormControl onSubmit={handleSubmit}>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} sm={6}>
          <motion.div variants={variants} initial="hidden" animate="visible">
            <TextField
              placeholder="Verification Code"
              id="verification-code"
              variant="standard"
              value={otp}
              onChange={(e) => {
                const newValue = e.target.value.replace(/\D/g, "").slice(0, 6);
                setOtp(newValue);
              }}
              onKeyPress={
                isMobile
                  ? undefined
                  : (e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        handleLogin();
                      }
                    }
              }
              fullWidth
              required
              autoComplete="off"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PhonelinkLockRoundedIcon fontSize="large" sx={{ color: "black" }} />
                  </InputAdornment>
                ),
                style: { fontSize: "1.5rem" },
              }}
              InputLabelProps={{ style: { color: "black" } }}
              sx={{
                "& .MuiInput-underline:after": {
                  borderBottomColor: "black",
                },
              }}
            />
          </motion.div>
        </Grid>

        <Grid item xs={12}>
          <Grid container justifyContent="center" alignItems="center">
            <Link
              component="button"
              onClick={handleResendOTP}
              style={{
                cursor: "pointer",
                color: "black",
                textDecoration: "underline",
                fontSize: "1.2rem",
              }}
            >
              Resend verification code
            </Link>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Grid container justifyContent="center" alignItems="center">
            <motion.div variants={variants} initial="hidden" animate="visible">
              <Button
                variant="contained"
                fullWidth
                type="submit"
                onClick={handleLogin}
                style={{
                  background: "#2B2A29",
                  borderRadius: "40px",
                  fontSize: isMobile ? "1rem" : "1.3rem",
                  textTransform: "capitalize",
                }}
              >
                Verify Code and Proceed
              </Button>
            </motion.div>
          </Grid>
        </Grid>

        <Grid item xs={12} sx={{ mt: isMobile ? 2 : 5 }}>
          <Grid container justifyContent="center" alignItems="center">
            <Link
              component="button"
              onClick={handlePreviousStep}
              style={{
                cursor: "pointer",
                color: "black",
                textDecoration: "underline",
                fontSize: isMobile ? "1.2rem" : "1.4rem",
              }}
            >
              If you believe you've entered incorrect data, you can go back.
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </FormControl>
  );
};

export default AddNewOfflineUserOTP;

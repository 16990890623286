import React, { useState, useEffect } from "react";
import {
  List,
  ListItem,
  ListItemText,
  TextField,
  Button,
  Typography,
  IconButton,
  Stack,
  Tooltip,
} from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import { DNA } from "react-loader-spinner";
import { motion } from "framer-motion";

const MedicineTypeList = ({ isMobile }) => {
  const [medicineType, setMedicineType] = useState([]);
  const [textFieldValue, setTextFieldValue] = useState("");
  const [selectedId, setSelectedId] = useState(null);
  const [action, setAction] = useState("add");
  const [medicinesLoading, setMedicinesLoading] = useState(true);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/medicine-type/get/all`,
      );

      if (response && response.status === 200) {
        if (response.data && response.data.data) {
          setMedicineType(response.data.data);
        } else {
          setMedicineType([]);
          console.error("Error fetching medicine type");
        }
      } else {
        setMedicineType([]);
        console.error("Error fetching medicine type");
      }

      setMedicinesLoading(false);
    } catch (error) {
      setMedicineType([]);
      console.error("Error fetching medicine type:", error);
      setMedicinesLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleCancel = () => {
    setTextFieldValue("");
    setSelectedId("");
    setAction("add");
  };

  const handleTextFieldChange = (event) => {
    setTextFieldValue(event.target.value);
  };

  const handleButtonClick = async () => {
    if (action === "add") {
      if (!textFieldValue) {
        toast.error("Please enter a medicine type first");
        return;
      }

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/medicine-type/post/new`,
          {
            medicineTypeName: textFieldValue,
          },
        );

        if (response && response.status === 201) {
          toast.success("Medicine Type Added Successfully");
          handleCancel();
          fetchData();
        }
      } catch (error) {
        if (error.response && error.response.status === 409) {
          console.error("Error adding medicine type:", error.response.data.message);
          toast.error("Entered Medicine Type Already Exists");
        } else {
          console.error("Error adding medicine type:", error);
          toast.error("Error adding medicine type");
        }
      }
    } else if (action === "edit") {
      if (!textFieldValue) {
        toast.error("Please fill medicine type first");
        return;
      }

      // Check if the text field value has changed
      if (
        textFieldValue === medicineType.find((item) => item._id === selectedId)?.medicineTypeName
      ) {
        toast.error("No changes detected.");
        return;
      }

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/medicine-type/post/selected/${selectedId}`,
          {
            medicineTypeName: textFieldValue,
          },
        );

        if (response.status === 200) {
          toast.success("Medicine Type Updated Successfully");
          handleCancel();
          fetchData();
        }
      } catch (error) {
        if (error.response && error.response.status === 409) {
          console.error("Error adding medicine type:", error.response.data.message);
          toast.error("Entered Medicine Type Already Exists");
        } else {
          console.error("Error adding medicine type:", error);
          toast.error("Error adding medicine type");
        }
      }
    }
  };

  const handleEdit = (item) => {
    if (item) {
      setSelectedId(item._id);
      setTextFieldValue(item.medicineTypeName);
      setAction("edit");
    }
  };

  const handleDelete = (item) => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}/api/medicine-type/delete/selected/${item._id}`)
      .then((response) => {
        if (response.status === 200) {
          toast.success(`Medicine Type Deleted Successfully`);
          fetchData();
        }
      })
      .catch((error) => {
        // Handle error, show error message, etc.
        console.error("Error deleting category group", error);
      });
  };

  const handleDeleteConfirmation = (item) => {
    handleCancel();

    Swal.fire({
      title: "Confirm Deletion",
      text: "Are you sure you want to delete this medicine type?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(item);
      } else {
        // example codes to clear up
      }
    });
  };

  return (
    <>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={2}
        alignItems="center"
        justifyContent="center"
        style={{
          position: "sticky",
          top: 0,
          background: "#A52D59",
          zIndex: 1,
          padding: "12px",
        }}
      >
        <TextField
          margin="dense"
          id="medicine type"
          type="text"
          label="Medicine Type"
          required
          value={textFieldValue}
          onChange={handleTextFieldChange}
          placeholder="Enter Medicine Type"
          InputLabelProps={{ style: { color: "white" } }}
          autoComplete="off"
          sx={{
            "& input": {
              color: "white",
            },
            "& .MuiOutlinedInput-root.Mui-focused": {
              borderColor: "white",
              "& fieldset": {
                borderColor: "white",
              },
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "white",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "white",
            },
          }}
          fullWidth
        />
        <Stack direction="row">
          <Button
            variant="contained"
            onClick={handleButtonClick}
            sx={{
              ml: 2,
              backgroundColor: "white",
              color: "darkblue",
              ":hover": {
                backgroundColor: "#e0e0e0",
                color: "darkblue",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              },
            }}
            size="large"
          >
            {action === "add" ? "Add" : "Update"}
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={handleCancel}
            sx={{ ml: 2 }}
            size="large"
          >
            Cancel
          </Button>
        </Stack>
      </Stack>
      {medicinesLoading ? (
        <motion.div>
          <DNA
            visible={true}
            height={isMobile ? "120" : "200"}
            width={isMobile ? "200" : "250"}
            color="#2B2A29"
            ariaLabel="dna-loading"
            wrapperStyle={{}}
            wrapperClass="dna-wrapper"
          />
        </motion.div>
      ) : (
        <List>
          {medicineType &&
            medicineType.map((item, index) => (
              <ListItem
                component="div"
                key={index}
                sx={{
                  backgroundColor: "#C47F9F",
                  borderRadius: 5,
                  "&:hover": {
                    backgroundColor: "#B54A7C",
                    cursor: "pointer",
                  },
                  userSelect: "none",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingRight: "10px",
                  padding: "10px",
                  marginBottom: "5px",
                }}
              >
                <ListItemText
                  primary={
                    <Typography
                      sx={{
                        ml: 2,
                        fontSize: isMobile ? "1rem" : "1.3rem",
                        color: "white",
                      }}
                    >
                      {item.medicineTypeName}
                    </Typography>
                  }
                />
                <div>
                  <Tooltip title="Edit Medical Type" arrow>
                    <IconButton
                      onClick={() => {
                        handleEdit(item);
                      }}
                    >
                      <EditIcon sx={{ color: "white" }} />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Delete Medical Type" arrow>
                    <IconButton
                      onClick={() => {
                        handleDeleteConfirmation(item);
                      }}
                    >
                      <DeleteIcon sx={{ color: "white" }} />
                    </IconButton>
                  </Tooltip>
                </div>
              </ListItem>
            ))}
          {medicineType && medicineType.length === 0 && (
            <ListItem
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "60vh",
                textAlign: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "1.6rem",
                  textAlign: "center",
                }}
              >
                No Medicine Type Data Found
              </Typography>
            </ListItem>
          )}
        </List>
      )}
    </>
  );
};

export default MedicineTypeList;

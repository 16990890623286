import React from "react";
import { Grid, TextField } from "@mui/material";

const PharmaCompaniesText = ({ state, dispatch }) => {
  return (
    <>
      <Grid item xs={12} sm={6} md={3} lg={4}>
        <TextField
          margin="dense"
          id="company name"
          type="text"
          required
          label="Company Name"
          value={state.companyName}
          onChange={(e) => {
            const newValue = e.target.value.toLowerCase().replace(/\s/g, ""); // Convert to lowercase and remove spaces
            dispatch({
              type: "set_company_name",
              payload: newValue,
            });
          }}
          placeholder="Company Name"
          fullWidth
          InputLabelProps={{ style: { color: "white" } }}
          autoComplete="off"
          sx={{
            "& input": {
              color: "white",
            },
            "& .MuiOutlinedInput-root.Mui-focused": {
              borderColor: "white",
              "& fieldset": {
                borderColor: "white",
              },
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "white",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "white",
            },
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={4}>
        <TextField
          margin="dense"
          id="company address"
          type="text"
          required
          label="Company Address"
          value={state.companyAddress}
          onChange={(e) => {
            dispatch({
              type: "set_company_address",
              payload: e.target.value,
            });
          }}
          placeholder="Company Address"
          fullWidth
          InputLabelProps={{ style: { color: "white" } }}
          autoComplete="off"
          sx={{
            "& input": {
              color: "white",
            },
            "& .MuiOutlinedInput-root.Mui-focused": {
              borderColor: "white",
              "& fieldset": {
                borderColor: "white",
              },
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "white",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "white",
            },
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={4}>
        <TextField
          margin="dense"
          id="company contact name"
          type="text"
          required
          label="Company Contact Name"
          value={state.companyContactName}
          onChange={(e) => {
            dispatch({
              type: "set_company_contact_name",
              payload: e.target.value,
            });
          }}
          placeholder="Company Contact Name"
          fullWidth
          InputLabelProps={{ style: { color: "white" } }}
          autoComplete="off"
          sx={{
            "& input": {
              color: "white",
            },
            "& .MuiOutlinedInput-root.Mui-focused": {
              borderColor: "white",
              "& fieldset": {
                borderColor: "white",
              },
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "white",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "white",
            },
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={4}>
        <TextField
          margin="dense"
          id="company contact number"
          type="text"
          required
          label="Company Contact Number"
          value={state.companyContactNumber}
          onChange={(e) => {
            const newValue = e.target.value.replace(/\D/g, "").slice(0, 10); // Remove non-digit characters and limit to 10 digits

            dispatch({
              type: "set_company_contact_number",
              payload: newValue,
            });
          }}
          placeholder="Company Contact Number"
          fullWidth
          InputLabelProps={{ style: { color: "white" } }}
          autoComplete="off"
          sx={{
            "& input": {
              color: "white",
            },
            "& .MuiOutlinedInput-root.Mui-focused": {
              borderColor: "white",
              "& fieldset": {
                borderColor: "white",
              },
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "white",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "white",
            },
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={4}>
        <TextField
          margin="dense"
          id="company contact email"
          type="text"
          required
          label="Company Contact Email"
          value={state.companyContactMail}
          onChange={(e) => {
            const newValue = e.target.value.toLowerCase().replace(/\s/g, ""); // Convert to lowercase and remove spaces
            dispatch({
              type: "set_company_contact_mail",
              payload: newValue,
            });
          }}
          placeholder="Company Contact Email"
          fullWidth
          InputLabelProps={{ style: { color: "white" } }}
          autoComplete="off"
          sx={{
            "& input": {
              color: "white",
            },
            "& .MuiOutlinedInput-root.Mui-focused": {
              borderColor: "white",
              "& fieldset": {
                borderColor: "white",
              },
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "white",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "white",
            },
          }}
        />
      </Grid>
    </>
  );
};

export default PharmaCompaniesText;

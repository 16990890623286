/* eslint-disable no-unused-vars */
import React, { useEffect, useReducer, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PharmaCompaniesText from "./PharmaCompaniesText";
import axios from "axios";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { DNA } from "react-loader-spinner";
import { motion } from "framer-motion";

const initialState = {
  companyName: "",
  companyAddress: "",
  companyContactName: "",
  companyContactNumber: "",
  companyContactMail: "",
  action: "add",
  companyDetail: "",
  companyDetailFlag: false,
  selectedId: "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "set_company_name":
      return { ...state, companyName: action.payload };
    case "set_company_address":
      return { ...state, companyAddress: action.payload };
    case "set_company_contact_name":
      return { ...state, companyContactName: action.payload };
    case "set_company_contact_number":
      return { ...state, companyContactNumber: action.payload };
    case "set_company_contact_mail":
      return { ...state, companyContactMail: action.payload };
    case "set_action":
      return { ...state, action: action.payload };
    case "set_company_detail":
      return { ...state, companyDetail: action.payload };
    case "set_company_detail_flag":
      return { ...state, companyDetailFlag: action.payload };
    case "set_selected_Id":
      return { ...state, selectedId: action.payload };
    default:
      return state;
  }
};

const PharmaCompaniesList = ({ isMobile }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/pharma-company/get/all`,
        );

        if (response && response.status === 200) {
          if (response.data && response.data.data) {
            dispatch({
              type: "set_company_detail",
              payload: response.data.data,
            });
          } else {
            dispatch({
              type: "set_company_detail",
              payload: null,
            });
            console.error("Error fetching pharma company details");
          }
        } else {
          dispatch({
            type: "set_company_detail",
            payload: null,
          });
          console.error("Error fetching pharma company details");
        }
        setLoading(false);
      } catch (error) {
        dispatch({
          type: "set_company_detail",
          payload: null,
        });
        console.error("Error fetching pharma company details:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [state.companyDetailFlag]);

  const clearState = () => {
    dispatch({
      type: "set_company_name",
      payload: "",
    });
    dispatch({
      type: "set_company_address",
      payload: "",
    });
    dispatch({
      type: "set_company_contact_name",
      payload: "",
    });
    dispatch({
      type: "set_company_contact_number",
      payload: "",
    });
    dispatch({
      type: "set_company_contact_mail",
      payload: "",
    });
    dispatch({
      type: "set_action",
      payload: "add",
    });
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhoneNumber = (phoneNumber) => {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phoneNumber);
  };

  const handleEdit = (data) => {
    dispatch({
      type: "set_selected_Id",
      payload: data.companyId,
    });

    dispatch({
      type: "set_action",
      payload: "edit",
    });

    dispatch({
      type: "set_company_name",
      payload: data.companyName,
    });
    dispatch({
      type: "set_company_address",
      payload: data.companyAddress,
    });
    dispatch({
      type: "set_company_contact_name",
      payload: data.contactName,
    });
    dispatch({
      type: "set_company_contact_number",
      payload: data.contactNumber,
    });
    dispatch({
      type: "set_company_contact_mail",
      payload: data.companyEmail,
    });
  };

  const handleDeleteCompany = (item) => {
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/api/pharma-company/delete/selected/${item.companyId}`,
      )
      .then((response) => {
        if (response.status === 200) {
          toast.success("Pharma Company Deleted Successfully");
          dispatch({
            type: "set_company_detail_flag",
            payload: !state.companyDetailFlag,
          });
        }
      })
      .catch((error) => {
        // Handle error, show error message, etc.
        console.error("Error deleting medicine delivery", error);
      });
  };

  // Handle deleting an item
  const handleDelete = (data) => {
    clearState();

    Swal.fire({
      title: "Confirm Deletion",
      text: "Are you sure you want to delete this pharma company?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDeleteCompany(data);
      } else {
        // example codes to clear up
      }
    });
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      responsive: true,
    },
    {
      field: "companyName",
      headerName: "Company Name",
      width: isMobile ? 250 : undefined,
      flex: isMobile ? undefined : 1,
      responsive: true,
    },
    {
      field: "companyAddress",
      headerName: "Company Address",
      width: isMobile ? 250 : undefined,
      flex: isMobile ? undefined : 1,
      responsive: true,
    },
    {
      field: "contactName",
      headerName: "Contact Name",
      width: isMobile ? 200 : undefined,
      flex: isMobile ? undefined : 1,
      responsive: true,
    },
    {
      field: "contactNumber",
      headerName: "Contact Number",
      width: isMobile ? 200 : undefined,
      flex: isMobile ? undefined : 1,
      responsive: true,
    },
    {
      field: "companyEmail",
      headerName: "Contact Mail",
      description: "This column has a value getter and is not sortable.",
      width: isMobile ? 200 : undefined,
      flex: isMobile ? undefined : 1,
      responsive: true,
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      width: isMobile ? 100 : 120,
      responsive: false,
      renderCell: (params) => (
        <div>
          <Tooltip title="Edit Pharma Company" arrow>
            <IconButton aria-label="edit" onClick={() => handleEdit(params.row)}>
              <EditIcon sx={{ color: "black" }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete Pharma Company" arrow>
            <IconButton aria-label="delete" onClick={() => handleDelete(params.row)}>
              <DeleteIcon sx={{ color: "black" }} />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  const rowsWithHandlers =
    state.companyDetail &&
    state.companyDetail.map((row, index) => ({
      id: index + 1,
      companyId: row._id,
      companyName: row.companyName,
      companyAddress: row.companyAddress,
      contactName: row.contactName,
      contactNumber: row.contactNumber,
      companyEmail: row.companyEmail,
      handleEdit: handleEdit,
      handleDelete: handleDelete,
    }));

  let gridContent;

  gridContent = (
    <DataGrid
      rows={rowsWithHandlers || [{ id: "no data available", noData: true }]}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: { page: 0, pageSize: 5 },
        },
      }}
      sx={{
        backgroundColor: "rgba(240, 240, 240, 0.7)", // Set transparent background color
        "& .MuiDataGrid-columnHeader, & .MuiDataGrid-row": {
          fontSize: isMobile ? undefined : "1.1rem",
        },
        "& .MuiDataGrid-cell": {
          color: "black", // Set text color of cell data
        },
      }}
      pageSizeOptions={[5, 10]}
    />
  );

  const handleButtonClick = async () => {
    if (
      !state.companyName ||
      !state.companyAddress ||
      !state.companyContactName ||
      !state.companyContactNumber ||
      !state.companyContactMail
    ) {
      toast.error("Please fill in all fields correctly.");
      return;
    }

    if (!validateEmail(state.companyContactMail)) {
      toast.error("Please insert a valid email.");
      return;
    }

    if (!validatePhoneNumber(state.companyContactNumber)) {
      toast.error("Please insert a valid phone number.");
      return;
    }

    const pharmaCompanyDetail = {
      pharmaCompanyDetail: {
        companyName: state.companyName,
        companyAddress: state.companyAddress,
        contactName: state.companyContactName,
        contactNumber: state.companyContactNumber,
        companyEmail: state.companyContactMail,
      },
    };

    if (state.action === "add") {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/pharma-company/post/new`,
          pharmaCompanyDetail,
        );

        if (response && response.status === 201) {
          toast.success("Pharma Company Added Successfully");
          clearState();

          dispatch({
            type: "set_company_detail_flag",
            payload: !state.companyDetailFlag,
          });
        }
      } catch (error) {
        console.error("Error adding pharma company:", error);
        toast.error("Error adding pharma company");
      }
    } else if (state.action === "edit") {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/pharma-company/post/selected/${state.selectedId}`,
          pharmaCompanyDetail,
        );

        if (response && response.status === 200) {
          toast.success("Pharma Company Edited Successfully");
          clearState();

          dispatch({
            type: "set_company_detail_flag",
            payload: !state.companyDetailFlag,
          });
        }
      } catch (error) {
        console.error("Error adding pharma company:", error);
        toast.error("Error adding pharma company");
      }
    }
  };

  const handleCancel = () => {
    clearState();
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        alignItems="center"
        style={{
          position: "sticky",
          top: 0,
          background: "#A52D59",
          zIndex: 1,
          padding: "5px",
        }}
      >
        <PharmaCompaniesText state={state} dispatch={dispatch} />
        <Grid item sx={{ ml: "auto" }}>
          <Button
            variant="contained"
            onClick={handleButtonClick}
            sx={{
              ml: 2,
              backgroundColor: "white",
              color: "darkblue",
              ":hover": {
                backgroundColor: "#e0e0e0",
                color: "darkblue",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              },
            }}
            size="large"
          >
            {state.action === "add" ? "Add" : "Update"}
          </Button>
          <Button
            variant="contained"
            color="error"
            sx={{ ml: 2 }}
            size="large"
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>

      {loading ? (
        <motion.div>
          <DNA
            visible={true}
            height={isMobile ? "120" : "200"}
            width={isMobile ? "200" : "250"}
            color="#2B2A29"
            ariaLabel="dna-loading"
            wrapperStyle={{}}
            wrapperClass="dna-wrapper"
          />
        </motion.div>
      ) : (
        <>
          {state.companyDetail && state.companyDetail.length > 0 ? (
            <div style={{ width: "100%", marginTop: 30 }}>{gridContent}</div>
          ) : (
            <Typography fontSize="1.6rem" color="black" align="center" mt="15%">
              No Pharma Companies Found
            </Typography>
          )}
        </>
      )}
    </>
  );
};

export default PharmaCompaniesList;

import React from "react";
import { DemoPaper } from "../../DemoPaper";
import { motion } from "framer-motion";
import { Stack } from "@mui/material";

const MedicineQuotes = ({ isMobile }) => {
  const containerStyle = {
    marginLeft: isMobile ? "5%" : "25%",
    marginBottom: isMobile ? "15%" : "3%",
    marginRight: "5%",
    marginTop: isMobile ? "25%" : "5%",
    transition: "margin 0.5s",
    zIndex: 3,
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Stack direction="column" alignItems="center" style={containerStyle}>
        <DemoPaper
          height={isMobile ? "120vh" : "80vh"}
          square={false}
          elevation={24}
          isMobile={isMobile}
        >
          MedicineQuotes
        </DemoPaper>
      </Stack>
    </motion.div>
  );
};

export default MedicineQuotes;

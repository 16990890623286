import React from "react";
import { styled, Box, Grid, Link, Typography } from "@mui/material";

const Item = styled("div")(({ theme }) => ({
  backgroundColor: "rgba(255, 255, 255, 0)",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  display: "flex", // Use flexbox
  alignItems: "center", // Vertically align items
  justifyContent: "space-between", // Horizontally space items
  flexDirection: "column", // Stack items vertically
  textAlign: "center", // Center contents
}));

const ContactInfo = ({ isMobile }) => {
  const phoneNumber = "+919588440361";
  const instaID = "7dliving";
  const mailID = "contact@7dliving";

  return (
    <Box>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        spacing={2}
        marginBottom={isMobile ? "5px" : undefined}
      >
        <Grid item xs={4}>
          <Item>
            <img
              src={`${process.env.PUBLIC_URL}/images/contact/WhatsApp.png`}
              style={{
                width: isMobile ? "40%" : "50%", // Adjusted icon size for mobile
                height: isMobile ? "40%" : "50%", // Adjusted icon size for mobile
                objectFit: "cover",
              }}
              alt="WhatsApp Icon"
            />
            <Typography variant={isMobile ? "body2" : "body1"} sx={{ marginTop: "2px" }}>
              <Link
                href={`tel:${phoneNumber}`}
                target="_blank"
                rel="noopener noreferrer"
                color="inherit"
                sx={{ textDecoration: "none" }}
              >
                {phoneNumber}
              </Link>
            </Typography>
          </Item>
        </Grid>
        <Grid item xs={4}>
          <Item>
            <img
              src={`${process.env.PUBLIC_URL}/images/contact/Instagram.png`}
              style={{
                width: isMobile ? "40%" : "50%",
                height: isMobile ? "40%" : "50%",
                objectFit: "cover",
              }}
              alt="Instagram Icon"
            />
            <Typography variant={isMobile ? "body2" : "body1"} sx={{ marginTop: "2px" }}>
              <Link
                href={`https://www.instagram.com/${instaID}`}
                target="_blank"
                rel="noopener noreferrer"
                color="inherit"
                sx={{ textDecoration: "none" }}
              >
                @{instaID}
              </Link>
            </Typography>
          </Item>
        </Grid>
        <Grid item xs={4}>
          <Item>
            <img
              src={`${process.env.PUBLIC_URL}/images/contact/Email.png`}
              style={{
                width: isMobile ? "40%" : "50%",
                height: isMobile ? "40%" : "50%",
                objectFit: "cover",
              }}
              alt="Email Icon"
            />
            <Typography variant={isMobile ? "body2" : "body1"} sx={{ marginTop: "2px" }}>
              <Link
                href={`mailto:${mailID}.com`}
                target="_blank"
                rel="noopener noreferrer"
                color="inherit"
                sx={{ textDecoration: "none" }}
              >
                {mailID}
              </Link>
            </Typography>
          </Item>
        </Grid>
      </Grid>
      <Typography
        variant="body2"
        align="center"
        color="textSecondary"
        sx={{ mt: 1, mb: isMobile ? 8 : 2 }}
      >
        © {new Date().getFullYear()} 7D living. All rights reserved.
      </Typography>
    </Box>
  );
};

export default ContactInfo;

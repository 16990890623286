/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useReducer } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import CategoryList from "./Category/CategoryList";
import Questions from "./Questions/Questions";
import AddEditQuestionsModal from "./AddEditQuestionsModal";
import QuestionsList from "./Questions/QuestionsList";
import { useScroll } from "../../../ScrollContext";
import { Stack, Typography } from "@mui/material";
import { DemoPaper } from "../DemoPaper";

const initialQuestion = {
  prompt: "",
  questiontext: "",
  questionimage: "",
  answers: [
    {
      answer: "",
      answerimage: "",
      results: [{ result: "", value: "" }],
    },
  ],
};

const initialState = {
  questionModal: false,
  questionAction: "add",
  selectedCategoryItem: "",
  selectedQuestionItem: "",
  questionsRefreshFlag: false,
  questions: initialQuestion,
  questionImageURL: "",
  answerImageURL: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case "set_question_modal":
      return { ...state, questionModal: action.payload };
    case "set_question_action":
      return { ...state, questionAction: action.payload };
    case "set_selected_category_item":
      return { ...state, selectedCategoryItem: action.payload };
    case "set_selected_question_item":
      return { ...state, selectedQuestionItem: action.payload };
    case "set_questions_refresh_flag":
      return { ...state, questionsRefreshFlag: action.payload };
    case "set_questions":
      return { ...state, questions: action.payload };
    case "set_question_image_url":
      return { ...state, questionImageURL: action.payload };
    case "set_answer_image_url":
      return { ...state, answerImageURL: action.payload };
    default:
      return state;
  }
};

const QaEditor = ({ isMobile }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { scrollToTop } = useScroll();

  useEffect(() => {
    scrollToTop();
  }, []);

  const containerStyle = {
    marginLeft: isMobile ? "5%" : "25%",
    marginBottom: isMobile ? "15%" : "3%",
    marginRight: "5%",
    marginTop: isMobile ? "25%" : "8%",
    transition: "margin 0.5s",
    zIndex: 3,
  };

  return (
    <div style={containerStyle}>
      <Stack direction="column" spacing={4} alignItems="center">
        <DemoPaper
          height={isMobile ? "70vh" : "50vh"}
          square={false}
          elevation={24}
          isMobile={isMobile}
        >
          <Typography
            variant="h6"
            align="left"
            sx={{
              fontFamily: "Monsterrat",
              fontSize: "2rem",
            }}
          >
            Category
          </Typography>
          <AddEditQuestionsModal state={state} dispatch={dispatch} isMobile={isMobile} />

          <PerfectScrollbar options={{ wheelPropagation: false }}>
            <CategoryList state={state} dispatch={dispatch} isMobile={isMobile} />
          </PerfectScrollbar>
        </DemoPaper>

        <DemoPaper
          height={isMobile ? "120vh" : "70vh"}
          square={false}
          elevation={24}
          isMobile={isMobile}
        >
          <Typography
            variant="h6"
            align="left"
            sx={{
              fontFamily: "Monsterrat",
              fontSize: "2rem",
            }}
          >
            Questions
          </Typography>
          <Questions state={state} dispatch={dispatch} isMobile={isMobile} />
          <PerfectScrollbar options={{ wheelPropagation: false }}>
            <QuestionsList state={state} dispatch={dispatch} isMobile={isMobile} />
          </PerfectScrollbar>
        </DemoPaper>
      </Stack>
    </div>
  );
};

export default QaEditor;

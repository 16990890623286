/* eslint-disable no-unused-vars */
import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { IconButton, Tooltip } from "@mui/material";

const MedicineTable = ({
  medicineData,
  refreshFlag,
  setRefreshFlag,
  refresh,
  handleEdit,
  isMobile,
}) => {
  const handleDeleteMedicine = (id) => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}/api/medicine/delete/selected/${id}`)
      .then((response) => {
        if (response.status === 200) {
          toast.success(`Medicine Deleted Successfully`);

          setRefreshFlag(!refreshFlag);
        }
      })
      .catch((error) => {
        // Handle error, show error message, etc.
        console.error("Error deleting medicine delivery", error);
      });
  };

  const handleDelete = (item) => {
    refresh();

    Swal.fire({
      title: "Confirm Deletion",
      text: "Are you sure you want to delete this medicine?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDeleteMedicine(item);
      } else {
        // example codes to clear up
      }
    });
  };

  const rowsWithHandlers =
    medicineData &&
    medicineData.map((row, index) => ({
      ...row,
      handleEdit: () => handleEdit(row),
      handleDelete: () => handleDelete(row.medicineId),
    }));

  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "type",
      headerName: "Medicine Type",
      width: isMobile ? 200 : undefined,
      flex: isMobile ? undefined : 1,
    },
    {
      field: "delivery",
      headerName: "Medicine Delivery",
      width: isMobile ? 200 : undefined,
      flex: isMobile ? undefined : 1,
    },
    {
      field: "englishName",
      headerName: "Medicine Name (E)",
      width: isMobile ? 200 : undefined,
      flex: isMobile ? undefined : 1,
    },
    {
      field: "sanskritName",
      headerName: "Medicine Name (S)",
      width: isMobile ? 200 : undefined,
      flex: isMobile ? undefined : 1,
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      width: 130,
      renderCell: (params) => (
        <div>
          <Tooltip title="Edit Medicine" arrow>
            <IconButton aria-label="edit" onClick={params.row.handleEdit}>
              <EditIcon sx={{ color: "black" }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete Medicine" arrow>
            <IconButton aria-label="delete" onClick={params.row.handleDelete}>
              <DeleteIcon sx={{ color: "black" }} />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <div style={{ width: "100%" }}>
      <DataGrid
        rows={rowsWithHandlers}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        sx={{
          backgroundColor: "rgba(240, 240, 240, 0.7)", // Set transparent background color
          "& .MuiDataGrid-columnHeader, & .MuiDataGrid-row": {
            fontSize: isMobile ? undefined : "1.1rem",
          },
          "& .MuiDataGrid-cell": {
            color: "black", // Set text color of cell data
          },
        }}
      />
    </div>
  );
};

export default MedicineTable;

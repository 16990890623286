/* eslint-disable no-unused-vars */
import React, { useState, useReducer, useEffect } from "react";
import { Breadcrumbs, Button, Tooltip, Stack } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import DoctorsTable from "./DoctorsDetails/DoctorsTable";
import AddEditDoctorsModal from "./DoctorsDetails/AddEditDoctorsModal";
import ViewDoctor from "./DoctorsDetails/ViewDoctor/ViewDoctor";
import { useScroll } from "../../../ScrollContext";
import { DemoPaper } from "../DemoPaper";
import { Position } from "@react-pdf-viewer/core";

const initialState = {
  doctor: "view doctor",
  doctorLabel: ["Doctors"],
  doctorAction: "add",
  gender: "",
  selectedDoctor: null,
  selectedDoctorId: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "set_doctor":
      return { ...state, doctor: action.payload };
    case "set_doctor_label":
      return { ...state, doctorLabel: action.payload };
    case "set_doctor_action":
      return { ...state, doctorAction: action.payload };
    case "set_gender":
      return { ...state, gender: action.payload };
    case "set_selected_doctor":
      return { ...state, selectedDoctor: action.payload };
    // Add a new action type to set the selected doctor ID
    case "set_selected_doctor_id":
      return { ...state, selectedDoctorId: action.payload };
    default:
      return state;
  }
};

const Doctors = ({ isMobile }) => {
  const [openAddModal, setOpenAddModal] = useState(false);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [tabIndex, setTabIndex] = useState(0);
  const [doctorRefreshFlag, setDoctorRefreshFlag] = useState(false);
  const { scrollToTop } = useScroll();

  useEffect(() => {
    scrollToTop();
  });

  const containerStyle = {
    marginLeft: isMobile ? "5%" : "25%",
    marginBottom: isMobile ? "15%" : "3%",
    marginRight: "5%",
    marginTop: isMobile ? "25%" : "5%",
    transition: "margin 0.5s",
    zIndex: 3,
  };

  const handleAddModalOpen = () => {
    setOpenAddModal(true);
    dispatch({ type: "set_selected_doctor", payload: initialState.selectedDoctor });
  };

  const handleAddModalClose = () => {
    setOpenAddModal(false);
    dispatch({ type: "set_selected_doctor", payload: null });
  };

  const handleEditDoctor = (doctor) => {
    setOpenAddModal(true);
    dispatch({ type: "set_selected_doctor", payload: doctor });
  };

  const handleBreadcrumbClick = (index) => {
    dispatch({ type: "set_doctor_label", payload: state.doctorLabel.slice(0, index + 1) });
    dispatch({ type: "set_selected_doctor", payload: null });
    dispatch({ type: "set_doctor", payload: "view doctor" });
    // console.log("breadcrumbs", state.doctorLabel);
  };

  const handleDoctorClick = (doctor) => {
    dispatch({ type: "set_selected_doctor", payload: doctor });
    // console.log("set_selected_doctor", doctor);
    dispatch({ type: "set_doctor", payload: "selected doctor" });
  };

  return (
    <div style={containerStyle}>
      <Stack direction="column" spacing={4} alignItems="center">
        <DemoPaper
          height={isMobile ? "120vh" : "80vh"}
          square={false}
          elevation={24}
          isMobile={isMobile}
        >
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
            {state.doctorLabel &&
              state.doctorLabel.map((label, index) => (
                <span
                  key={index}
                  onClick={() => handleBreadcrumbClick(index)}
                  style={{
                    cursor:
                      index < state.doctorLabel && state.doctorLabel.length - 1
                        ? "pointer"
                        : "pointer",
                    fontSize: "1.5rem",
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  {label}
                </span>
              ))}
          </Breadcrumbs>
          {state.doctor === "view doctor" && (
            <>
              <Tooltip
                title="Add New Doctor"
                arrow
                style={{ alignSelf: "flex-end", marginRight: "10px" }}
              >
                <Button
                  variant="contained"
                  size="medium"
                  onClick={handleAddModalOpen}
                  startIcon={<PersonAddAlt1Icon />}
                  sx={{
                    color: "primary",
                  }}
                >
                  Add New Doctor
                </Button>
              </Tooltip>

              <DoctorsTable
                state={state}
                dispatch={dispatch}
                isOpen={openAddModal}
                onEdit={handleEditDoctor}
                handleBreadcrumbClick={handleBreadcrumbClick}
                onDoctorClick={handleDoctorClick}
                selectedDoctor={state.selectedDoctor}
                doctorRefreshFlag={doctorRefreshFlag}
                setDoctorRefreshFlag={setDoctorRefreshFlag}
              />
              <AddEditDoctorsModal
                isOpen={openAddModal}
                onClose={handleAddModalClose}
                selectedDoctor={state.selectedDoctor}
                state={state}
                dispatch={dispatch}
                doctorRefreshFlag={doctorRefreshFlag}
                setDoctorRefreshFlag={setDoctorRefreshFlag}
              />
            </>
          )}
          {state.doctor === "selected doctor" && (
            <>
              <ViewDoctor
                state={state}
                dispatch={dispatch}
                isOpen={openAddModal}
                onEdit={handleEditDoctor}
                handleBreadcrumbClick={handleBreadcrumbClick}
                onDoctorClick={handleDoctorClick}
                selectedDoctor={state.selectedDoctor}
                selectedDoctorId={state.selectedDoctorId}
                tabIndex={tabIndex}
              />
            </>
          )}
        </DemoPaper>
      </Stack>
    </div>
  );
};

export default Doctors;

import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { Box, Typography, Button, Tooltip, Stack } from "@mui/material";
import ContactInfo from "./ContactInfo";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext";
import { toast } from "react-toastify";
import Header from "./Header";

const LandingPage = ({ isMobile }) => {
  const logoRef = useRef(null);
  const menuRef = useRef(null);

  const [isLogoVisible, setIsLogoVisible] = useState(false);
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const { user } = useAuth();

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          switch (entry.target) {
            case logoRef.current:
              setIsLogoVisible(entry.isIntersecting);
              break;
            case menuRef.current:
              setIsMenuVisible(entry.isIntersecting);
              break;
            default:
              setIsLogoVisible(entry.isIntersecting);
              setIsMenuVisible(entry.isIntersecting);
              break;
          }
        });
      },
      { threshold: 0.2 },
    );

    if (logoRef.current) observer.observe(logoRef.current);
    if (menuRef.current) observer.observe(menuRef.current);

    return () => observer.disconnect();
  }, []);

  const navigate = useNavigate();

  const handleButtonClick = () => {
    if (user && user.profileName) {
      navigate("/admin");
    } else {
      toast.info("To continue, please authenticate by logging in");
      navigate("/admin/login");
    }
  };

  const buttonStyle = {
    background: "rgba(255, 255, 255, 1)", // Semi-transparent white background
    borderRadius: "40px",
    color: "#000000",
    fontSize: isMobile ? "0.8rem" : "1.2rem",
    fontWeight: "bold",
    border: "1px solid #ffffff",
    boxShadow: "0px 0px 1px #ffffff",
    height: "30px",
  };

  const buttonVariants = {
    hidden: { x: 50, opacity: 0 },
    visible: { x: 0, opacity: 1 },
  };

  const animationVariants = {
    visible: { opacity: 1, transition: { duration: 1 } },
    hidden: { opacity: 0, transition: { duration: 1 } },
  };

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%", // Adjust width to fill the container
        height: "auto", // Adjust height as needed
        overflow: "hidden", // Hide overflow to prevent images from overflowing
        display: "flex",
        flexDirection: "column", // Stack elements vertically
        alignItems: "center",
        margin: "0 auto", // Center the carousel horizontally
      }}
    >
      <motion.img
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
        src={
          isMobile
            ? `${process.env.PUBLIC_URL}/images/Portrait-Image.jpg`
            : `${process.env.PUBLIC_URL}/images/Landscape-Image.jpeg`
        }
        alt="Banner"
        style={{
          width: "100%",
          height: isMobile ? "80vh" : "120vh",
          objectPosition: isMobile ? "right center" : "center center",
          objectFit: "cover",
        }}
        loading="lazy"
      />

      <motion.img
        ref={logoRef}
        initial="hidden"
        animate={isLogoVisible ? "visible" : "hidden"}
        variants={animationVariants}
        transition={{ duration: 1 }}
        src={`${process.env.PUBLIC_URL}/images/Company-Logo.png`}
        alt="Overlay"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: isMobile ? "110px" : "140px",
          height: isMobile ? "110px" : "140px",
          zIndex: 1,
        }}
      />

      {/* Buttons */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-end",
          justifyContent: "flex-end",
        }}
      >
        <motion.div
          initial="hidden"
          animate={isMenuVisible ? "visible" : "hidden"}
          exit="hidden"
          variants={animationVariants}
          ref={menuRef}
        >
          <Stack direction="row" spacing={isMobile ? undefined : 2} alignItems="center">
            <Tooltip title="Admin DashBoard" arrow>
              <motion.div
                variants={buttonVariants}
                initial="hidden"
                animate="visible"
                transition={{ delay: 0.4 }}
              >
                <Button variant="contained" style={buttonStyle} onClick={handleButtonClick}>
                  Admin DashBoard
                </Button>
              </motion.div>
            </Tooltip>

            <Header isMobile={isMobile} />
          </Stack>
        </motion.div>
      </Box>

      {/* Text */}
      <Typography
        sx={{
          position: "absolute",
          top: isMobile ? "15%" : "10%",
          right: isMobile ? "4%" : "8%",
          textAlign: "center",
          color: "#fff",
          zIndex: 1,
        }}
      >
        <motion.div initial="hidden" animate="visible" exit="hidden" variants={animationVariants}>
          <span
            style={{
              fontSize: isMobile ? "3rem" : "4rem",
              fontWeight: "bold",
              textShadow: "2px 2px 4px black",
            }}
          >
            ELEVATE
          </span>
          <br />
          <span
            style={{
              fontSize: isMobile ? "3rem" : "4rem",
              fontWeight: "bold",
              textShadow: "2px 2px 4px black",
              color: "#FFD700",
            }}
          >
            YOUR
          </span>
          <br />
          <span
            style={{
              fontSize: isMobile ? "3rem" : "4rem",
              fontWeight: "bold",
              textShadow: "2px 2px 4px black",
            }}
          >
            LIFESTYLE
          </span>
          <br />
          <span
            style={{
              fontSize: isMobile ? "1rem" : "1.5rem",
              fontWeight: "bold",
              textShadow: "2px 2px 4px black",
              lineHeight: "3",
            }}
          >
            WITH WISDOM OF
          </span>
          <br />
          <span
            style={{
              fontSize: isMobile ? "1rem" : "1.5rem",
              fontWeight: "bold",
              textShadow: "2px 2px 4px black",
              lineHeight: "2",
            }}
          >
            AYURVEDA
          </span>
          <br />
          <span
            style={{
              fontSize: isMobile ? "1rem" : "1.5rem",
              fontWeight: "bold",
              textShadow: "2px 2px 4px black",
              lineHeight: "2",
            }}
          >
            &
          </span>
          <br />
          <span
            style={{
              fontSize: isMobile ? "1rem" : "1.5rem",
              fontWeight: "bold",
              textShadow: "2px 2px 4px black",
              lineHeight: "2",
            }}
          >
            SMARTNESS OF AI
          </span>
          <br />
        </motion.div>
      </Typography>

      <ContactInfo isMobile={isMobile} />
    </Box>
  );
};

export default LandingPage;

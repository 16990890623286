/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import axios from "axios";
import {
  Box,
  Typography,
  Button,
  TextField,
  Paper,
  Grid,
  Tooltip,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

const UpdateQuestionList = ({
  state,
  dispatch,
  questionFileInputRef,
  answerFileInputRefs,
  resetScroll,
}) => {
  const handleClearState = () => {
    dispatch({
      type: "set_question_image_url",
      payload: "",
    });
    dispatch({
      type: "set_answer_image_url",
      payload: "",
    });
    dispatch({
      type: "set_questions",
      payload: "",
    });
  };

  useEffect(() => {
    if (state.selectedQuestionItem) {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/qna/get/each/question/${state.selectedQuestionItem._id}`,
        )
        .then((response) => {
          if (response.status === 200 && response.data && response.data.data) {
            const { answers, questiontext, prompt, questionimage } = response.data.data;
            resetScroll();

            const updatedAnswers = answers.map((answer) => ({
              answer: answer.answer,
              answerimage: "",
              results: answer.results.map((result) => ({
                result: result.result,
                value: result.value,
              })),
            }));

            const updatedQuestion = {
              prompt,
              questiontext,
              questionimage: "",
              answers: updatedAnswers,
            };

            if (questionimage) {
              dispatch({
                type: "set_question_image_url",
                payload: `https://7dstatic.s3.ap-south-1.amazonaws.com/trial/${questionimage}`,
              });
            } else {
              dispatch({
                type: "set_question_image_url",
                payload: "",
              });
            }

            const updatedImages = answers.map((answer) =>
              answer.answerimage
                ? `https://7dstatic.s3.ap-south-1.amazonaws.com/trial/${answer.answerimage}`
                : "",
            );

            dispatch({
              type: "set_answer_image_url",
              payload: updatedImages,
            });
            dispatch({
              type: "set_questions",
              payload: updatedQuestion,
            });
          } else {
            handleClearState();
            toast.error("Error fetching questions, Please Try after sometime");
          }
        })
        .catch((error) => {
          console.error("Error fetching question:", error);

          handleClearState();
          if (error.response && [400, 404, 409, 500].includes(error.response.status)) {
            switch (error.response.status) {
              case 400:
                toast.error("Bad request. Please check your inputs");
                break;
              case 404:
                toast.error("Questions not found");
                break;
              case 409:
                toast.error("There is a conflict with the current state of the questions");
                break;
              case 500:
                toast.error("Server error while fetching questions, Please Try after sometime");
                break;
              default:
                toast.error(
                  "An error occurred while fetching questions, Please Try after sometime",
                );
            }
          } else {
            toast.error("No Questions Found,  Please Contact developer");
          }
        });
    } else {
      handleClearState();
    }
  }, [state.selectedQuestionItem]);

  const handleChange = (field, value, aIndex, rIndex) => {
    const updatedQuestion = { ...state.questions };
    if (aIndex === undefined && rIndex === undefined) {
      updatedQuestion[field] = value;
    } else if (rIndex === undefined) {
      updatedQuestion.answers[aIndex][field] = value;
    } else {
      updatedQuestion.answers[aIndex].results[rIndex][field] = value;
    }
    dispatch({ type: "set_questions", payload: updatedQuestion });
  };

  const handleAddAnswer = () => {
    const updatedQuestion = { ...state.questions };
    updatedQuestion.answers.push({
      answer: "",
      answerimage: "",
      results: [{ result: "", value: "" }],
    });
    dispatch({ type: "set_questions", payload: updatedQuestion });
  };

  const handleDeleteAnswer = (aIndex) => {
    const updatedQuestion = { ...state.questions };
    updatedQuestion.answers.splice(aIndex, 1);
    dispatch({ type: "set_questions", payload: updatedQuestion });
  };

  const handleAddResult = (aIndex) => {
    const updatedQuestion = { ...state.questions };
    updatedQuestion.answers[aIndex].results.push({
      result: "",
      value: "",
    });
    dispatch({ type: "set_questions", payload: updatedQuestion });
  };

  const handleDeleteResult = (aIndex, rIndex) => {
    const updatedQuestion = { ...state.questions };
    updatedQuestion.answers[aIndex].results.splice(rIndex, 1);
    dispatch({ type: "set_questions", payload: updatedQuestion });
  };

  const handleAnswerImageUpload = (aIndex, event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      if (file) {
        const updatedQuestions = { ...state.questions };
        updatedQuestions.answers[aIndex].answerimage = file; // Store the File object
        dispatch({ type: "set_questions", payload: updatedQuestions });
      }
    } else {
      console.error("No image selected");
    }
  };

  const handleClick = () => {
    if (questionFileInputRef.current) {
      questionFileInputRef.current.click();
    }
  };

  const handleQuestionImageUpload = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      if (file) {
        const updatedQuestions = { ...state.questions, questionimage: file }; // Store the image object
        dispatch({ type: "set_questions", payload: updatedQuestions });
      }
    } else {
      console.error("No image selected");
    }
  };

  const handleRemoveQuestionImage = () => {
    const updatedQuestions = { ...state.questions };
    updatedQuestions.questionimage = ""; // Empty question image data
    dispatch({ type: "set_questions", payload: updatedQuestions });
    dispatch({ type: "set_question_image_url", payload: "" });
  };

  const handleCancelQuestionImage = () => {
    Swal.fire({
      title: "Confirm Deletion",
      text: "Are you sure you want to remove selected question image ???",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
      didOpen: () => {
        document.querySelector(".swal2-container").style.zIndex = "2000";
      },
    }).then((result) => {
      if (result.isConfirmed) {
        handleRemoveQuestionImage();
      } else {
        // example codes to clear up
      }
    });
  };

  const handleRemoveAnswerImage = (answerIndex) => {
    const updatedQuestions = { ...state.questions };
    updatedQuestions.answers[answerIndex].answerimage = ""; // Empty answer image data
    dispatch({ type: "set_questions", payload: updatedQuestions });

    const updatedAnswers = { ...state.answerImageURL };
    updatedAnswers[answerIndex] = "";

    dispatch({
      type: "set_answer_image_url",
      payload: updatedAnswers,
    });
  };

  const handleCancelAnswerImage = (answerIndex) => {
    Swal.fire({
      title: "Confirm Deletion",
      text: "Are you sure you want to remove selected answer image ???",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
      didOpen: () => {
        document.querySelector(".swal2-container").style.zIndex = "2000";
      },
    }).then((result) => {
      if (result.isConfirmed) {
        handleRemoveAnswerImage(answerIndex);
      } else {
        // example codes to clear up
      }
    });
  };

  const handleFieldChange = (aIndex, rIndex, e) => {
    let inputValue = e.target.value.replace(/\D/g, "").slice(0, 1);

    if (inputValue || inputValue === "") {
      handleChange("value", inputValue, aIndex, rIndex);
    } else {
      return;
    }
  };

  return (
    <>
      {/* Prompt Input */}
      <TextField
        margin="dense"
        id="prompt"
        label="Prompt (Optional)"
        type="text"
        placeholder="Enter Ai Question Prompt Text"
        autoComplete="off"
        value={state.questions.prompt}
        onChange={(e) => handleChange("prompt", e.target.value)}
        fullWidth
        InputLabelProps={{
          style: { color: "black" }, // Style for label
        }}
        InputProps={{
          sx: {
            "&:focused": { borderColor: "black" }, // Border color when focused
          },
        }}
        sx={{
          mb: 1.2,
          my: 2,
          "& input:focus": {
            borderColor: "black !important",
          },
          "& .MuiOutlinedInput-root.Mui-focused": {
            "& fieldset": {
              borderColor: "black !important",
            },
          },
        }}
      />

      {/* Question Text Input */}
      <TextField
        margin="dense"
        id="question text"
        label="Question Text"
        type="text"
        placeholder="Enter Question Text"
        required
        autoComplete="off"
        value={state.questions.questiontext}
        onChange={(e) => handleChange("questiontext", e.target.value)}
        fullWidth
        InputLabelProps={{
          style: { color: "black" }, // Style for label
        }}
        InputProps={{
          sx: {
            "&:focused": { borderColor: "black" }, // Border color when focused
          },
        }}
        sx={{
          mb: 1.2,
          my: 2,
          "& input:focus": {
            borderColor: "black !important",
          },
          "& .MuiOutlinedInput-root.Mui-focused": {
            "& fieldset": {
              borderColor: "black !important",
            },
          },
        }}
      />

      {/* Question Image Upload Input */}
      <Paper elevation={12} sx={{ padding: "20px", my: 2 }}>
        <Grid container alignItems="center">
          <input
            ref={questionFileInputRef}
            type="file"
            accept="image/*"
            name="questionimage"
            style={{ display: "none" }}
            onChange={handleQuestionImageUpload}
          />
          <Button variant="contained" onClick={handleClick} sx={{ mr: 2 }}>
            Choose An Question Image (Optional)
          </Button>
          {state.questions.questionimage || state.questionImageURL ? (
            <Typography variant="body1">
              {state.questions.questionimage
                ? state.questions.questionimage.name
                : state.questionImageURL.substring(state.questionImageURL.lastIndexOf("/") + 1)}
            </Typography>
          ) : (
            <Typography variant="body1">No Question Image Choosen </Typography>
          )}
          <Box
            sx={{
              marginLeft: "auto",
              alignSelf: "center",
              cursor: "pointer",
            }}
          >
            <Tooltip title="Remove Selected Image" arrow>
              <IconButton
                edge="end"
                aria-label="add"
                onClick={handleCancelQuestionImage}
                sx={{ color: "black" }}
                size="large"
              >
                <CloseIcon fontSize="large" />
              </IconButton>
            </Tooltip>
          </Box>
        </Grid>
      </Paper>

      {(state.questions.questionimage || state.questionImageURL) && (
        <Paper elevation={12} sx={{ padding: "20px", my: 2 }}>
          <Grid container direction="column" justifyContent="center" alignItems="flex-start">
            <Grid container justifyContent="center" alignItems="center" sx={{ mb: 2 }}>
              <Typography variant="h6" component="h2" justifyContent="center">
                Selected Question Image: {"  "}
                {state.questions.questionimage
                  ? state.questions.questionimage.name
                  : state.questionImageURL.substring(state.questionImageURL.lastIndexOf("/") + 1)}
              </Typography>
            </Grid>
            <Grid container justifyContent="center" alignItems="center">
              <img
                src={
                  state.questions.questionimage
                    ? URL.createObjectURL(state.questions.questionimage)
                    : state.questionImageURL
                }
                alt="Uploaded File"
                style={{ maxWidth: "100%" }}
              />
            </Grid>
          </Grid>
        </Paper>
      )}

      {/* Answer Section */}
      {state.questions.answers.map((answer, aIndex) => (
        <div key={aIndex}>
          {/* Answer Input */}
          <TextField
            margin="dense"
            id="answer"
            label={`${aIndex + 1}. Answer`}
            type="text"
            placeholder="Enter Answer Text"
            InputLabelProps={{
              style: {
                color: "black",
              },
            }}
            autoComplete="off"
            required
            value={answer.answer}
            onChange={(e) => handleChange("answer", e.target.value, aIndex)}
            fullWidth
            sx={{
              my: 2,
              mb: 1.2,
              "& input:focus": {
                borderColor: "black !important",
              },
              "& .MuiOutlinedInput-root.Mui-focused": {
                "& fieldset": {
                  borderColor: "black !important",
                },
              },
            }}
          />

          {/* Delete Answer Button */}
          <Button
            variant="outlined"
            onClick={() => handleDeleteAnswer(aIndex)}
            sx={{ mt: 1, mb: 2 }}
          >
            Delete Answer Field
          </Button>

          {/* Image Upload Input for Answer */}
          <Paper elevation={12} sx={{ padding: "20px", mb: 2 }}>
            <Grid container alignItems="center">
              <Button
                variant="contained"
                onClick={() => answerFileInputRefs[aIndex].current.click()}
                sx={{ mr: 2 }}
              >
                Choose An Answer Image (Optional)
              </Button>

              <input
                ref={answerFileInputRefs[aIndex]}
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={(e) => handleAnswerImageUpload(aIndex, e)}
              />

              {answer.answerimage || (state.answerImageURL && state.answerImageURL[aIndex]) ? (
                <Typography variant="body1">
                  {answer.answerimage
                    ? answer.answerimage.name
                    : state.answerImageURL[aIndex].substring(
                        state.answerImageURL[aIndex].lastIndexOf("/") + 1,
                      )}
                </Typography>
              ) : (
                <Typography variant="body1">No Answer Image Choosen </Typography>
              )}
              <Box
                sx={{
                  marginLeft: "auto",
                  alignSelf: "center",
                  cursor: "pointer",
                }}
              >
                <Tooltip title="Remove Selected Image" arrow>
                  <IconButton
                    edge="end"
                    aria-label="add"
                    onClick={() => handleCancelAnswerImage(aIndex)}
                    sx={{ color: "black" }}
                    size="large"
                  >
                    <CloseIcon fontSize="large" />
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
          </Paper>

          {(answer.answerimage || (state.answerImageURL && state.answerImageURL[aIndex])) && (
            <Paper elevation={12} sx={{ padding: "20px", my: 2 }}>
              <Grid container direction="column" justifyContent="center" alignItems="flex-start">
                <Grid container justifyContent="center" alignItems="center" sx={{ mb: 2 }}>
                  <Typography variant="h6" component="h2" justifyContent="center">
                    {aIndex + 1}. Answer Image : {"  "}
                    {answer.answerimage
                      ? answer.answerimage.name
                      : state.answerImageURL[aIndex].substring(
                          state.answerImageURL[aIndex].lastIndexOf("/") + 1,
                        )}
                  </Typography>
                </Grid>
                <Grid container justifyContent="center" alignItems="center">
                  <img
                    src={
                      answer.answerimage
                        ? URL.createObjectURL(answer.answerimage)
                        : state.answerImageURL[aIndex]
                    }
                    alt="Uploaded File"
                    style={{ maxWidth: "100%" }}
                  />
                </Grid>
              </Grid>
            </Paper>
          )}

          {/* Result Section */}
          {answer.results.map((result, rIndex) => (
            <div key={rIndex}>
              {/* Result Input */}
              <TextField
                margin="dense"
                id="answer result"
                label={`${rIndex + 1}. Answer Result`}
                type="text"
                placeholder="Enter Result Text (Vata or V)"
                autoComplete="off"
                InputLabelProps={{
                  style: {
                    color: "black",
                  },
                }}
                required
                value={result.result}
                onChange={(e) => handleChange("result", e.target.value, aIndex, rIndex)}
                fullWidth
                sx={{
                  my: 2,
                  mb: 1.2,
                  "& input:focus": {
                    borderColor: "black !important",
                  },
                  "& .MuiOutlinedInput-root.Mui-focused": {
                    "& fieldset": {
                      borderColor: "black !important",
                    },
                  },
                }}
              />

              {/* Value Input */}
              <TextField
                margin="dense"
                id="answer value"
                label={`${rIndex + 1}. Answer Value`}
                type="text"
                placeholder="Enter Result Value text (0 or 1)"
                InputLabelProps={{
                  style: {
                    color: "black",
                  },
                }}
                autoComplete="off"
                required
                value={result.value}
                onChange={(e) => handleFieldChange(aIndex, rIndex, e)}
                fullWidth
                sx={{
                  my: 1,
                  mb: 1.2,
                  "& input:focus": {
                    borderColor: "black !important",
                  },
                  "& .MuiOutlinedInput-root.Mui-focused": {
                    "& fieldset": {
                      borderColor: "black !important",
                    },
                  },
                }}
              />

              {/* Delete Result Button */}
              <Button
                variant="outlined"
                onClick={() => handleDeleteResult(aIndex, rIndex)}
                sx={{ mt: 1, mb: 2 }}
              >
                Delete Result Field
              </Button>
            </div>
          ))}

          {/* Add Result Button */}
          <Button variant="outlined" onClick={() => handleAddResult(aIndex)} sx={{ mb: 1 }}>
            Add Result Field
          </Button>
        </div>
      ))}

      {/* Add Answer Button */}
      <Button variant="outlined" onClick={() => handleAddAnswer()} sx={{ mt: 2 }}>
        Add Answer Field
      </Button>
    </>
  );
};

export default UpdateQuestionList;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { Box, Tab, Tabs } from "@mui/material";
import WeeklyTimings from "./WeeklyTimings/WeeklyTimings";
import Holidays from "./Holidays/Holidays";
import { useScroll } from "../../../ScrollContext";

const DemoPaper = styled(Paper)(({ theme }) => ({
  width: "90%",
  height: "85vh",
  padding: theme.spacing(3),
  ...theme.typography.body2,
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  backgroundColor: "rgba(255, 255, 255, 0.7)",
}));

const CustomTabPanel = ({ value, index, children }) => {
  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const CompanyTimingsHolidays = ({ isMobile }) => {
  const { scrollToTop } = useScroll();

  useEffect(() => {
    scrollToTop();
  }, []);

  const containerStyle = {
    marginLeft: isMobile ? "5%" : "25%",
    marginBottom: "5%",
    marginRight: isMobile ? "5%" : "5%",
    marginTop: isMobile ? "25%" : "2%",
    transition: "margin 0.5s",
    zIndex: 3,
  };

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div style={containerStyle}>
      <DemoPaper square={false}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "black",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            sx={{
              "& .MuiTabs-indicator": {
                backgroundColor: "#721F4B", // Change the indicator color here
              },
            }}
          >
            <Tab
              label="Weekly Timings"
              style={{
                color: value === 0 ? "white" : "black",
                backgroundColor: value === 0 ? "#E22C6F" : "transparent",
                cursor: "pointer",
                fontSize: "1.2rem",
              }}
            />
            <Tab
              label="Holidays"
              style={{
                color: value === 1 ? "white" : "black",
                backgroundColor: value === 1 ? "#E22C6F" : "transparent",
                cursor: "pointer",
                fontSize: "1.2rem",
              }}
            />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <WeeklyTimings />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Holidays />
        </CustomTabPanel>
      </DemoPaper>
    </div>
  );
};

export default CompanyTimingsHolidays;

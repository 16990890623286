/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import {
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Grid,
  ListItemIcon,
  ListItemText,
  Box,
} from "@mui/material";
import { toast } from "react-toastify";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import PerfectScrollbar from "react-perfect-scrollbar";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import axios from "axios";
import QualificationTable from "./QualificationTable";
import countries from "../../../../country.json";
import { motion } from "framer-motion";

const AddEditDoctorsModal = ({
  isOpen,
  onClose,
  selectedDoctor,
  setDoctorRefreshFlag,
  isMobile,
}) => {
  const fileInputRef = useRef(null);

  const [doctorData, setDoctorData] = useState({
    fullName: "",
    phoneNumber: "",
    gender: "",
    age: "",
    email: "",
    practiceAddress: "",
    qualifications: [],
  });

  const [showLoading, setShowLoading] = useState(true);
  const [selectedCountry, setSelectedCountry] = useState("India");
  const [phoneCode, setPhoneCode] = useState("+91");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedDegreeImages, setSelectedDegreeImages] = useState([]);

  useEffect(() => {
    if (selectedDoctor && selectedDoctor.id) {
      const { phoneNumber, country } = selectedDoctor;

      if (!isOpen) {
        setDoctorData({
          fullName: selectedDoctor.fullName || "",
          phoneNumber: selectedDoctor.phoneNumber || "",
          gender: selectedDoctor.gender || "",
          age: selectedDoctor.age || "",
          email: selectedDoctor.email || "",
          practiceAddress: selectedDoctor.practiceAddress || "",
          qualifications: selectedDoctor.qualifications || [],
        });
      }
    } else {
      setDoctorData({
        fullName: "",
        phoneNumber: "",
        gender: "",
        age: "",
        email: "",
        practiceAddress: "",
        qualifications: [{ degreeName: "", collegeName: "", batch: "", degreeCertificate: "" }],
      });
      setPhoneCode("");
      setPhoneNumber("");
    }
  }, [selectedDoctor, isOpen]);

  const handleCountryChange = (event) => {
    const countryName = event.target.value;
    const selectedCountry = countries.find((country) => country.name === countryName);

    console.log("countryName", countryName);
    if (selectedCountry) {
      setSelectedCountry(selectedCountry.name);
      setPhoneCode(selectedCountry.dial_code || "");
      setPhoneNumber("");
    }
    console.log("selectedCountry", selectedCountry);
  };

  const handlePhoneNumberChange = (event) => {
    const { value } = event.target;
    setPhoneNumber(value);
  };

  const handleInputChange = (field, value) => {
    if (field === "phoneNumber" || field === "pinCode") {
      const numericValue = value.replace(/\D/g, "");
      const limitedValue = numericValue.slice(0, 13);
      setDoctorData((prevData) => ({ ...prevData, [field]: limitedValue }));
    } else {
      setDoctorData((prevData) => ({ ...prevData, [field]: value }));
    }
  };

  const validateFullName = (fullName) => {
    const nameRegex = /^[A-Za-z]+(?: [A-Za-z]+)*$/;
    return nameRegex.test(fullName);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // const validatePhoneNumber = (phoneNumber) => {
  //   const phoneRegex = /^\d{10}$/;
  //   return phoneRegex.test(phoneNumber);
  // };

  const handleGenderChange = (e) => {
    const genderValue = e.target.value === "Others" ? "Others" : e.target.value;
    setDoctorData((prevData) => ({
      ...prevData,
      gender: genderValue,
    }));
  };

  const handleQualificationChange = (index, field, value, e) => {
    if (field === "degreeName") {
      const degreeValue = value ? { id: value } : null;
      setDoctorData((prevData) => {
        const updatedQualifications = [...prevData.qualifications];
        updatedQualifications[index].degreeName = degreeValue;
        return { ...prevData, qualifications: updatedQualifications };
      });
    } else if (field === "collegeName") {
      const collegeId = value ? { id: value } : null;
      setDoctorData((prevData) => {
        const updatedQualifications = [...prevData.qualifications];
        updatedQualifications[index].collegeName = collegeId;
        return { ...prevData, qualifications: updatedQualifications };
      });
    } else if (field === "degreeCertificate") {
      const file = e.target.files && e.target.files[0];
      if (file) {
        setDoctorData((prevData) => {
          const updatedQualifications = [...prevData.qualifications];
          updatedQualifications[index].degreeCertificate = file;
          return { ...prevData, qualifications: updatedQualifications };
        });
      }
      // console.log("file uploaded", file);
    } else if (field === "batch") {
      const numericValue = value.replace(/\D/g, "");
      setDoctorData((prevData) => {
        const updatedQualifications = [...prevData.qualifications];
        updatedQualifications[index].batch = numericValue;
        return { ...prevData, qualifications: updatedQualifications };
      });
    } else {
      setDoctorData((prevData) => {
        const updatedQualifications = [...prevData.qualifications];
        updatedQualifications[index][field] = value;
        return { ...prevData, qualifications: updatedQualifications };
      });
    }
  };

  const handleAddDegree = () => {
    setDoctorData((prevData) => ({
      ...prevData,
      qualifications: [
        ...prevData.qualifications,
        { degreeName: "", collegeName: "", batch: "", degreeCertificate: "" },
      ],
    }));
    setSelectedDegreeImages((prevImages) => [...prevImages, null]); // Add null for the newly added degree
  };

  const handleCancelDegree = (index) => {
    setDoctorData((prevData) => {
      const updatedDegrees = [...prevData.qualifications];
      updatedDegrees.splice(index, 1);
      return { ...prevData, qualifications: updatedDegrees };
    });
  };

  const handleSaveDoctor = async () => {
    const phoneNumberWithCode = `${phoneCode} ${phoneNumber}`;

    // Update the doctor data with the merged phone number
    const updatedDoctorData = {
      ...doctorData,
      phoneNumber: phoneNumberWithCode,
    };

    try {
      if (selectedDoctor && selectedDoctor.id) {
        await handleEditDoctor(selectedDoctor);
      } else {
        const jsonData = {
          fullName: doctorData.fullName,
          phoneNumber: updatedDoctorData.phoneNumber,
          email: doctorData.email,
          practiceAddress: doctorData.practiceAddress,
          gender: doctorData.gender,
          age: doctorData.age,
          qualifications: doctorData.qualifications.map((qualification) => {
            return {
              degreeName: qualification.degreeName ? qualification.degreeName.id : null,
              collegeName: qualification.collegeName ? qualification.collegeName.id : null,
              batch: qualification.batch,
              degreeCertificate: qualification.degreeCertificate
                ? qualification.degreeCertificate.name
                : null,
            };
          }),
        };
        const formData = new FormData();
        console.log("created doctor", jsonData);

        formData.append("doctorDetails", JSON.stringify(jsonData));

        doctorData.qualifications.forEach((qualification, index) => {
          if (qualification.degreeCertificate) {
            formData.append("files", qualification.degreeCertificate);
          }
        });

        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/doctor/post/new/doctor-details`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          },
        );

        if (response.status === 201) {
          toast.success("Doctor details saved successfully");
          onClose();
          setDoctorRefreshFlag(true);
          if (fileInputRef.current) {
            fileInputRef.current.value = "";
          }
        }
      }
    } catch (error) {
      console.error("Error saving doctor details", error);
      toast.error("Error saving doctor details");
    }
    setShowLoading(false);
  };

  //handle edit doctor
  useEffect(() => {
    const fetchDoctorDetails = async () => {
      try {
        if (selectedDoctor && selectedDoctor.doctorId) {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/doctor/get/selected/${selectedDoctor.doctorId}`,
          );
          if (response && response.status === 200 && response.data && response.data.data) {
            setDoctorData(response.data.data);
            setShowLoading(false);
            console.log("fetch edit DoctorDetails", response.data.data);
          } else {
            console.error("Error fetching doctor details:", response);
            setShowLoading(false);
            toast.error("Error fetching doctor details:");
          }
        }
      } catch (error) {
        if (error.response && [400, 404, 409, 500].includes(error.response.status)) {
          toast.error("Error fetching doctor details");
        } else {
          toast.error("Error fetching doctor details");
        }
      }
    };
    fetchDoctorDetails();
  }, [selectedDoctor]);

  const handleEditDoctor = async () => {
    setShowLoading(true);
    try {
      // const country = countries.find((country) => country.dial_code === phoneCode);
      // console.log("country", country);

      const phoneNumberWithoutCode = doctorData.phoneNumber.split(" ")[1];

      const jsonData = {
        fullName: doctorData.fullName,
        phoneNumber: phoneNumberWithoutCode,
        email: doctorData.email,
        practiceAddress: doctorData.practiceAddress,
        gender: doctorData.gender,
        age: doctorData.age,
        qualifications: doctorData.qualifications.map((qualification) => {
          return {
            degreeName: qualification.degreeName ? qualification.degreeName.id : null,
            collegeName: qualification.collegeName ? qualification.collegeName.id : null,
            batch: qualification.batch,
            degreeCertificate: qualification.degreeCertificate
              ? qualification.degreeCertificate.name
              : null,
          };
        }),
      };
      const formData = new FormData();

      formData.append("doctorDetails", JSON.stringify(jsonData));

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/doctor/post/selected/${selectedDoctor.doctorId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );

      if (response.status === 200) {
        toast.success("Doctor details updated successfully");
        setShowLoading(false);
        onClose();
        setDoctorRefreshFlag(true);

        // if (country) {
        //   setSelectedCountry({
        //     name: country.name,
        //     dial_code: country.dial_code,
        //     flag_url: country.flag_url,
        //   });
        // }
      }
    } catch (error) {
      console.error("Error updating doctor details", error);
      toast.error("Error updating doctor details");
    }
    setShowLoading(false);
  };

  const handleClose = () => {
    // console.log("Selected Doctor:", selectedDoctor);

    onClose();
  };

  // const isEditing = selectedDoctor && selectedDoctor.id;

  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={isOpen}
        fullWidth
        maxWidth={isMobile ? "lg" : "md"}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "white", // Set transparent background color for the paper element inside the dialog
          },
        }}
      >
        <DialogTitle
          sx={{
            fontSize: "1.6rem",
          }}
          align="left"
        >
          {selectedDoctor && selectedDoctor.id ? "Edit Doctor" : "Add Doctor"}
        </DialogTitle>
        <Divider sx={{ bgcolor: "black" }} />

        <DialogContent sx={{ height: "500px", overflow: "hidden" }}>
          <PerfectScrollbar>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  placeholder="Enter Full Name"
                  label="Full Name"
                  value={doctorData.fullName}
                  onChange={(e) => handleInputChange("fullName", e.target.value)}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    style: { color: "black", fontWeight: "bold" },
                  }}
                  autoComplete="off"
                  sx={{
                    "& input": {
                      color: "black",
                    },
                    "& .MuiOutlinedInput-root.Mui-focused": {
                      borderColor: "black",
                      "& fieldset": {
                        borderColor: "black",
                      },
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "black",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "black",
                    },
                  }}
                />
              </Grid>

              {/* new email */}
              <Grid item xs={12} sm={6}>
                <TextField
                  placeholder="Enter Email"
                  label="Email"
                  value={doctorData.email}
                  onChange={(e) => handleInputChange("email", e.target.value)}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    style: { color: "black", fontWeight: "bold" },
                  }}
                  autoComplete="off"
                  sx={{
                    "& input": {
                      color: "black",
                    },
                    "& .MuiOutlinedInput-root.Mui-focused": {
                      borderColor: "black",
                      "& fieldset": {
                        borderColor: "black",
                      },
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "black",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "black",
                    },
                  }}
                />
              </Grid>
            </Grid>

            {/*phone  */}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <motion.div initial="hidden" animate="visible">
                  <Box display="flex">
                    <FormControl sx={{ mr: 1, mt: 3 }} variant="standard">
                      <Select
                        value={selectedCountry}
                        onChange={handleCountryChange}
                        sx={{
                          "&:focus": {
                            borderColor: "black",
                          },
                          "&:hover": {
                            borderColor: "black",
                          },
                          "& .MuiInput-underline:after": {
                            borderBottomColor: "black",
                          },
                          "& .MuiMenuItem-root.Mui-selected": {
                            color: "black",
                          },
                        }}
                      >
                        {countries &&
                          countries.map((country, index, array) => (
                            <MenuItem
                              key={country.name}
                              value={country.name}
                              sx={{
                                mt: index === 0 ? -1 : "normal",
                                mb: index === array.length - 1 ? -1 : "normal",
                              }}
                              divider
                            >
                              <ListItemIcon>
                                <img src={country.flag_url} alt="" width="30" height="20" />
                              </ListItemIcon>
                              <ListItemText primary={country.code} />
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>

                    <TextField
                      placeholder="Enter Phone Number"
                      label="Phone Number"
                      value={phoneNumber}
                      onChange={handlePhoneNumberChange}
                      // onChange={(e) => handleInputChange("phoneNumber", e.target.value)}

                      fullWidth
                      InputLabelProps={{
                        style: { color: "black", fontFamily: "Montserrat", fontWeight: "bold" },
                      }}
                      autoComplete="off"
                      sx={{
                        mr: 1,
                        mt: 2,
                        "& input": {
                          color: "black",
                        },
                        "& .MuiOutlinedInput-root.Mui-focused": {
                          borderColor: "black",
                          "& fieldset": {
                            borderColor: "black",
                          },
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "black",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "black",
                        },
                      }}
                    />
                  </Box>
                </motion.div>
              </Grid>

              <Grid item xs={12} sm={6} lg={3} md={3} marginTop={2}>
                {/* Gender as Select */}
                <FormControl fullWidth placeholder="Enter Gender">
                  <InputLabel
                    id="gender"
                    sx={{
                      color: "black",
                      fontWeight: "bold",
                      "&.Mui-focused": {
                        color: "black",
                      },
                    }}
                  >
                    Gender
                  </InputLabel>
                  <Select
                    labelId="gender"
                    label="Gender"
                    value={doctorData.gender}
                    onChange={handleGenderChange}
                    autoComplete="off"
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "black",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "black",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "black",
                      },
                      color: "black",
                      "& .MuiSvgIcon-root": {
                        color: "black",
                      },
                    }}
                  >
                    <MenuItem value="Others">Others</MenuItem>
                    <MenuItem value="male">Male</MenuItem>
                    <MenuItem value="female">Female</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={3}>
                <TextField
                  placeholder="Enter Age"
                  label="Age"
                  value={doctorData.age}
                  onChange={(e) => handleInputChange("age", e.target.value)}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    style: { color: "black", fontWeight: "bold", fontFamily: "Montserrat" },
                  }}
                  autoComplete="off"
                  sx={{
                    "& input": {
                      color: "black",
                    },
                    "& .MuiOutlinedInput-root.Mui-focused": {
                      borderColor: "black",
                      "& fieldset": {
                        borderColor: "black",
                      },
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "black",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "black",
                    },
                  }}
                />
              </Grid>
            </Grid>

            {/* Address as Textarea */}
            <TextField
              placeholder="Enter Address"
              label="Practice Address"
              multiline
              value={doctorData.practiceAddress}
              onChange={(e) => handleInputChange("practiceAddress", e.target.value)}
              fullWidth
              margin="normal"
              InputLabelProps={{
                style: { color: "black", fontWeight: "bold" },
              }}
              autoComplete="off"
              sx={{
                "& input": {
                  color: "black",
                },
                "& .MuiOutlinedInput-root.Mui-focused": {
                  borderColor: "black",
                  "& fieldset": {
                    borderColor: "black",
                  },
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "black",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "black",
                },
                "& textarea": {
                  height: "auto", // Allow textarea to grow dynamically
                  minHeight: "50px", // Set a minimum height if needed
                },
              }}
            />

            {/* Grid for Degrees */}
            <QualificationTable
              qualifications={doctorData.qualifications}
              handleQualificationChange={handleQualificationChange}
              handleAddDegree={handleAddDegree}
              handleCancelDegree={handleCancelDegree}
              fileInputRef={fileInputRef}
              selectedDegreeImages={selectedDegreeImages}
              setSelectedDegreeImages={setSelectedDegreeImages}
            />
          </PerfectScrollbar>
        </DialogContent>

        <Divider sx={{ bgcolor: "black", margin: "16px 0 " }} />

        <DialogActions>
          <Button
            variant="outlined"
            onClick={selectedDoctor && selectedDoctor.id ? handleEditDoctor : handleSaveDoctor}
            startIcon={<SaveIcon />}
            sx={{
              color: "primary",
            }}
          >
            {selectedDoctor && selectedDoctor.id ? "Update" : "Add"}
          </Button>
          <Button variant="outlined" color="error" onClick={onClose} startIcon={<CloseIcon />}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddEditDoctorsModal;

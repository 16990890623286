/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import axios from "axios";
import { DemoPaper } from "../../DemoPaper";
import { toast } from "react-toastify";
import { motion } from "framer-motion";
import { Button, Stack, Tooltip } from "@mui/material";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import VendorsTable from "./VendorsTable";
import AddNewVendors from "./AddNewVendors";

const Vendors = ({ isMobile }) => {
  const containerStyle = {
    marginLeft: isMobile ? "5%" : "25%",
    marginBottom: isMobile ? "15%" : "3%",
    marginRight: "5%",
    marginTop: isMobile ? "25%" : "5%",
    transition: "margin 0.5s",
    zIndex: 3,
  };

  const [vendorsList, setVendorsList] = useState([]);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [vendorData, setVendorData] = useState({
    companyName: "",
    companyAddress: "",
    companyContactNumber: "",
    companyTelephoneNumber: "",
    companyContactPerson: "",
    companyEmail: "",
    companyPanNo: "",
    companyGSTNo: "",
  });
  const [loading, setLoading] = useState(false);

  const fetchVendors = async () => {
    try {
      setShowLoading(true);
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/vendors/get/all/list`);

      if (response && response.status === 200 && response.data && response.data.data) {
        setVendorsList(response.data.data);
      } else {
        setVendorsList([]);
      }
    } catch (error) {
      setVendorsList([]);

      if (error.response && [400, 404, 409, 500].includes(error.response.status)) {
        switch (error.response.status) {
          case 404:
            toast.error("Vendor's details not found");
            break;
          case 500:
            toast.error(
              "An error occurred while fetching Vendor's details. Please try again later.",
            );
            break;
          default:
            toast.error(
              "An error occurred while fetching Vendor's details. Please try again later.",
            );
        }
      } else {
        toast.error(
          "An error occurred while fetching Vendor's details. Please contact the developer.",
        );
      }
    }
    setShowLoading(false);
  };

  useEffect(() => {
    fetchVendors();
  }, []);

  const handleModalOpen = () => {
    setIsModelOpen(true);
  };

  const handleModalClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;

    setIsModelOpen(false);

    setVendorData({
      companyName: "",
      companyAddress: "",
      companyContactNumber: "",
      companyTelephoneNumber: "",
      companyContactPerson: "",
      companyEmail: "",
      companyPanNo: "",
      companyGSTNo: "",
    });

    setLoading(false);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Stack direction="column" alignItems="center" style={containerStyle}>
        <DemoPaper
          height={isMobile ? "120vh" : "80vh"}
          square={false}
          elevation={24}
          isMobile={isMobile}
        >
          <Tooltip
            title="Add New  Offline User"
            arrow
            style={{ alignSelf: "flex-end", marginRight: "10px", marginBottom: "10px" }}
          >
            <Button
              variant="contained"
              size="large"
              onClick={handleModalOpen}
              startIcon={<AddBusinessIcon fontSize="inherit" />}
              sx={{
                backgroundColor: "#72225e",
                fontWeight: "bold",
                ":hover": {
                  backgroundColor: "#8C3C78",
                },
              }}
            >
              Add New Vendor
            </Button>
          </Tooltip>
          <VendorsTable isMobile={isMobile} vendors={vendorsList} showLoading={showLoading} />

          <AddNewVendors
            isOpen={isModelOpen}
            modalClose={handleModalClose}
            vendorData={vendorData}
            setVendorData={setVendorData}
            loading={loading}
            setLoading={setLoading}
            isMobile={isMobile}
          />
        </DemoPaper>
      </Stack>
    </motion.div>
  );
};

export default Vendors;

/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { TextField, Button, Grid, Typography } from "@mui/material";
import { toast } from "react-toastify";
import DiagnosticDiseaseList from "./DiagnosticDiseaseList";
import axios from "axios";
import Swal from "sweetalert2";
import { DNA } from "react-loader-spinner";
import { motion } from "framer-motion";

const DaignosticDisease = ({ isMobile }) => {
  const [diseases, setDiseases] = useState([]);
  const [formData, setFormData] = useState({
    diseaseName: "",
    diseaseCode: "",
    diseaseName_EN: "",
    diseaseName_SN: "",
  });

  const [diseaseFlag, setDiseaseFlag] = useState(false);

  const [selectedId, setSelectedId] = useState(null);
  const [action, setAction] = useState("add");
  const [loading, setLoading] = useState(true);

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    const fetchDisease = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/disease/get/all`);
        if (response && response.status === 200) {
          if (response.data && response.data.data) {
            setDiseases(response.data.data);
          } else {
            setDiseases([]);
            console.error("Error fetching diagnostic disease details");
          }
        } else {
          setDiseases([]);
          console.error("Error fetching diagnostic disease details");
        }
      } catch (error) {
        if (error.response && [400, 404, 409, 500].includes(error.response.status)) {
          toast.error("Some Error In Fetching , Please Try after sometime");
        } else {
          console.error("Error fetching diagnostic disease details:", error);
        }
        setDiseases([]);
      }
      setLoading(false);
    };

    fetchDisease();
  }, [diseaseFlag]);

  const handleCancel = () => {
    setFormData("");
    setSelectedId("");
    setAction("add");
  };

  const handleAdd = async () => {
    if (action === "add") {
      if (
        !formData.diseaseName &&
        !formData.diseaseCode &&
        !formData.diseaseName_EN &&
        !formData.diseaseName_SN
      ) {
        toast.error("Please enter a diseases first");
        return;
      }

      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/disease/post/new`, {
          diseasesDetail: formData,
        });

        if (response && response.status === 201) {
          toast.success("Diseases Added Successfully");
          handleCancel();
          setDiseaseFlag(!diseaseFlag);
        }
      } catch (error) {
        console.error("Error adding disease :", error);
        toast.error("Error adding disease ");
      }
    } else if (action === "edit") {
      if (!formData) {
        toast.error("Please fill disease  first");
        return;
      }

      const existingDisease = diseases.find((item) => item._id === selectedId);
      const formChanged = Object.keys(formData).some(
        (key) => formData[key] !== existingDisease[key],
      );

      // Check if the text field value has changed
      if (!formChanged) {
        toast.error("No changes detected.");
        return;
      }

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/disease/post/selected/${selectedId}`,
          {
            diseasesDetail: {
              diseaseName: formData.diseaseName,
              diseaseCode: formData.diseaseCode,
              diseaseName_EN: formData.diseaseName_EN,
              diseaseName_SN: formData.diseaseName_SN,
            },
          },
        );

        if (response && response.status === 200) {
          toast.success("Disease Edited Successfully");
          handleCancel();
          setDiseaseFlag(!diseaseFlag);
        }
      } catch (error) {
        console.error("Error adding disease :", error);
        toast.error("Error adding disease ");
      }
    }
  };

  const handleEdit = (item) => {
    if (item) {
      setSelectedId(item._id);
      setAction("edit");
      setFormData({
        diseaseName: item.diseaseName,
        diseaseCode: item.diseaseCode,
        diseaseName_EN: item.diseaseName_EN,
        diseaseName_SN: item.diseaseName_SN,
      });
    }
  };

  const handleDelete = async (item) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/disease/delete/selected/${item}`,
      );

      if (response && response.status === 200) {
        toast.success("Disease Deleted Successfully");
        setDiseaseFlag(!diseaseFlag);
      }
    } catch (error) {
      console.error("Error deleting disease:", error);
      toast.error("Error deleting disease");
    }
  };

  const handleOpenDeleteConfirmation = (item) => {
    handleCancel();

    Swal.fire({
      title: "Confirm Deletion",
      text: "Are you sure you want to delete this disease?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(item);
      }
    });
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        alignItems="center"
        marginTop={1}
        marginBottom={1}
        style={{
          position: "sticky",
          top: 0,
          background: "#A52D59",
          zIndex: 1,
          padding: "5px",
        }}
      >
        <Grid item xs={12} md={3} lg={3}>
          <TextField
            margin="dense"
            id="disease name"
            type="text"
            required
            label="Disease Name"
            value={formData?.diseaseName || ""}
            onChange={handleInputChange}
            placeholder="Disease Name"
            fullWidth
            InputLabelProps={{ style: { color: "white" } }}
            autoComplete="off"
            sx={{
              "& input": {
                color: "white",
              },
              "& .MuiOutlinedInput-root.Mui-focused": {
                borderColor: "white",
                "& fieldset": {
                  borderColor: "white",
                },
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "white",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "white",
              },
            }}
          />
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <TextField
            margin="dense"
            id="disease code"
            type="text"
            required
            label="Disease Code"
            value={formData?.diseaseCode || ""}
            onChange={handleInputChange}
            placeholder="Enter Disease Code"
            fullWidth
            InputLabelProps={{ style: { color: "white" } }}
            autoComplete="off"
            sx={{
              "& input": {
                color: "white",
              },
              "& .MuiOutlinedInput-root.Mui-focused": {
                borderColor: "white",
                "& fieldset": {
                  borderColor: "white",
                },
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "white",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "white",
              },
            }}
          />
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <TextField
            margin="dense"
            id="disease name(e)"
            type="text"
            required
            label="Disease Name(E)"
            value={formData?.diseaseName_EN || ""}
            onChange={handleInputChange}
            placeholder="English Name"
            fullWidth
            InputLabelProps={{ style: { color: "white" } }}
            autoComplete="off"
            sx={{
              "& input": {
                color: "white",
              },
              "& .MuiOutlinedInput-root.Mui-focused": {
                borderColor: "white",
                "& fieldset": {
                  borderColor: "white",
                },
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "white",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "white",
              },
            }}
          />
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <TextField
            margin="dense"
            id="disease name(s)"
            type="text"
            required
            label="Disease Name(S)"
            value={formData?.diseaseName_SN || ""}
            onChange={handleInputChange}
            placeholder="Sanskrit Name"
            fullWidth
            InputLabelProps={{ style: { color: "white" } }}
            autoComplete="off"
            sx={{
              "& input": {
                color: "white",
              },
              "& .MuiOutlinedInput-root.Mui-focused": {
                borderColor: "white",
                "& fieldset": {
                  borderColor: "white",
                },
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "white",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "white",
              },
            }}
          />
        </Grid>
        <Grid item sx={{ ml: "auto" }}>
          <Button
            variant="contained"
            onClick={handleAdd}
            sx={{
              ml: 2,
              backgroundColor: "white",
              color: "darkblue",
              ":hover": {
                backgroundColor: "#e0e0e0",
                color: "darkblue",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              },
            }}
          >
            {action === "add" ? "Add" : "Update"}
          </Button>
          <Button variant="contained" color="error" onClick={handleCancel} sx={{ ml: 2 }}>
            Cancel
          </Button>
        </Grid>
      </Grid>
      {loading ? (
        <motion.div>
          <DNA
            visible={true}
            height={isMobile ? "120" : "200"}
            width={isMobile ? "200" : "250"}
            color="#2B2A29"
            ariaLabel="dna-loading"
            wrapperStyle={{}}
            wrapperClass="dna-wrapper"
          />
        </motion.div>
      ) : (
        <>
          {DaignosticDisease && DaignosticDisease.length === 0 ? ( // Conditionally render no data message
            <div style={{ textAlign: "center", marginTop: "10%" }}>
              <Typography variant="body1" fontSize="1.8rem">
                No Daignostic Disease Data Found
              </Typography>
            </div>
          ) : (
            <DiagnosticDiseaseList
              diseases={diseases}
              onEdit={handleEdit}
              onDelete={handleOpenDeleteConfirmation}
            />
          )}
        </>
      )}
    </>
  );
};

export default DaignosticDisease;

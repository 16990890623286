/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import axios from "axios";
import { DemoPaper } from "../../DemoPaper";
import { toast } from "react-toastify";
import { motion } from "framer-motion";
import { Button, Stack, Tooltip } from "@mui/material";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import OfflineUsersTable from "./OfflineUsersTable";
import AddNewOfflineUser from "./AddNewOfflineUser/AddNewOfflineUser";
import countries from "../../../../country.json";

const OfflineUsers = ({ isMobile }) => {
  const containerStyle = {
    marginLeft: isMobile ? "5%" : "25%",
    marginBottom: isMobile ? "15%" : "3%",
    marginRight: "5%",
    marginTop: isMobile ? "25%" : "5%",
    transition: "margin 0.5s",
    zIndex: 3,
  };

  const [usersList, setUsersList] = useState([]);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [userData, setUserData] = useState({
    name: "",
    mobileNumber: "",
    email: "",
    gender: "",
    age: "",
    countryCode: "+91",
    selectedCountry: countries.find((country) => country.name === "India"),
  });
  const [loading, setLoading] = useState(false);

  const fetchUsers = async () => {
    try {
      setShowLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/users/get/offline/list`,
      );

      if (response && response.status === 200 && response.data && response.data.data) {
        setUsersList(response.data.data);
      } else {
        setUsersList([]);
      }
    } catch (error) {
      setUsersList([]);

      if (error.response && [400, 404, 409, 500].includes(error.response.status)) {
        switch (error.response.status) {
          case 404:
            toast.error("User's details not found");
            break;
          case 500:
            toast.error("An error occurred while fetching user's details. Please try again later.");
            break;
          default:
            toast.error("An error occurred while fetching user's details. Please try again later.");
        }
      } else {
        toast.error(
          "An error occurred while fetching user's details. Please contact the developer.",
        );
      }
    }
    setShowLoading(false);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleModalOpen = () => {
    setIsModelOpen(true);
  };

  const handleModalClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;

    setIsModelOpen(false);

    setUserData({
      name: "",
      mobileNumber: "",
      email: "",
      gender: "",
      age: "",
      countryCode: "+91",
      selectedCountry: countries.find((country) => country.name === "India"),
    });

    setLoading(false);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Stack direction="column" alignItems="center" style={containerStyle}>
        <DemoPaper
          height={isMobile ? "120vh" : "80vh"}
          square={false}
          elevation={24}
          isMobile={isMobile}
        >
          <Tooltip
            title="Add New  Offline User"
            arrow
            style={{ alignSelf: "flex-end", marginRight: "10px", marginBottom: "10px" }}
          >
            <Button
              variant="contained"
              size="large"
              onClick={handleModalOpen}
              startIcon={<PersonAddAlt1Icon />}
              sx={{
                backgroundColor: "#72225e",
                fontWeight: "bold",
                ":hover": {
                  backgroundColor: "#8C3C78",
                },
              }}
            >
              Add New Offline User
            </Button>
          </Tooltip>
          <OfflineUsersTable isMobile={isMobile} users={usersList} showLoading={showLoading} />

          <AddNewOfflineUser
            isOpen={isModelOpen}
            modalClose={handleModalClose}
            userData={userData}
            setUserData={setUserData}
            loading={loading}
            setLoading={setLoading}
            isMobile={isMobile}
          />
        </DemoPaper>
      </Stack>
    </motion.div>
  );
};

export default OfflineUsers;

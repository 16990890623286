/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState, useMemo } from "react";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Divider,
  Box,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import Swal from "sweetalert2";
import { DNA } from "react-loader-spinner";

const AddEditCategoriesModal = ({ state, dispatch, onAddItem, isMobile }) => {
  const [prompt, setPrompt] = useState("");
  const [header, setHeader] = useState("");
  const [description, setDescription] = useState("");
  const [file, setFile] = useState(null);
  const [videoLink, setVideoLink] = useState("");
  const [imageURL, setImageURL] = useState("");
  const [loading, setLoading] = useState(false);

  const fileInputRef = useRef(null);

  const handleChange = (setState, value) => {
    setState(value); // Updates the corresponding state
  };

  // Memoize the handler to avoid unnecessary re-creation
  const memoizedHandleChange = useMemo(() => {
    return (setState, value) => handleChange(setState, value);
  }, []);

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;

    dispatch({ type: "set_category_modal", payload: false });
    setPrompt("");
    setHeader("");
    setDescription("");
    setVideoLink("");
    setFile("");
    setImageURL("");
    dispatch({ type: "set_category_action", payload: "" });
    dispatch({ type: "set_model_type", payload: null });
    dispatch({ type: "set_model_name", payload: null });
  };

  const handleAdd = async () => {
    setLoading(true);

    try {
      if (!header || !description) {
        toast.error("Both header and description are required");
        setLoading(false);
        return;
      }

      let apiEndpoint;

      switch (state.modelType) {
        case "categoryGroup":
          apiEndpoint = `${process.env.REACT_APP_API_URL}/api/qna/post/new/categorygroup`;
          break;
        case "category":
          apiEndpoint = `${process.env.REACT_APP_API_URL}/api/qna/post/new/category/${state.selectedCategoryGroupItem._id}`;
          break;
        case "subCategory":
          apiEndpoint = `${process.env.REACT_APP_API_URL}/api/qna/post/new/subcategory/${state.selectedCategoryItem._id}`;
          break;
        default:
          throw new Error("Invalid category action");
      }

      const requestData = {
        prompt,
        header,
        description,
        videoLink,
        image: file,
        categoryId: state.modelType === "category" ? state.selectedItemId : undefined,
        subCategoryId: state.modelType === "subCategory" ? state.selectedItemId : undefined,
      };

      const response = await axios.post(apiEndpoint, requestData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (response && response.status === 201) {
        toast.success(`${state.modelName} Added Successfully`);
        onAddItem(state.modelType);
        handleClose();
      } else {
        toast.error(`Error while adding ${state.modelName},  Please Try after sometime`);
      }
    } catch (error) {
      console.error("Error while adding Category Models", error);

      if (error.response && [400, 404, 409, 500].includes(error.response.status)) {
        switch (error.response.status) {
          case 400:
            toast.error(`Bad request. Please check your inputs`);
            break;
          case 404:
            toast.error(`${state.modelName} not found`);
            break;
          case 409:
            toast.error(`There is a conflict with the current state of the ${state.modelName}`);
            break;
          case 500:
            toast.error(`Server error while adding ${state.modelName}, Please Try after sometime`);
            break;
          default:
            toast.error(
              `An error occurred while adding ${state.modelName}, Please Try after sometime`,
            );
        }
      } else {
        toast.error(`Unexpected Error while adding ${state.modelName},  Please Contact Developer`);
      }
    }
    setLoading(false);
  };

  const handleEdit = async () => {
    setLoading(true);

    try {
      if (!header || !description) {
        toast.error("Both header and description are required");
        setLoading(false);
        return;
      }

      let modelId;

      switch (state.modelType) {
        case "categoryGroup":
          modelId = state.selectedCategoryGroupItem._id;
          break;
        case "category":
          modelId = state.selectedCategoryItem._id;
          break;
        case "subCategory":
          modelId = state.selectedSubCategoryItem._id;
          break;
        default:
          break;
      }

      const requestData = {
        prompt,
        header,
        description,
        videoLink,
        image: file ? file : imageURL ? imageURL.substring(imageURL.lastIndexOf("/") + 1) : null,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/qna/post/edit/${state.modelType}/${modelId}`,
        requestData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        },
      );

      if (response && response.status === 200) {
        toast.success(`${state.modelName} Edited Successfully`);
        onAddItem(state.modelType);
        handleClose();
      } else {
        toast.error("Failed to edit. Please fill all fields!!!");
      }
    } catch (error) {
      console.error("Error while Updating Category Models", error);

      if (error.response && [400, 404, 409, 500].includes(error.response.status)) {
        switch (error.response.status) {
          case 400:
            toast.error(`Bad request. Please check your inputs`);
            break;
          case 404:
            toast.error(`${state.modelName} not found`);
            break;
          case 409:
            toast.error(`There is a conflict with the current state of the ${state.modelName}`);
            break;
          case 500:
            toast.error(
              `Server error while updating ${state.modelName}, Please Try after sometime`,
            );
            break;
          default:
            toast.error(
              `An error occurred while updating ${state.modelName}, Please Try after sometime`,
            );
        }
      } else {
        toast.error("Unexpected Error while Updating Category Models, Please Contact Developer");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    const getModelData = () => {
      switch (state.modelType) {
        case "categoryGroup":
          return {
            headingKey: "categoryGroupHeading",
            descriptionKey: "categoryGroupDescription",
            videoLinkKey: "categoryGroupVideoLink",
            imageKey: "categoryGroupImage",
          };
        case "category":
          return {
            headingKey: "categoryHeading",
            descriptionKey: "categoryDescription",
            videoLinkKey: "categoryVideoLink",
            imageKey: "categoryImage",
          };
        case "subCategory":
          return {
            headingKey: "subCategoryHeading",
            descriptionKey: "subCategoryDescription",
            videoLinkKey: "subCategoryVideoLink",
            imageKey: "subCategoryImage",
          };
        default:
          return {
            headingKey: "",
            descriptionKey: "",
            videoLinkKey: "",
            imageKey: "",
          };
      }
    };

    const { headingKey, descriptionKey, videoLinkKey, imageKey } = getModelData();

    if (state.categoryAction === "add" && state.modelType) {
      setPrompt("");
      setHeader("");
      setDescription("");
      setVideoLink("");
      setFile("");
      setImageURL("");
    } else if (state.categoryAction === "edit" && state.modelType) {
      let selectedItemId;

      switch (state.modelType) {
        case "categoryGroup":
          selectedItemId = state.selectedCategoryGroupItem._id;
          break;
        case "category":
          selectedItemId = state.selectedCategoryItem._id;
          break;
        case "subCategory":
          selectedItemId = state.selectedSubCategoryItem._id;
          break;
        default:
          break;
      }

      if (selectedItemId) {
        axios
          .get(
            `${process.env.REACT_APP_API_URL}/api/qna/get/edit/${state.modelType}/${selectedItemId}`,
          )
          .then((response) => {
            if (response.status === 200 && response.data && response.data.data) {
              const data = response.data.data;

              setPrompt(data["prompt"]);
              setHeader(data[headingKey]);
              setDescription(data[descriptionKey]);
              setVideoLink(data[videoLinkKey]);
              if (data[imageKey]) {
                setImageURL(`https://7dstatic.s3.ap-south-1.amazonaws.com/trial/${data[imageKey]}`); // Set the image URL for preview
                setFile(null);
              } else {
                setFile("");
                setImageURL("");
              }
            } else {
              toast.error("Error fetching model data");
            }
          })
          .catch((error) => {
            console.error("Error fetching data:", error);

            if (error.response && [400, 404, 409, 500].includes(error.response.status)) {
              switch (error.response.status) {
                case 400:
                  toast.error(`Bad request. Please check your inputs`);
                  break;
                case 404:
                  toast.error(`${state.modelName} not found`);
                  break;
                case 409:
                  toast.error(
                    `There is a conflict with the current state of the ${state.modelName}`,
                  );
                  break;
                case 500:
                  toast.error(
                    `Server error while fetching ${state.modelName}, Please Try after sometime`,
                  );
                  break;
                default:
                  toast.error(
                    `An error occurred while fetching ${state.modelName}, Please Try after sometime`,
                  );
              }
            } else {
              toast.error("Error while fetching model data, Please Contact Developer");
            }
          });
      }
    }
  }, [state.categoryAction, state.modelType]);

  const handleCKEditorChange = (event, editor) => {
    setDescription(editor.getData());
  };

  const fileSelected = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      if (file) {
        setFile(file);
        setImageURL("");
      }
    } else {
      console.error("No image selected");
    }
  };

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleRemove = () => {
    setFile("");
    setImageURL("");
  };

  const handleCancel = () => {
    Swal.fire({
      title: "Confirm Deletion",
      text: "Are you sure you want to remove selected or existing image ???",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
      didOpen: () => {
        document.querySelector(".swal2-container").style.zIndex = "2000";
      },
    }).then((result) => {
      if (result.isConfirmed) {
        handleRemove();
      } else {
        // example codes to clear up
      }
    });
  };

  return (
    <Dialog open={state.categoryModal} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle
        style={{
          fontSize: "1.6rem",
        }}
      >
        {state.categoryAction === "add" ? `Add New ${state.modelName}` : `Edit ${state.modelName}`}
      </DialogTitle>

      <Divider sx={{ bgcolor: "black" }} />

      <PerfectScrollbar options={{ wheelPropagation: false }}>
        <DialogContent>
          {loading ? (
            <DNA
              visible={true}
              height={isMobile ? "120" : "200"}
              width={isMobile ? "200" : "250"}
              color="#2B2A29"
              ariaLabel="dna-loading"
              wrapperStyle={{}}
              wrapperClass="dna-wrapper"
            />
          ) : (
            <>
              <TextField
                autoFocus
                margin="dense"
                id="prompt"
                label="Prompt (Optional)"
                placeholder={`Enter Ai  ${state.modelName} Prompt Text`}
                type="text"
                fullWidth
                autoComplete="off"
                value={prompt}
                onChange={(e) => memoizedHandleChange(setPrompt, e.target.value)}
                InputLabelProps={{
                  style: { color: "black" }, // Style for label
                }}
                InputProps={{
                  sx: {
                    "&:focused": { borderColor: "black" }, // Border color when focused
                  },
                }}
                sx={{
                  mb: 1.2,
                  my: 2,
                  "& input:focus": {
                    borderColor: "black !important",
                  },
                  "& .MuiOutlinedInput-root.Mui-focused": {
                    "& fieldset": {
                      borderColor: "black !important",
                    },
                  },
                }}
              />

              <TextField
                autoFocus
                margin="dense"
                id="header"
                label="Header"
                type="text"
                placeholder={`Enter ${state.modelName} Header`}
                onChange={(e) => memoizedHandleChange(setHeader, e.target.value)}
                required
                fullWidth
                autoComplete="off"
                value={header}
                InputLabelProps={{
                  style: { color: "black" }, // Style for label
                }}
                InputProps={{
                  sx: {
                    "&:focused": { borderColor: "black" }, // Border color when focused
                  },
                }}
                sx={{
                  mb: 1.2,
                  my: 2,
                  "& input:focus": {
                    borderColor: "black !important",
                  },
                  "& .MuiOutlinedInput-root.Mui-focused": {
                    "& fieldset": {
                      borderColor: "black !important",
                    },
                  },
                }}
              />

              <div>
                <Typography variant="body1" htmlFor="editor" sx={{ mt: 2, my: 1, color: "black" }}>
                  {state.modelName} Description *
                </Typography>
                <CKEditor
                  editor={ClassicEditor}
                  data={description}
                  onChange={handleCKEditorChange}
                  config={{
                    toolbar: {
                      items: [
                        "heading",
                        "|",
                        "bold",
                        "italic",
                        "link",
                        "bulletedList",
                        "numberedList",
                        "|",
                        "blockQuote",
                        "undo",
                        "redo",
                        "|",
                        "fontFamily",
                        "fontColor",
                        "fontBackgroundColor",
                        "fontSize",
                      ],
                    },
                  }}
                  id="editor"
                />
              </div>

              <TextField
                autoFocus
                margin="dense"
                id="videoLink"
                label="Video Link (Optional)"
                type="text"
                placeholder={`Enter ${state.modelName} Video Link https://www.youtube.com/`}
                fullWidth
                autoComplete="off"
                value={videoLink}
                onChange={(e) => memoizedHandleChange(setVideoLink, e.target.value)}
                InputLabelProps={{
                  style: { color: "black" }, // Style for label
                }}
                InputProps={{
                  sx: {
                    "&:focused": { borderColor: "black" }, // Border color when focused
                  },
                }}
                sx={{
                  mb: 1.2,
                  my: 2,
                  "& input:focus": {
                    borderColor: "black !important",
                  },
                  "& .MuiOutlinedInput-root.Mui-focused": {
                    "& fieldset": {
                      borderColor: "black !important",
                    },
                  },
                }}
              />

              <Paper elevation={12} sx={{ padding: "20px", mt: 2 }}>
                <Grid container alignItems="center">
                  <input
                    ref={fileInputRef}
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={fileSelected}
                  />
                  <Button variant="contained" onClick={handleClick} sx={{ mr: 2 }}>
                    CHOOSE AN IMAGE (OPTIONAL)
                  </Button>
                  {file || imageURL ? (
                    <Typography variant="body1">
                      {file ? file.name : imageURL.substring(imageURL.lastIndexOf("/") + 1)}
                    </Typography>
                  ) : (
                    <Typography variant="body1" color="black">
                      No Image Choosen{" "}
                    </Typography>
                  )}
                  <Box
                    sx={{
                      marginLeft: "auto", // Pushes the CloseIcon to the right side
                      alignSelf: "center", // Centers vertically
                      cursor: "pointer",
                    }}
                  >
                    <Tooltip title="Remove Selected Image" arrow>
                      <IconButton
                        edge="end"
                        aria-label="add"
                        onClick={handleCancel}
                        sx={{ color: "black" }}
                        size="large"
                      >
                        <CloseIcon fontSize="large" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Grid>
              </Paper>

              {(file || imageURL) && (
                <Paper elevation={12} sx={{ padding: "20px", mt: 2 }}>
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="flex-start"
                  >
                    <Grid container justifyContent="center" alignItems="center" sx={{ mb: 2 }}>
                      <Typography variant="h6" component="h2" justifyContent="center">
                        Selected Image: {"  "}
                        {file ? file.name : imageURL.substring(imageURL.lastIndexOf("/") + 1)}
                      </Typography>
                    </Grid>
                    <Grid container justifyContent="center" alignItems="center">
                      <img
                        src={file ? URL.createObjectURL(file) : imageURL}
                        alt="Uploaded File"
                        style={{ maxWidth: "100%" }}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              )}
            </>
          )}
        </DialogContent>
      </PerfectScrollbar>
      <Divider sx={{ bgcolor: "black" }} />

      <DialogActions>
        <Button
          variant="outlined"
          color="primary"
          onClick={state.categoryAction === "add" ? handleAdd : handleEdit}
          startIcon={state.categoryAction === "add" ? <SaveIcon /> : <BorderColorIcon />}
        >
          {state.categoryAction === "add" ? "Add" : "Edit"}
        </Button>
        <Button variant="outlined" color="error" onClick={handleClose} startIcon={<CloseIcon />}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddEditCategoriesModal;

/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import HolidayTable from "./HolidayTable";
import AddHolidays from "./AddHolidays";
import axios from "axios";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import Swal from "sweetalert2";

const Holidays = () => {
  const [holidays, setHolidays] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedHoliday, setSelectedHoliday] = useState(null);

  const handleOpenModal = () => {
    setOpenModal(true);
    setSelectedHoliday(null);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const fetchAllHolidays = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/company-holidays/get/all/holidays`)
      .then((response) => {
        if (response.status === 200) {
          console.log("All holidays fetched successfully:", response.data);
          setHolidays(response.data.data);
        } else {
          console.error("Error fetching holidays. Unexpected status:", response.status);
        }
      })
      .catch((error) => {
        console.error("Error fetching holidays:", error);
      });
  };

  useEffect(() => {
    fetchAllHolidays();
  }, []);

  const handleSaveHoliday = (newHoliday) => {
    const formattedDate = dayjs(newHoliday.date).format("YYYY-MM-DD");
    // console.log("formattedDate", formattedDate);

    const holidayData = {
      holidayName: newHoliday.holidayName,
      date: formattedDate,
    };

    axios
      .post(`${process.env.REACT_APP_API_URL}/api/company-holidays/post/new/holidays`, holidayData)
      .then((response) => {
        if (response.status === 201) {
          toast.success("Holiday Added Successfully");
          fetchAllHolidays();
        } else {
          console.error("Error creating new holiday. Unexpected status:", response.status);
        }
      })
      .catch((error) => {
        console.error("Error creating new holiday:", error);
      });
  };

  const handleUpdateHoliday = (holiday) => {
    console.log("fetch edit holiday", holiday);
    setSelectedHoliday(holiday);
    setOpenModal(true);
  };

  const handleUpdate = (updatedHoliday) => {
    const index = holidays.findIndex((holiday) => holiday._id === updatedHoliday._id);
    if (index !== -1) {
      const updatedHolidays = [...holidays];
      updatedHolidays[index] = updatedHoliday;
      setHolidays(updatedHolidays);

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/company-holidays/post/update/holiday/${updatedHoliday._id}`,
          updatedHoliday,
        )
        .then((response) => {
          if (response.status === 200) {
            console.log("Holiday updated successfully:", updatedHoliday);
            toast.success("Holiday Updated Successfully");
            setHolidays();
            fetchAllHolidays();
          } else {
            console.error("Error updating holiday. Unexpected status:", response.status);
          }
        })
        .catch((error) => {
          console.error("Error updating holiday:", error);
        });
    }
    handleCloseModal();
  };

  const handleDeleteHoliday = (holidayId) => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}/api/company-holidays/delete/${holidayId}`)
      .then((response) => {
        if (response.status === 200) {
          console.log("Holiday deleted successfully", response.data);
          toast.success("Holiday Deleted Successfully");
          setHolidays(holidays.filter((holiday) => holiday._id !== holidayId));
        } else {
          console.error("Error deleting holiday. Unexpected status:", response.status);
        }
      })
      .catch((error) => {
        console.error("Error deleting holiday:", error);
      });
  };

  const handleOpenDeleteConfirmation = (holidayId) => {
    console.log("holidayId", holidayId);
    setSelectedHoliday(holidayId);

    Swal.fire({
      title: "Confirm Deletion",
      text: "Are you sure you want to delete this disease?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDeleteHoliday(holidayId);
      }
    });
  };

  return (
    <div>
      <div style={{ textAlign: "right", marginBottom: "20px" }}>
        <Button
          onClick={handleOpenModal}
          variant="contained"
          sx={{
            color: "primary",
          }}
        >
          Add Holiday
        </Button>
      </div>
      <AddHolidays
        open={openModal}
        handleClose={handleCloseModal}
        handleSave={handleSaveHoliday}
        selectedHoliday={selectedHoliday}
        handleUpdate={handleUpdate}
      />
      <HolidayTable
        holidays={holidays}
        handleUpdate={handleUpdateHoliday}
        handleDelete={handleOpenDeleteConfirmation}
      />
    </div>
  );
};

export default Holidays;

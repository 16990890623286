import React, { useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { List, ListItemButton, ListItemText, Typography, Paper, Collapse } from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

const SideBarList = ({ state, dispatch, options }) => {
  const [expanded, setExpanded] = useState("");

  const handleListItemClick = (name) => {
    if (expanded === name) {
      setExpanded("");
    } else {
      setExpanded(name);
    }

    if (name === "Sales" || name === "Purchases" || name === "Inventory") {
      return;
    } else {
      dispatch({
        type: "set_component",
        payload: name,
      });
      dispatch({
        type: "set_is_mobile_drawer_open",
        payload: false,
      });
      dispatch({
        type: "set_selected_option",
        payload: name,
      });
    }
  };

  const handleExpandClick = (name) => {
    if (expanded === name) {
      setExpanded("");
    } else {
      setExpanded(name);
    }
  };

  const handleSubOptionClick = (name) => {
    dispatch({
      type: "set_component",
      payload: name,
    });
    dispatch({
      type: "set_is_mobile_drawer_open",
      payload: false,
    });

    dispatch({
      type: "set_selected_option",
      payload: name,
    });
  };

  return (
    <PerfectScrollbar options={{ wheelPropagation: false }}>
      <List sx={{ overflowX: "hidden" }}>
        {options &&
          options.map((option) => (
            <Paper
              key={option.name}
              elevation={4}
              sx={{
                borderRadius: "20px",
                mb: 1,
                mx: 1,
                overflow: "hidden",
              }}
            >
              <ListItemButton
                onClick={() => handleListItemClick(option.name)}
                selected={state.selectedOption === option.name}
                sx={{
                  borderRadius: "20px",
                  "&:hover": {
                    backgroundColor: state.selectedOption === option.name ? "#721F4B" : "#99729A",
                  },
                  backgroundColor: state.selectedOption === option.name ? "#721F4B" : "white",
                  ".Mui-selected&": {
                    backgroundColor: "#721F4B",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#99729A",
                    },
                  },
                  "&:not(.Mui-selected):hover": {
                    backgroundColor: "#99729A",
                  },
                }}
              >
                <ListItemText
                  primary={
                    <Typography
                      sx={{
                        ml: 1,
                        fontSize: "110%",
                        fontWeight: "bold",
                        color: state.selectedOption === option.name ? "white" : "black",
                      }}
                    >
                      {option.name}
                    </Typography>
                  }
                />
                {option?.subOptions &&
                  (expanded === option.name ? (
                    <ExpandLess onClick={() => handleExpandClick(option.name)} />
                  ) : (
                    <ExpandMore onClick={() => handleExpandClick(option.name)} />
                  ))}
              </ListItemButton>
              {option?.subOptions && (
                <Collapse in={expanded === option.name} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {option.subOptions.map((subOption, index) => (
                      <ListItemButton
                        key={subOption.name}
                        onClick={() => handleSubOptionClick(subOption.name)}
                        selected={state.selectedOption === subOption.name}
                        sx={{
                          my: 1,
                          pl: 4,
                          "&:hover": {
                            backgroundColor:
                              state.selectedOption === subOption.name ? "#721F4B" : "#99729A",
                          },
                          backgroundColor:
                            state.selectedOption === subOption.name ? "#721F4B" : "white",
                          ".Mui-selected&": {
                            backgroundColor: "#721F4B",
                            color: "white",
                            "&:hover": {
                              backgroundColor: "#99729A",
                            },
                          },
                          "&:not(.Mui-selected):hover": {
                            backgroundColor: "#99729A",
                          },
                        }}
                      >
                        <ListItemText
                          primary={
                            <Typography
                              sx={{
                                ml: 1,
                                fontSize: "110%",
                                fontWeight: "bold",
                                color: state.selectedOption === subOption.name ? "white" : "black",
                              }}
                            >
                              {subOption.name}
                            </Typography>
                          }
                        />
                      </ListItemButton>
                    ))}
                  </List>
                </Collapse>
              )}
            </Paper>
          ))}
      </List>
    </PerfectScrollbar>
  );
};

export default SideBarList;

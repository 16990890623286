import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import ScheduleIcon from "@mui/icons-material/Schedule";
import DeleteIcon from "@mui/icons-material/Delete";
import "react-perfect-scrollbar/dist/css/styles.css";
import { Button, Tooltip } from "@mui/material";
import axios from "axios";
import IntakeFormDialog from "./IntakeFormDialog";

const UpcomingAppointmentsTable = ({ patient }) => {
  const [appointments, setAppointments] = useState([]);
  const [, setError] = useState(null);
  const [intakeFormData, setIntakeFormData] = useState(null);
  const [intakeFormDialogOpen, setIntakeFormDialogOpen] = useState(false);

  useEffect(() => {
    const fetchAppointments = async () => {
      try {
        if (patient && patient._id) {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/appointment/get/upcoming/selected/patient/${patient._id}`,
          );
          console.log("dhdhd", response);
          if (response.data.success) {
            const formattedAppointments = response.data.data.map((appointment, index) => ({
              id: index + 1, // Ensure each appointment has a unique id
              date: new Date(appointment.date).toISOString().split("T")[0], // Format date as YYYY-MM-DD
              startTime: appointment.appointments[0]?.startTime || "",
              doctorName: appointment.appointments[0]?.doctorName || "",
            }));
            setAppointments(formattedAppointments);
          } else {
            console.error("Error fetching appointments:", response.data.message);
          }
        }
      } catch (error) {
        setError(error);
      }
    };

    fetchAppointments();
  }, [patient]);

  const handleIntakeFormClick = async () => {
    try {
      if (patient && patient._id) {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/patient/get/selected/intake-form/${patient._id}`,
        );
        if (response.data.success) {
          setIntakeFormData(response.data.data[0]);
          setIntakeFormDialogOpen(true);
        } else {
          console.error("Error fetching intake form data:", response.data.message);
        }
      }
    } catch (error) {
      console.error("Error fetching intake form data:", error);
    }
  };

  const handleCloseIntakeFormDialog = () => {
    setIntakeFormDialogOpen(false);
  };

  // Map appointments to rows with unique IDs
  const appointmentsWithId =
    appointments &&
    appointments.map((appointment, index) => ({
      ...appointment,
      id: index + 1,
    }));

  // Define columns for the data grid
  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "date", headerName: "Date", width: 130 },
    { field: "startTime", headerName: "Start Time", width: 150 },
    { field: "doctorName", headerName: "Doctor Name", width: 150 },
    {
      field: "intakeForm",
      headerName: "Intake Form",
      width: 150,
      renderCell: (params) => <Button onClick={handleIntakeFormClick}>View Form</Button>,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 130,
      flex: 0,
      renderCell: () => (
        <div>
          <Tooltip title="Reschedule" arrow>
            <ScheduleIcon />
          </Tooltip>
          <Tooltip title="Delete" arrow>
            <DeleteIcon />
          </Tooltip>
        </div>
      ),
    },
    { field: "createVisit", headerName: "Visits", width: 150 },
  ];

  return (
    <div
      style={{
        height: 300,
        width: "90%",
        margin: "auto",
      }}
    >
      {/* Render the data grid with appointments data */}
      <DataGrid
        rows={appointmentsWithId}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        sx={{
          backgroundColor: "rgba(240, 240, 240, 0.7)",
          "& .MuiDataGrid-columnHeader, & .MuiDataGrid-row": {
            fontSize: "1rem",
          },
          "& .MuiDataGrid-cell": {
            color: "black", // Set text color of cell data
          },
        }}
      />
      {/* Render the intake form dialog */}
      <IntakeFormDialog
        isOpen={intakeFormDialogOpen}
        handleClose={handleCloseIntakeFormDialog}
        intakeFormData={intakeFormData}
      />
    </div>
  );
};

export default UpcomingAppointmentsTable;

const headerStyles = `
  .custom-header {
    background-color: #721F4B; /* Set the background color for the header */
    color: white; /* Set the text color for the header */
    font-weight: bold; /* Set the font weight for the header text */
    font-size: 16px; /* Set the font size for the header text */
  }
`;
// Inject the styles into the component
const styleElement = document.createElement("style");
styleElement.innerHTML = headerStyles;
document.head.appendChild(styleElement);

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { List, Button, Grid, Paper, Tooltip, Typography, IconButton } from "@mui/material";
import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import DeleteIcon from "@mui/icons-material/Delete";
import dayjs from "dayjs";
import axios from "axios";
import { toast } from "react-toastify";
import { MobileDatePicker } from "@mui/x-date-pickers";

const CalendarSlots = ({ selectedDoctorId }) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [timeSlots, setTimeSlots] = useState([]);
  const [noSlotsMessage, setNoSlotsMessage] = useState("");
  const [type, setType] = useState("time");
  const [selectedSlotIndex, setSelectedSlotIndex] = useState(null); // State to track selected slot index
  const [unavailableDates, setUnavailableDates] = useState([
    "2024-05-10", // Example of an unavailable date
    "2024-05-15", // Another example of an unavailable date
  ]);

  const handleDateChange = async (selectedDate) => {
    setSelectedDate(selectedDate);
    const formattedDate = dayjs(selectedDate).format("YYYY-MM-DD");

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/doctor-slots/get/available/slots/${selectedDoctorId}/${formattedDate}`,
      );
      if (response.status === 200) {
        setTimeSlots(
          response.data.data.map((startTime) => ({ start: startTime, available: true })),
        );
        console.log("slots", response.data);
        if (response.data.data.length === 0) {
          setNoSlotsMessage("No slots available for selected date");
        } else {
          setNoSlotsMessage("");
        }
      } else {
        setTimeSlots([]);
        console.error("Failed to fetch available slots:", response.statusText);
      }
    } catch (error) {
      setTimeSlots([]);
      console.error("Error fetching available slots:", error);
    }
  };

  useEffect(() => {
    handleDateChange(selectedDate);
  }, []);

  const handleDeleteSlots = async (itemToDelete) => {
    const formattedDate = dayjs(selectedDate).format("YYYY-MM-DD");

    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/doctor-slots/delete/slots/${selectedDoctorId}`,
        {
          data: {
            date: formattedDate,
            time: typeof itemToDelete === "string" ? itemToDelete : null,
            type: typeof itemToDelete === "string" ? "time" : "date",
          },
        },
      );
      if (response.status === 200) {
        toast.success(
          `Slot timing ${
            typeof itemToDelete === "string" ? "deleted" : "for selected date deleted"
          } successfully`,
        );

        if (typeof itemToDelete === "string") {
          const updatedSlots = [...timeSlots];
          updatedSlots.splice(selectedSlotIndex, 1);
          setTimeSlots(updatedSlots);
          setSelectedSlotIndex(null);
        } else {
          setTimeSlots([]);
        }
      }
    } catch (error) {
      console.error("Error deleting slot:", error);
    }
  };

  const handleTimeClick = (index) => {
    if (selectedSlotIndex === index) {
      // If the clicked slot is already selected, deselect it
      setSelectedSlotIndex(null);
    } else {
      // Otherwise, select the clicked slot
      setSelectedSlotIndex(index);
    }
  };

  const isDateDisabled = (date) => {
    return unavailableDates.includes(dayjs(date).format("YYYY-MM-DD"));
  };

  return (
    <>
      <div style={{ display: "flex", height: 430, width: "100%" }}>
        <div style={{ flex: 1, textAlign: "center" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]}>
              <DateCalendar onChange={handleDateChange} isDateDisabled={isDateDisabled} />
              {/* <MobileDatePicker onChange={handleDateChange} isDateDisabled={isDateDisabled} /> */}
            </DemoContainer>
          </LocalizationProvider>
        </div>
        <div style={{ flex: 1 }}>
          {noSlotsMessage && (
            <Typography variant="body1" color="error" align="center">
              {noSlotsMessage}
            </Typography>
          )}

          <Grid container spacing={1} direction="column">
            <div style={{ textAlign: "center", marginTop: "20px" }}>
              <Button
                variant="contained"
                color="error"
                onClick={() => handleDeleteSlots(selectedDate)}
              >
                Delete Selected Date
              </Button>
              {selectedSlotIndex !== null && (
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => handleDeleteSlots(timeSlots[selectedSlotIndex].start)}
                  style={{ marginLeft: "10px", marginTop: "10px" }}
                >
                  Delete Selected Time Slot
                </Button>
              )}
            </div>
            <Grid item container spacing={1}>
              {timeSlots &&
                timeSlots.map((slot, index) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                    <List>
                      <Paper
                        variant="outlined"
                        // style={{
                        //   backgroundColor: selectedSlotIndex === index ? "white" : "primary.main",
                        //   color: selectedSlotIndex === index ? "primary.main" : "primary.main",
                        // }}
                      >
                        <Button
                          fullWidth
                          variant="body1"
                          style={{
                            backgroundColor: slot.available ? "white" : "gray",
                            color: slot.available ? "black" : "white",
                          }}
                          onClick={() => handleTimeClick(index)}
                        >
                          {slot.start}
                        </Button>
                      </Paper>
                    </List>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default CalendarSlots;

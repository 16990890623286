import React, { useState, useEffect } from "react";
import axios from "axios";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Switch from "@mui/material/Switch";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemText,
  Divider,
  Paper,
  Typography,
} from "@mui/material";
import { DNA } from "react-loader-spinner";
import { motion } from "framer-motion";

const AddQuestionsModal = ({ state, dispatch, onAddItem, isMobile }) => {
  const [questions, setQuestions] = useState([]);
  const [selectedQuestions, setSelectedQuestions] = useState(new Set());
  const [selectedQuestionsArray, setSelectedQuestionsArray] = useState([]);
  const [checked, setChecked] = useState(true);
  const [apiEndpoint, setApiEndpoint] = useState(
    `${process.env.REACT_APP_API_URL}/api/qna/get/all/question`,
  );
  const [loadingSwitch, setLoadingSwitch] = useState(false);

  const handleChange = (event) => {
    setLoadingSwitch(true);
    const newApiEndpoint = event.target.checked
      ? `${process.env.REACT_APP_API_URL}/api/qna/get/all/question`
      : `${process.env.REACT_APP_API_URL}/api/qna/get/each/category/questions/${state.selectedCategoryItem._id}`;

    setApiEndpoint(newApiEndpoint);
    setChecked(event.target.checked);
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    dispatch({ type: "set_question_modal", payload: false });
    setSelectedQuestions(new Set());
    setSelectedQuestionsArray([]);
    setApiEndpoint(`${process.env.REACT_APP_API_URL}/api/qna/get/all/question`);
    setChecked(true);
  };

  const handleSubmit = async () => {
    dispatch({
      type: "set_questions",
      payload: selectedQuestionsArray,
    });

    const formattedQuestionIds =
      selectedQuestionsArray &&
      selectedQuestionsArray.map((question) => ({
        _id: question._id,
      }));

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/qna/post/questions/${state.selectedSubCategoryItem._id}`,
        { questions: formattedQuestionIds },
      );

      if (response && response.status === 201) {
        toast.success(`New Question Added Successfully`);
        setSelectedQuestions(new Set());
        setSelectedQuestionsArray([]);
        onAddItem("questions");
      } else {
        toast.error("Error posting questions");
      }
    } catch (error) {
      console.error("Error posting questions:", error);

      if (error.response && [400, 404, 409, 500].includes(error.response.status)) {
        switch (error.response.status) {
          case 400:
            toast.error("Bad request. Please check selected questions");
            break;
          case 404:
            toast.error("Seleted category not found");
            break;
          case 409:
            toast.error("There is a conflict with the current state of the questions");
            break;
          case 500:
            toast.error("Server error while adding questions, Please Try after sometime");
            break;
          default:
            toast.error("An error occurred while adding questions, Please Try after sometime");
        }
      } else {
        toast.error("Error posting questions, Please Contact Developer");
      }
    }

    handleClose();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(apiEndpoint);

        if (response && response.status === 200 && response.data && response.data.data) {
          setQuestions(response.data.data); // Assuming the API response is an array of questions
        } else {
          setQuestions("");
          toast.error("Error fetching questions");
        }
      } catch (error) {
        console.error("Error fetching questions:", error);

        setQuestions("");
        if (error.response && [400, 404, 409, 500].includes(error.response.status)) {
          switch (error.response.status) {
            case 400:
              toast.error("Bad request. Please check selected questions");
              break;
            case 404:
              toast.error("Seleted category not found");
              break;
            case 409:
              toast.error("There is a conflict with the current state of the questions");
              break;
            case 500:
              toast.error("Server error while fetching questions, Please Try after sometime");
              break;
            default:
              toast.error("An error occurred while fetching questions, Please Try after sometime");
          }
        } else {
          toast.error("Error while fetching questions, Please Contact Developer");
        }
      }
      setLoadingSwitch(false);
    };

    fetchData();
  }, [apiEndpoint, state.selectedCategoryItem]);

  const handleListItemClick = (question) => {
    const newSelectedQuestions = new Set(selectedQuestions);
    const newSelectedQuestionsArray = [];

    if (newSelectedQuestions.has(question.questiontext)) {
      newSelectedQuestions.delete(question.questiontext);
    } else {
      newSelectedQuestions.add(question.questiontext);
    }

    newSelectedQuestions.forEach((text) => {
      const selectedQuestion = questions.find((q) => q.questiontext === text);
      if (selectedQuestion) {
        newSelectedQuestionsArray.push({
          _id: selectedQuestion._id,
          questiontext: selectedQuestion.questiontext,
        });
      }
    });

    setSelectedQuestions(newSelectedQuestions);
    setSelectedQuestionsArray(newSelectedQuestionsArray);
  };

  return (
    <Dialog open={state.questionModal} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle sx={{ fontSize: isMobile ? "1.4rem" : "1.7rem" }}>
        Select One or Multiple Questions
      </DialogTitle>
      <Divider />
      <div style={{ alignSelf: "flex-end", marginRight: "10px" }}>
        <Typography sx={{ fontSize: isMobile ? "1rem" : "1.2rem" }}>List All Questions</Typography>

        {loadingSwitch ? (
          <div style={{ position: "relative", width: "100%", height: "100%", marginLeft: "-120%" }}>
            <motion.div>
              <DNA
                visible={true}
                height={isMobile ? "120" : "200"}
                width={isMobile ? "200" : "250"}
                color="#2B2A29"
                ariaLabel="dna-loading"
                wrapperStyle={{}}
                wrapperClass="dna-wrapper"
              />
            </motion.div>
          </div>
        ) : (
          <Switch
            checked={checked}
            onChange={handleChange}
            inputProps={{ "aria-label": "controlled" }}
          />
        )}
      </div>

      {!loadingSwitch && questions && questions.length > 0 ? (
        <PerfectScrollbar>
          <DialogContent>
            <List>
              {questions.map((question) => (
                <Paper
                  key={question._id}
                  elevation={12}
                  sx={{
                    borderRadius: "20px",
                    mb: 1,
                    mx: 1,
                    overflow: "hidden",
                  }}
                >
                  <ListItem
                    key={question._id}
                    onClick={() => handleListItemClick(question)}
                    sx={{
                      backgroundColor:
                        selectedQuestions && selectedQuestions.has(question.questiontext)
                          ? "#890F3C"
                          : "white",
                      "&:hover": {
                        backgroundColor: "#E22C6F",
                        cursor: "default",
                      },
                      userSelect: "none",
                    }}
                  >
                    <ListItemText
                      primary={
                        <Typography
                          sx={{
                            fontSize: isMobile ? "1.2rem" : "1.5rem",
                            color:
                              selectedQuestions && selectedQuestions.has(question.questiontext)
                                ? "white"
                                : "black",
                          }}
                        >
                          {question.questiontext}
                        </Typography>
                      }
                    />
                  </ListItem>
                </Paper>
              ))}
            </List>
          </DialogContent>
        </PerfectScrollbar>
      ) : (
        <DialogContent>
          {!loadingSwitch && (
            <Typography fontSize="1.6rem" sx={{ textAlign: "center", mt: "-3%" }}>
              {questions && questions.length === 0
                ? "No data found."
                : "Select an option from category"}
            </Typography>
          )}
        </DialogContent>
      )}

      <Divider />

      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          sx={{ fontSize: isMobile ? "1rem" : "1.2rem" }}
          onClick={handleSubmit}
        >
          Add
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          sx={{ fontSize: isMobile ? "1rem" : "1.2rem" }}
          onClick={handleClose}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddQuestionsModal;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useReducer, useState } from "react";
import axios from "axios";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import QuestionsList from "./QuestionsList";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton, Tooltip, Stack } from "@mui/material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import { useScroll } from "../../../ScrollContext";
import { DemoPaper } from "../DemoPaper";
import { DNA } from "react-loader-spinner";

const initialState = {
  selectedQuestionItem: null,
  questionsRefreshFlag: false,
  questions: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case "set_selected_question_item":
      return { ...state, selectedQuestionItem: action.payload };
    case "set_questions_refresh_flag":
      return { ...state, questionsRefreshFlag: action.payload };
    case "set_questions":
      return { ...state, questions: action.payload };
    default:
      return state;
  }
};

const Questions = ({ isMobile }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [loading, setLoading] = useState(false);

  const { scrollToTop } = useScroll();

  useEffect(() => {
    scrollToTop();
  }, []);

  const containerStyle = {
    marginLeft: isMobile ? "5%" : "25%",
    marginBottom: isMobile ? "15%" : "3%",
    marginRight: "5%",
    marginTop: isMobile ? "25%" : "8%",
    transition: "margin 0.5s",
    zIndex: 3,
  };

  const handleDelete = () => {
    setLoading(true); // Set loading to true before deletion
    dispatch({ type: "set_questions", payload: [] }); // Clear questions list

    const questionId = state.selectedQuestionItem._id;

    axios
      .delete(`${process.env.REACT_APP_API_URL}/api/qna/delete/each/question/${questionId}`)
      .then((response) => {
        if (response.status === 200) {
          toast.success(`Question Deleted Successfully`);
          dispatch({
            type: "set_questions_refresh_flag",
            payload: !state.questionsRefreshFlag,
          });
        } else {
          toast.error("Error deleting delete question, Please Try after sometime");
        }

        setLoading(false);
      })
      .catch((error) => {
        console.error("Error deleting question", error);

        if (error.response && [400, 404, 409, 500].includes(error.response.status)) {
          switch (error.response.status) {
            case 400:
              toast.error("Bad request. Please check selected question");
              break;
            case 404:
              toast.error("Seleted question not found");
              break;
            case 409:
              toast.error("There is a conflict with the current state of the question");
              break;
            case 500:
              toast.error("Server error while deleting question, Please Try after sometime");
              break;
            default:
              toast.error("An error occurred while deleting question, Please Try after sometime");
          }
        } else {
          toast.error("Error deleting delete question, Please Contact Developer");
        }

        setLoading(false);
      });
  };

  const handleDeleteConfirmation = () => {
    Swal.fire({
      title: "Confirm Deletion",
      text: "Are you sure you want to delete this question ???",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete();
      } else {
        // example codes to clear up
      }
    });
  };

  const showToastNotification = () => {
    toast.warning("Please select a question before deleting.");
  };

  return (
    <div style={containerStyle}>
      <Stack direction="column" spacing={4} alignItems="center">
        <DemoPaper
          height={isMobile ? "120vh" : "90vh"}
          square={false}
          elevation={24}
          isMobile={isMobile}
        >
          <Tooltip
            title="Delete Question Set"
            arrow
            style={{ alignSelf: "flex-end", marginRight: "10px" }}
          >
            <IconButton
              aria-label="delete"
              onClick={() => {
                state.selectedQuestionItem ? handleDeleteConfirmation() : showToastNotification();
              }}
              sx={{
                color: "black",
                width: "3rem",
                height: "3rem",
              }} // Adjust size here
            >
              <DeleteIcon sx={{ fontSize: "2rem" }} />
            </IconButton>
          </Tooltip>
          <PerfectScrollbar options={{ wheelPropagation: false }}>
            {loading ? ( // Render DNA loading spinner only during loading state
              <div style={{ display: "flex", justifyContent: "center" }}>
                <DNA
                  visible={true}
                  height={isMobile ? "120" : "200"}
                  width={isMobile ? "200" : "250"}
                  color="#2B2A29"
                  ariaLabel="dna-loading"
                  wrapperStyle={{}}
                  wrapperClass="dna-wrapper"
                />
              </div>
            ) : (
              <QuestionsList state={state} dispatch={dispatch} isMobile={isMobile} />
            )}
          </PerfectScrollbar>
        </DemoPaper>
      </Stack>
    </div>
  );
};

export default Questions;

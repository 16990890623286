/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Button,
  Modal,
} from "@mui/material";
import axios from "axios";
import { Backdrop, ClickAwayListener } from "@mui/material";
import { Document, Page, pdfjs } from "react-pdf";
import { DNA } from "react-loader-spinner";
import { motion } from "framer-motion";
import { toast } from "react-toastify";

const DoctorDetails = ({ selectedDoctorId, isMobile }) => {
  const [doctorData, setDoctorData] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [numPages, setNumPages] = useState(0);
  const [showLoading, setShowLoading] = useState(true);
  const [openModalIndex, setOpenModalIndex] = useState(null);

  // console.log("selectedDoctorId", selectedDoctorId);

  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

  useEffect(() => {
    const fetchDoctorDetails = async () => {
      try {
        if (selectedDoctorId) {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/doctor/get/selected/${selectedDoctorId}`,
          );
          if (response && response.status === 200 && response.data && response.data.data) {
            setDoctorData(response.data.data);
            setShowLoading(false);
            console.log("fetch doctor details", response.data.data);
          } else {
            console.error("Error fetching doctor details:", response);
            setShowLoading(false);
            toast.error("Error fetching doctor details:");
          }
        }
      } catch (error) {
        if (error.response && [400, 404, 409, 500].includes(error.response.status)) {
          toast.error("Error fetching doctor details");
        } else {
          toast.error("Error fetching doctor details");
        }
      }
    };
    fetchDoctorDetails();
  }, [selectedDoctorId]);

  const handleOpenModal = (index) => {
    setOpenModal(true);
    setOpenModalIndex(index);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <div style={{ height: 430, width: "100%" }}>
        {/* view Single doctor details */}
        {showLoading && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            <DNA
              visible={true}
              height={isMobile ? "120" : "200"}
              width={isMobile ? "200" : "250"}
              color="#2B2A29"
              ariaLabel="dna-loading"
              wrapperStyle={{}}
              wrapperClass="dna-wrapper"
            />
          </motion.div>
        )}

        {doctorData && (
          <>
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                color: "#333",
                fontWeight: "bold",
                marginRight: "8px",
              }}
            >
              Doctor Details
            </Typography>
            <Grid container spacing={4} alignItems="center" marginBottom="20px">
              {/* Full Name */}
              <Grid item xs={12} md={4}>
                <Paper
                  sx={{
                    borderRadius: 2,
                  }}
                >
                  <ListItem sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "1.4rem",
                        color: "#333",
                        fontWeight: "bold",
                        marginRight: "8px",
                      }}
                    >
                      Full Name:
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "1.4rem",
                        color: "#890F3C",
                      }}
                    >
                      {doctorData.fullName}
                    </Typography>
                  </ListItem>
                </Paper>
              </Grid>

              {/* Phone Number */}
              <Grid item xs={12} md={4}>
                <Paper
                  sx={{
                    borderRadius: 2,
                  }}
                >
                  <ListItem sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "1.4rem",
                        color: "#333",
                        fontWeight: "bold",
                        marginRight: "8px",
                      }}
                    >
                      Ph No:
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "1.4rem",
                        color: "#890F3C",
                      }}
                    >
                      {doctorData.phoneNumber}
                    </Typography>
                  </ListItem>
                </Paper>
              </Grid>

              {/* Email */}
              <Grid item xs={12} md={4}>
                <Paper
                  sx={{
                    borderRadius: 2,
                    minWidth: 200,
                  }}
                >
                  <ListItem sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "1.4rem",
                        color: "#333",
                        fontWeight: "bold",
                        marginRight: "8px",
                      }}
                    >
                      Email:
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "1.4rem",
                        color: "#890F3C",
                        wordBreak: "break-all",
                      }}
                    >
                      {doctorData.email}
                    </Typography>
                  </ListItem>
                </Paper>
              </Grid>
            </Grid>

            <Grid container spacing={4} alignItems="center" marginBottom="20px">
              {/* Address */}
              <Grid item xs={12} md={4}>
                <Paper
                  sx={{
                    borderRadius: 2,
                  }}
                >
                  <ListItem sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "1.4rem",
                        color: "#333",
                        fontWeight: "bold",
                        marginRight: "8px",
                      }}
                    >
                      Address:
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "1.4rem",
                        color: "#890F3C",
                      }}
                    >
                      {doctorData.practiceAddress}
                    </Typography>
                  </ListItem>
                </Paper>
              </Grid>

              {/* Gender */}
              <Grid item xs={12} md={4}>
                <Paper
                  sx={{
                    borderRadius: 2,
                  }}
                >
                  <ListItem sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "1.4rem",
                        color: "#333",
                        fontWeight: "bold",
                        marginRight: "8px",
                      }}
                    >
                      Gender:
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "1.4rem",
                        color: "#890F3C",
                      }}
                    >
                      {doctorData.gender}
                    </Typography>
                  </ListItem>
                </Paper>
              </Grid>

              {/* age */}
              <Grid item xs={12} md={4}>
                <Paper
                  sx={{
                    borderRadius: 2,
                  }}
                >
                  <ListItem sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "1.4rem",
                        color: "#333",
                        fontWeight: "bold",
                        marginRight: "8px",
                      }}
                    >
                      Age:
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "1.4rem",
                        color: "#890F3C",
                      }}
                    >
                      {doctorData.age}
                    </Typography>
                  </ListItem>
                </Paper>
              </Grid>
            </Grid>

            <TableContainer
              component={Paper}
              sx={{
                marginBottom: "20px",
                backgroundColor: "white",
                width: isMobile ? "80%" : "60%",
                marginLeft: "20%",
              }}
            >
              <Table aria-label="qualification table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        fontWeight: "bold",
                        fontSize: "20px",
                      }}
                    >
                      Degree
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: "bold",
                        fontSize: "20px",
                      }}
                    >
                      College
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: "bold",
                        fontSize: "20px",
                      }}
                    >
                      Batch
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: "bold",
                        fontSize: "20px",
                      }}
                    >
                      Certificate
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {doctorData.qualifications.map((qualification, index) => (
                    <TableRow key={index}>
                      <TableCell sx={{ fontSize: "16px", color: "black" }}>
                        <Typography
                          variant="body1"
                          sx={{
                            fontSize: "1.4rem",
                            color: "#890F3C",
                          }}
                        >
                          {" "}
                          {qualification.degreeName ? qualification.degreeName.name : ""}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ fontSize: "16px", color: "black" }}>
                        <Typography
                          variant="body1"
                          sx={{
                            fontSize: "1.4rem",
                            color: "#890F3C",
                          }}
                        >
                          {qualification.collegeName ? qualification.collegeName.name : ""}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ fontSize: "16px", color: "black" }}>
                        <Typography
                          variant="body1"
                          sx={{
                            fontSize: "1.4rem",
                            color: "#890F3C",
                          }}
                        >
                          {" "}
                          {qualification.batch}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="body1"
                          sx={{
                            fontSize: "1.4rem",
                            color: "#890F3C",
                          }}
                        >
                          {qualification.degreeCertificate ? (
                            <button
                              onClick={() => handleOpenModal(index)}
                              style={{
                                border: "none",
                                background: "transparent",
                                fontSize: "14px",
                                color: "blue",
                              }}
                            >
                              View Certificate
                            </button>
                          ) : (
                            "No"
                          )}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            {doctorData && (
              <Modal
                open={openModal && openModalIndex !== null}
                onClose={handleCloseModal}
                aria-labelledby="certificate-modal-title"
                aria-describedby="certificate-modal-description"
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ClickAwayListener onClickAway={handleCloseModal}>
                  <div
                    style={{
                      display: "flex",
                      // justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      maxHeight: "80vh",
                      overflowY: "auto",
                      padding: "20px",
                      backgroundColor: "#ffffff",
                      borderRadius: "8px",
                      boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.1)",
                      fontSize: "20px",
                    }}
                  >
                    <div style={{ textAlign: "center" }}>
                      <h2 id="certificate-modal-title">View Certificate</h2>
                      {/* {console.log(
                      "doctorData.degreeCertificate",
                      `https://7dstatic.s3.ap-south-1.amazonaws.com/trial/${doctorData?.qualifications[0]?.degreeCertificate}`,
                    )} */}
                      {doctorData.qualifications.map(
                        (qualification, index) =>
                          qualification.degreeCertificate && (
                            <div key={index}>
                              {qualification.degreeCertificate.endsWith(".pdf") ? (
                                <Document
                                  file="/images/calendar Document.pdf"
                                  onLoadSuccess={({ numPages }) => {
                                    setNumPages(numPages);
                                  }}
                                >
                                  {Array.from(new Array(numPages), (el, index) => (
                                    <Page key={`page_${index + 1}`} pageNumber={index + 1}>
                                      <div style={{ position: "absolute", bottom: 10, right: 10 }}>
                                        Page {index + 1} of {numPages}
                                      </div>
                                    </Page>
                                  ))}
                                </Document>
                              ) : (
                                <img
                                  src={`https://7dstatic.s3.ap-south-1.amazonaws.com/trial/${qualification.degreeCertificate}`}
                                  alt="Certificate"
                                  style={{ width: "100%", maxHeight: "700px" }}
                                />
                              )}
                            </div>
                          ),
                      )}
                      <button onClick={handleCloseModal} style={{ marginBottom: "10px" }}>
                        Close
                      </button>
                    </div>
                  </div>
                </ClickAwayListener>
              </Modal>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default DoctorDetails;

// file="/images/calendar Document.pdf" single page

// file="/images/All Docs and Patients.pdf" multiple page

// file={`https://7dstatic.s3.ap-south-1.amazonaws.com/trial/${qualification.degreeCertificate}`}

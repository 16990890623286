/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import {
  Typography,
  Button,
  TextField,
  Paper,
  Grid,
  Box,
  Tooltip,
  IconButton,
} from "@mui/material";
import Swal from "sweetalert2";
import CloseIcon from "@mui/icons-material/Close";

const AddQuestionsList = ({ state, dispatch, questionFileInputRef, answerFileInputRefs }) => {
  const updatePrompt = (value) => {
    dispatch({
      type: "set_questions",
      payload: { ...state.questions, prompt: value },
    });
  };

  const addAnswer = () => {
    const updatedQuestions = { ...state.questions };
    updatedQuestions.answers.push({
      answer: "",
      answerimage: "",
      results: [{ result: "", value: "" }],
    });
    dispatch({ type: "set_questions", payload: updatedQuestions });
  };

  const deleteAnswer = (aIndex) => {
    const updatedQuestions = { ...state.questions };
    updatedQuestions.answers.splice(aIndex, 1);
    dispatch({ type: "set_questions", payload: updatedQuestions });
  };

  const addResult = (aIndex) => {
    const updatedQuestions = { ...state.questions };
    updatedQuestions.answers[aIndex].results.push({
      result: "",
      value: "",
    });
    dispatch({ type: "set_questions", payload: updatedQuestions });
  };

  const deleteResult = (aIndex, rIndex) => {
    const updatedQuestions = { ...state.questions };
    updatedQuestions.answers[aIndex].results.splice(rIndex, 1);
    dispatch({ type: "set_questions", payload: updatedQuestions });
  };

  const updateQuestion = (field, value) => {
    dispatch({
      type: "set_questions",
      payload: { ...state.questions, [field]: value },
    });
  };

  const updateAnswer = (aIndex, field, value) => {
    const updatedQuestions = { ...state.questions };
    updatedQuestions.answers[aIndex][field] = value;
    dispatch({ type: "set_questions", payload: updatedQuestions });
  };

  const handleAnswerImageUpload = (aIndex, event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      if (file) {
        const updatedQuestions = { ...state.questions };
        updatedQuestions.answers[aIndex].answerimage = file; // Store the File object
        dispatch({ type: "set_questions", payload: updatedQuestions });
      }
    } else {
      console.error("No image selected");
    }
  };

  const handleClick = () => {
    if (questionFileInputRef.current) {
      questionFileInputRef.current.click();
    }
  };

  const handleQuestionImageUpload = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      if (file) {
        const updatedQuestions = { ...state.questions, questionimage: file }; // Store the image object
        dispatch({ type: "set_questions", payload: updatedQuestions });
      }
    } else {
      console.error("No image selected");
    }
  };

  const updateResult = (aIndex, rIndex, field, value) => {
    const updatedQuestions = { ...state.questions };
    updatedQuestions.answers[aIndex].results[rIndex][field] = value;
    dispatch({ type: "set_questions", payload: updatedQuestions });
  };

  const handleRemoveQuestionImage = () => {
    const updatedQuestions = { ...state.questions };
    updatedQuestions.questionimage = ""; // Empty question image data
    dispatch({ type: "set_questions", payload: updatedQuestions });
  };

  const handleCancelQuestionImage = () => {
    Swal.fire({
      title: "Confirm Deletion",
      text: "Are you sure you want to remove selected question image ???",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
      didOpen: () => {
        document.querySelector(".swal2-container").style.zIndex = "2000";
      },
    }).then((result) => {
      if (result.isConfirmed) {
        handleRemoveQuestionImage();
      } else {
        // example codes to clear up
      }
    });
  };

  const handleRemoveAnswerImage = (answerIndex) => {
    const updatedQuestions = { ...state.questions };
    updatedQuestions.answers[answerIndex].answerimage = ""; // Empty answer image data
    dispatch({ type: "set_questions", payload: updatedQuestions });
  };

  const handleCancelAnswerImage = (answerIndex) => {
    Swal.fire({
      title: "Confirm Deletion",
      text: "Are you sure you want to remove selected answer image ???",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
      didOpen: () => {
        document.querySelector(".swal2-container").style.zIndex = "2000";
      },
    }).then((result) => {
      if (result.isConfirmed) {
        handleRemoveAnswerImage(answerIndex);
      } else {
        // example codes to clear up
      }
    });
  };

  const handleChange = (aIndex, rIndex, e) => {
    let inputValue = e.target.value.replace(/\D/g, "").slice(0, 1); // Remove non-digit characters

    if (inputValue || inputValue === "") {
      updateResult(aIndex, rIndex, "value", inputValue);
    } else {
      return;
    }
  };

  return (
    <>
      {/* Prompt Input */}
      <TextField
        margin="dense"
        id="prompt"
        label="Prompt (Optional)"
        type="text"
        autoComplete="off"
        placeholder="Enter Ai Question Prompt Text"
        value={state.questions.prompt}
        onChange={(e) => updatePrompt(e.target.value)}
        fullWidth
        InputLabelProps={{
          style: { color: "black" }, // Style for label
        }}
        InputProps={{
          sx: {
            "&:focused": { borderColor: "black" }, // Border color when focused
          },
        }}
        sx={{
          mb: 1.2,
          my: 2,
          "& input:focus": {
            borderColor: "black !important",
          },
          "& .MuiOutlinedInput-root.Mui-focused": {
            "& fieldset": {
              borderColor: "black !important",
            },
          },
        }}
      />

      {/* Question Text Input */}
      <TextField
        margin="dense"
        id="question text"
        label="Question Text"
        type="text"
        required
        placeholder="Enter Question Text"
        autoComplete="off"
        value={state.questions.questiontext}
        onChange={(e) => updateQuestion("questiontext", e.target.value)}
        fullWidth
        InputLabelProps={{
          style: { color: "black" }, // Style for label
        }}
        InputProps={{
          sx: {
            "&:focused": { borderColor: "black" }, // Border color when focused
          },
        }}
        sx={{
          mb: 1,
          my: 2,
          "& input:focus": {
            borderColor: "black !important",
          },
          "& .MuiOutlinedInput-root.Mui-focused": {
            "& fieldset": {
              borderColor: "black !important",
            },
          },
        }}
      />

      {/* Question Image Upload Input */}
      <Paper elevation={12} sx={{ padding: "20px", my: 2 }}>
        <Grid container alignItems="center">
          <input
            ref={questionFileInputRef}
            type="file"
            accept="image/*"
            name="questionimage"
            style={{ display: "none" }}
            onChange={handleQuestionImageUpload}
          />
          <Button variant="contained" onClick={handleClick} sx={{ mr: 2 }}>
            Choose An Question Image (Optional)
          </Button>
          {state.questions.questionimage ? (
            <Typography variant="body1">{state.questions.questionimage.name}</Typography>
          ) : (
            <Typography
              variant="body1"
              sx={{
                color: "black",
              }}
            >
              No Question Image Choosen{" "}
            </Typography>
          )}
          <Box
            sx={{
              marginLeft: "auto",
              alignSelf: "center",
              cursor: "pointer",
            }}
          >
            <Tooltip title="Remove Selected Image" arrow>
              <IconButton
                edge="end"
                aria-label="add"
                onClick={handleCancelQuestionImage}
                sx={{ color: "black" }}
                size="large"
              >
                <CloseIcon fontSize="large" />
              </IconButton>
            </Tooltip>
          </Box>
        </Grid>
      </Paper>

      {state.questions.questionimage && (
        <Paper elevation={12} sx={{ padding: "20px", my: 2 }}>
          <Grid container direction="column" justifyContent="center" alignItems="flex-start">
            <Grid container justifyContent="center" alignItems="center" sx={{ mb: 2 }}>
              <Typography variant="h6" component="h2" justifyContent="center">
                Selected Question Image: {"  "}
                {state.questions.questionimage.name}
              </Typography>
            </Grid>
            <Grid container justifyContent="center" alignItems="center">
              <img
                src={
                  state.questions.questionimage &&
                  URL.createObjectURL(state.questions.questionimage)
                }
                alt="Uploaded File"
                style={{ maxWidth: "100%" }}
              />
            </Grid>
          </Grid>
        </Paper>
      )}

      {/* Answer Section */}
      {state.questions.answers.map((answer, aIndex) => (
        <div key={aIndex}>
          {/* Answer Input */}
          <TextField
            margin="dense"
            id="answer"
            label={`${aIndex + 1}. Answer`}
            type="text"
            placeholder="Enter Answer Text"
            autoComplete="off"
            required
            value={answer.answer}
            InputLabelProps={{
              style: {
                color: "black",
              },
            }}
            onChange={(e) => updateAnswer(aIndex, "answer", e.target.value)}
            fullWidth
            sx={{
              my: 2,
              mb: 1.2,
              "& input:focus": {
                borderColor: "black !important",
              },
              "& .MuiOutlinedInput-root.Mui-focused": {
                "& fieldset": {
                  borderColor: "black !important",
                },
              },
            }}
          />
          {/* Delete Answer Button */}
          <Button variant="outlined" onClick={() => deleteAnswer(aIndex)} sx={{ mt: 1, mb: 2 }}>
            Delete Answer Field
          </Button>

          <Paper elevation={12} sx={{ padding: "20px", mb: 2 }}>
            <Grid container alignItems="center">
              <Button
                variant="contained"
                onClick={() => answerFileInputRefs[aIndex].current.click()}
                sx={{ mr: 2 }}
              >
                Choose An Answer Image (Optional)
              </Button>

              <input
                ref={answerFileInputRefs[aIndex]}
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={(e) => handleAnswerImageUpload(aIndex, e)}
              />

              {answer.answerimage ? (
                <Typography variant="body1">{answer.answerimage.name}</Typography>
              ) : (
                <Typography
                  variant="body1"
                  sx={{
                    color: "black",
                  }}
                >
                  No Answer Image Choosen{" "}
                </Typography>
              )}
              <Box
                sx={{
                  marginLeft: "auto",
                  alignSelf: "center",
                  cursor: "pointer",
                }}
              >
                <Tooltip title="Remove Selected Image" arrow>
                  <IconButton
                    edge="end"
                    aria-label="add"
                    onClick={() => handleCancelAnswerImage(aIndex)}
                    sx={{ color: "black" }}
                    size="large"
                  >
                    <CloseIcon fontSize="large" />
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
          </Paper>

          {answer.answerimage && (
            <Paper elevation={12} sx={{ padding: "20px", my: 2 }}>
              <Grid container direction="column" justifyContent="center" alignItems="flex-start">
                <Grid container justifyContent="center" alignItems="center" sx={{ mb: 2 }}>
                  <Typography variant="h6" component="h2" justifyContent="center">
                    {aIndex + 1}. Answer Image: {"  "}
                    {answer.answerimage.name}
                  </Typography>
                </Grid>
                <Grid container justifyContent="center" alignItems="center">
                  <img
                    src={answer.answerimage && URL.createObjectURL(answer.answerimage)}
                    alt="Uploaded File"
                    style={{ maxWidth: "100%" }}
                  />
                </Grid>
              </Grid>
            </Paper>
          )}

          {/* Result Section */}
          {answer.results.map((result, rIndex) => (
            <div key={rIndex}>
              {/* Result Input */}
              <TextField
                margin="dense"
                id="answer result"
                label={`${rIndex + 1}. Answer Result`}
                type="text"
                autoComplete="off"
                placeholder="Enter Result Text (Vata or V)"
                InputLabelProps={{
                  style: {
                    color: "black",
                  },
                }}
                required
                value={result.result}
                onChange={(e) => updateResult(aIndex, rIndex, "result", e.target.value)}
                fullWidth
                sx={{
                  my: 2,
                  mb: 1.2,
                  "& input:focus": {
                    borderColor: "black !important",
                  },
                  "& .MuiOutlinedInput-root.Mui-focused": {
                    "& fieldset": {
                      borderColor: "black !important",
                    },
                  },
                }}
              />

              {/* Value Input */}
              <TextField
                margin="dense"
                id="answer value"
                label={`${rIndex + 1}. Answer Value`}
                placeholder="Enter Result Value text (0 or 1)"
                type="text"
                autoComplete="off"
                InputLabelProps={{
                  style: {
                    color: "black",
                  },
                }}
                required
                value={result.value}
                onChange={(e) => handleChange(aIndex, rIndex, e)}
                fullWidth
                sx={{
                  my: 1,
                  mb: 1.2,
                  "& input:focus": {
                    borderColor: "black !important",
                  },
                  "& .MuiOutlinedInput-root.Mui-focused": {
                    "& fieldset": {
                      borderColor: "black !important",
                    },
                  },
                }}
              />

              {/* Delete Result Button */}
              <Button
                variant="outlined"
                onClick={() => deleteResult(aIndex, rIndex)}
                sx={{ mt: 1, mb: 2 }}
              >
                Delete Result Field
              </Button>
            </div>
          ))}

          {/* Add Result Button */}
          <Button variant="outlined" onClick={() => addResult(aIndex)} sx={{ mb: 1 }}>
            Add Result Field
          </Button>
        </div>
      ))}

      {/* Add Answer Button */}
      <Button variant="outlined" onClick={() => addAnswer()} sx={{ mt: 2 }}>
        Add Answer Field
      </Button>
    </>
  );
};

export default AddQuestionsList;

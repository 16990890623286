import { Typography } from "@mui/material";
import React from "react";

const Visits = () => {
  return (
    <>
      <Typography
        variant="h4"
        style={{ height: 430, width: "100%", color: "#333", fontWeight: "bold" }}
      >
        Visits
      </Typography>
    </>
  );
};

export default Visits;

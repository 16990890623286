/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import axios from "axios";

const MedicineType = ({ selectedType, setSelectedType, medicineTypes, setMedicineTypes }) => {
  useEffect(() => {
    const fetchMedicineTypes = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/medicine-type/get/all`,
        );

        if (response && response.status === 200) {
          if (response.data && response.data.data) {
            setMedicineTypes(response.data.data);
          } else {
            setMedicineTypes([]);
            console.error("Error fetching medicine types");
          }
        } else {
          setMedicineTypes([]);
          console.error("Error fetching medicine types");
        }
      } catch (error) {
        setMedicineTypes([]);
        console.error("Error fetching medicine types:", error);
      }
    };

    fetchMedicineTypes();
  }, []);

  const handleTypeChange = (event) => {
    const selectedType = medicineTypes.find((type) => type.medicineTypeName === event.target.value);

    setSelectedType(selectedType || {});
  };

  return (
    <FormControl fullWidth>
      <InputLabel
        id="medicine type"
        sx={{
          color: "white",
          "&.Mui-focused": {
            color: "white",
          },
        }}
      >
        Medicine Type
      </InputLabel>
      <Select
        labelId="medicine type"
        label="Medicine Type"
        value={selectedType.medicineTypeName || ""}
        onChange={handleTypeChange}
        sx={{
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "white",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "white",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "white",
          },
          color: "white",
          "& .MuiSvgIcon-root": {
            color: "white",
          },
        }}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {medicineTypes &&
          medicineTypes.map((type) => (
            <MenuItem key={type._id} value={type.medicineTypeName}>
              {type.medicineTypeName}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default MedicineType;

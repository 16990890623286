/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { TextField, Button, Grid, Typography } from "@mui/material";
import SymptomsList from "./SymptomsList";
import { toast } from "react-toastify";
import axios from "axios";
import Swal from "sweetalert2";
import { DNA } from "react-loader-spinner";
import { motion } from "framer-motion";

const Symptoms = ({ isMobile }) => {
  const [symptoms, setSymptoms] = useState([]);
  const [formData, setFormData] = useState({
    symptomName: "",
    symptomCode: "",
    symptomName_EN: "",
    symptomName_SN: "",
  });
  const [symptomFlag, setSymptomFlag] = useState(false);

  const [selectedId, setSelectedId] = useState(null);
  const [action, setAction] = useState("add");
  const [loading, setLoading] = useState(true);

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    const fetchSymptoms = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/symptom/get/all`);
        setSymptoms(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching symptoms:", error);
        setLoading(false);
      }
    };

    fetchSymptoms();
  }, [symptomFlag]);

  const handleAdd = async () => {
    if (action === "add") {
      if (
        !formData.symptomName &&
        !formData.symptomCode &&
        !formData.symptomName_EN &&
        !formData.symptomName_SN
      ) {
        toast.error("Please enter a symptoms first");
        return;
      }

      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/symptom/post/new`, {
          symptomDetail: formData,
        });

        if (response && response.status === 201) {
          toast.success("Symptoms Added Successfully");
          setFormData({
            symptomName: "",
            symptomCode: "",
            symptomName_EN: "",
            symptomName_SN: "",
          });
          setSymptomFlag(!symptomFlag);
        }
      } catch (error) {
        console.error("Error adding symptom :", error);
        toast.error("Error adding symptom ");
      }
    } else if (action === "edit") {
      if (!formData) {
        toast.error("Please fill symptom  first");
        return;
      }

      const existingSymptom = symptoms.find((item) => item._id === selectedId);
      const formChanged = Object.keys(formData).some(
        (key) => formData[key] !== existingSymptom[key],
      );

      // Check if the text field value has changed
      if (!formChanged) {
        toast.error("No changes detected.");
        return;
      }

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/symptom/post/selected/${selectedId}`,
          {
            symptomDetail: {
              symptomName: formData.symptomName,
              symptomCode: formData.symptomCode,
              symptomName_EN: formData.symptomName_EN,
              symptomName_SN: formData.symptomName_SN,
            },
          },
        );

        if (response && response.status === 200) {
          toast.success("Symptom Edited Successfully");
          setFormData({
            symptomName: "",
            symptomCode: "",
            symptomName_EN: "",
            symptomName_SN: "",
          });
          setSelectedId("");
          setAction("add");
          setSymptomFlag(!symptomFlag);
        }
      } catch (error) {
        console.error("Error adding symptom :", error);
        toast.error("Error adding symptom ");
      }
    }
  };

  const handleEdit = (item) => {
    if (item) {
      setSelectedId(item._id);
      setAction("edit");
      setFormData({
        symptomName: item.symptomName,
        symptomCode: item.symptomCode,
        symptomName_EN: item.symptomName_EN,
        symptomName_SN: item.symptomName_SN,
      });
    }
  };

  const handleDelete = async (item) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/symptom/delete/selected/${item}`,
      );

      if (response && response.status === 200) {
        toast.success("Symptom Deleted Successfully");
        setSymptomFlag(!symptomFlag);
      }
    } catch (error) {
      console.error("Error deleting symptom:", error);
      toast.error("Error deleting symptom");
    }
  };

  const handleOpenDeleteConfirmation = (item) => {
    setFormData("");
    setSelectedId("");
    setAction("add");

    Swal.fire({
      title: "Confirm Deletion",
      text: "Are you sure you want to delete this symptom?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(item);
      }
    });
  };

  const handleCancel = () => {
    setFormData("");
    setSelectedId("");
    setAction("add");
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        alignItems="center"
        marginTop={1}
        marginBottom={1}
        style={{
          position: "sticky",
          top: 0,
          background: "#A52D59",
          zIndex: 1,
          padding: "5px",
        }}
      >
        <Grid item xs={12} md={3} lg={3}>
          <TextField
            margin="dense"
            id="symptom name"
            type="text"
            required
            label="Symtom Name"
            value={formData?.symptomName || ""}
            onChange={handleInputChange}
            placeholder="Symtom Name"
            fullWidth
            InputLabelProps={{ style: { color: "white" } }}
            autoComplete="off"
            sx={{
              "& input": {
                color: "white",
              },
              "& .MuiOutlinedInput-root.Mui-focused": {
                borderColor: "white",
                "& fieldset": {
                  borderColor: "white",
                },
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "white",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "white",
              },
            }}
          />
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <TextField
            margin="dense"
            id="symptom code"
            type="text"
            required
            label="Symptom Code"
            value={formData?.symptomCode || ""}
            onChange={handleInputChange}
            placeholder="Symptom Code"
            fullWidth
            InputLabelProps={{ style: { color: "white" } }}
            autoComplete="off"
            sx={{
              "& input": {
                color: "white",
              },
              "& .MuiOutlinedInput-root.Mui-focused": {
                borderColor: "white",
                "& fieldset": {
                  borderColor: "white",
                },
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "white",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "white",
              },
            }}
          />
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <TextField
            margin="dense"
            id="symptom name(e)"
            type="text"
            required
            label="Symptom Name(E)"
            value={formData?.symptomName_EN || ""}
            onChange={handleInputChange}
            placeholder="English Name"
            fullWidth
            InputLabelProps={{ style: { color: "white" } }}
            autoComplete="off"
            sx={{
              "& input": {
                color: "white",
              },
              "& .MuiOutlinedInput-root.Mui-focused": {
                borderColor: "white",
                "& fieldset": {
                  borderColor: "white",
                },
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "white",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "white",
              },
            }}
          />
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <TextField
            margin="dense"
            id="symptom name(s)"
            type="text"
            required
            label="Symptom Name(S)"
            value={formData?.symptomName_SN || ""}
            onChange={handleInputChange}
            placeholder="Sanskrit Name"
            fullWidth
            InputLabelProps={{ style: { color: "white" } }}
            autoComplete="off"
            sx={{
              "& input": {
                color: "white",
              },
              "& .MuiOutlinedInput-root.Mui-focused": {
                borderColor: "white",
                "& fieldset": {
                  borderColor: "white",
                },
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "white",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "white",
              },
            }}
          />
        </Grid>
        <Grid item sx={{ ml: "auto" }}>
          <Button
            variant="contained"
            onClick={handleAdd}
            sx={{
              ml: 2,
              backgroundColor: "white",
              color: "darkblue",
              ":hover": {
                backgroundColor: "#e0e0e0",
                color: "darkblue",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              },
            }}
          >
            {action === "add" ? "Add" : "Update"}
          </Button>
          <Button variant="contained" color="error" onClick={handleCancel} sx={{ ml: 2 }}>
            Cancel
          </Button>
        </Grid>
      </Grid>
      {loading ? (
        <motion.div>
          <DNA
            visible={true}
            height={isMobile ? "120" : "200"}
            width={isMobile ? "200" : "250"}
            color="#2B2A29"
            ariaLabel="dna-loading"
            wrapperStyle={{}}
            wrapperClass="dna-wrapper"
          />
        </motion.div>
      ) : (
        <>
          {symptoms && symptoms.length === 0 ? ( // Conditionally render no data message
            <div style={{ textAlign: "center", marginTop: "10%" }}>
              <Typography variant="body1" fontSize="1.8rem">
                No Symptoms Data Found
              </Typography>
            </div>
          ) : (
            <SymptomsList
              symptoms={symptoms}
              onEdit={handleEdit}
              onDelete={handleOpenDeleteConfirmation}
              onAdd={handleAdd}
            />
          )}
        </>
      )}
    </>
  );
};

export default Symptoms;

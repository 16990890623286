import React, { useState, useEffect } from "react";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import VisibilityIcon from "@mui/icons-material/Visibility";
import axios from "axios";
import Swal from "sweetalert2";

const QualificationTable = ({
  qualifications,
  handleQualificationChange,
  handleAddDegree,
  handleCancelDegree,
  isMobile,
  selectedDegreeImages,
  setSelectedDegreeImages,
  fileInputRef,
}) => {
  const [degree, setDegree] = useState([]);
  const [selectedDegrees, setSelectedDegrees] = useState({});
  const [college, setCollege] = useState([]);
  const [selectedColleges, setSelectedColleges] = useState({});
  const [selectedFileName, setSelectedFileName] = useState(""); //
  const [selectedFilePreview, setSelectedFilePreview] = useState(null);

  // const fileInputEl = useRef(null);

  useEffect(() => {
    const initialSelectedDegrees = {};
    qualifications.forEach((qualification, index) => {
      initialSelectedDegrees[index] = qualification.degreeName ? qualification.degreeName.id : "";
    });
    setSelectedDegrees(initialSelectedDegrees);

    const fetchDegreeData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/degree/get/all`);
        setDegree(response.data.data);
      } catch (error) {
        console.error("Error fetching educational degree:", error);
      }
    };
    fetchDegreeData();
  }, [qualifications]);

  const handleDegreeChange = (index, value) => {
    setSelectedDegrees((prevState) => ({
      ...prevState,
      [index]: value,
    }));
    handleQualificationChange(index, "degreeName", value);
  };

  useEffect(() => {
    const initialSelectedColleges = {};
    qualifications.forEach((qualification, index) => {
      initialSelectedColleges[index] = qualification.collegeName
        ? qualification.collegeName.id
        : "";
    });
    setSelectedColleges(initialSelectedColleges);

    const fetchCollegeData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/college/get/all`);
        setCollege(response.data.data);
        // console.log("college", response);
      } catch (error) {
        console.error("Error fetching educational college:", error);
      }
    };
    fetchCollegeData();
  }, [qualifications]);

  const handleCollegeChange = (index, value) => {
    setSelectedColleges((prevState) => ({
      ...prevState,
      [index]: value,
    }));
    handleQualificationChange(index, "collegeName", value);
  };

  const handleChooseFileClick = (index) => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileInputChange = (index, e) => {
    const file = e.target.files && e.target.files[0];

    console.log("file", file);
    if (file) {
      const newSelectedDegreeImages = [...selectedDegreeImages];
      newSelectedDegreeImages[index] = file; // Update the selected image for the specific degree
      setSelectedDegreeImages(newSelectedDegreeImages);
      handleQualificationChange(index, "degreeCertificate", file, e);
      setSelectedFileName(file.name); // Update selected file name

      setSelectedFilePreview(file ? URL.createObjectURL(file) : null);
    }
  };

  const handleRemoveFile = () => {
    setSelectedFileName(""); // Reset the selected file name
    fileInputRef.current.value = null; // Clear the file input
  };

  const handleCancel = () => {
    Swal.fire({
      title: "Confirm Deletion",
      text: "Are you sure you want to remove the selected file?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
      didOpen: () => {
        document.querySelector(".swal2-container").style.zIndex = "2000";
      },
    }).then((result) => {
      if (result.isConfirmed) {
        handleRemoveFile(); // Call the function to remove the file
      } else {
        // Handle cancellation if needed
      }
    });
  };

  return (
    <>
      <TableContainer sx={{ marginTop: isMobile ? 2 : 4, overflowX: isMobile ? "auto" : "auto" }}>
        <Table style={{ border: "1px solid black" }}>
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  border: "1px solid black",
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                Degree
              </TableCell>
              <TableCell
                style={{
                  border: "1px solid black",
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                College
              </TableCell>
              <TableCell
                style={{
                  border: "1px solid black",
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                Batch
              </TableCell>
              <TableCell
                style={{
                  border: "1px solid black",
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                Certificates
              </TableCell>
              <TableCell
                style={{
                  border: "1px solid black",
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {qualifications &&
              qualifications.map((qualification, index) => (
                <TableRow key={index}>
                  <TableCell
                    style={{ border: "1px solid black", width: isMobile ? "100%" : "30%" }}
                  >
                    <FormControl sx={{ width: "90%" }}>
                      <InputLabel
                        id="degree"
                        sx={{
                          wordBreak: "break-all",
                          fontWeight: "bold",
                          color: "black",
                          "&.Mui-focused": { color: "black" },
                        }}
                      >
                        Degree
                      </InputLabel>
                      <Select
                        labelId="degree"
                        label="Degree"
                        value={selectedDegrees[index] || ""}
                        onChange={(e) => handleDegreeChange(index, e.target.value)}
                        sx={{
                          width: "100%",
                          "&.Mui-focused": { color: "black" },
                          "& .MuiOutlinedInput-notchedOutline": { borderColor: "black" },
                          "&:hover .MuiOutlinedInput-notchedOutline": { borderColor: "black" },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "black",
                          },
                          color: "black",
                          "& .MuiSvgIcon-root": { color: "black" },
                        }}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {degree &&
                          degree.map((degree) => (
                            <MenuItem key={degree._id} value={degree._id}>
                              {degree.degreeName}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </TableCell>

                  <TableCell style={{ border: "1px solid black", width: isMobile ? "80%" : "20%" }}>
                    <FormControl sx={{ width: "90%" }}>
                      <InputLabel
                        id="college"
                        sx={{
                          wordBreak: "break-all",
                          fontWeight: "bold",
                          color: "black",
                          "&.Mui-focused": { color: "black" },
                        }}
                      >
                        College
                      </InputLabel>
                      <Select
                        labelId="college"
                        label="College"
                        value={selectedColleges[index] || ""}
                        onChange={(e) => handleCollegeChange(index, e.target.value)}
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": { borderColor: "black" },
                          "&:hover .MuiOutlinedInput-notchedOutline": { borderColor: "black" },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "black",
                          },
                          color: "black",
                          "& .MuiSvgIcon-root": { color: "black" },
                        }}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {college &&
                          college.map((college) => (
                            <MenuItem key={college._id} value={college._id}>
                              {college.collegeName}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </TableCell>

                  <TableCell
                    style={{ border: "1px solid black", width: isMobile ? "100%" : "20%" }}
                  >
                    <TextField
                      placeholder="Enter Batch"
                      label="Batch"
                      value={qualification.batch}
                      onChange={(e) => handleQualificationChange(index, "batch", e.target.value)}
                      fullWidth
                      // margin="normal"
                      InputLabelProps={{
                        style: { color: "black", fontWeight: "bold" },
                      }}
                      autoComplete="off"
                      sx={{
                        "& input": { color: "black", fontWeight: "bold" },
                        "& .MuiOutlinedInput-root.Mui-focused": {
                          borderColor: "black",
                          "& fieldset": { borderColor: "black" },
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": { borderColor: "black" },
                        "& .MuiOutlinedInput-notchedOutline": { borderColor: "black" },
                      }}
                    />
                  </TableCell>

                  <TableCell
                    style={{ border: "1px solid black", width: isMobile ? "100%" : "20%" }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Button variant="outlined" onClick={handleChooseFileClick}>
                        Choose File
                      </Button>
                      {selectedFileName ? (
                        <>
                          <Typography variant="body1" sx={{ marginLeft: "0.5rem" }}>
                            {selectedFileName}
                          </Typography>
                          {selectedFilePreview && (
                            <IconButton
                              edge="end"
                              aria-label="view"
                              onClick={() => window.open(selectedFilePreview)}
                              sx={{ color: "black" }}
                              size="large"
                            >
                              <VisibilityIcon fontSize="medium " />
                            </IconButton>
                          )}
                          <IconButton
                            edge="end"
                            aria-label="cancel"
                            onClick={handleCancel}
                            sx={{ color: "black", marginTop: "0.5rem" }}
                            size="large"
                          >
                            <CloseIcon fontSize="medium" />
                          </IconButton>
                        </>
                      ) : (
                        <Typography variant="body1" sx={{ marginLeft: "0.5rem" }}>
                          No File Chosen
                        </Typography>
                      )}
                    </div>

                    <input
                      ref={fileInputRef}
                      type="file"
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={(e) => handleFileInputChange(index, e)}
                    />
                  </TableCell>

                  <TableCell
                    style={{ border: "1px solid black", width: isMobile ? "100%" : "20%" }}
                  >
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={handleCancelDegree}
                      startIcon={<DeleteIcon />}
                    >
                      Cancel
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{ display: "flex", justifyContent: "flex-end", marginRight: "9rem" }}>
        <Button
          variant="outlined"
          onClick={handleAddDegree}
          startIcon={<AddIcon />}
          sx={{
            mt: 2,
            mb: 2,
            ml: 3,
            textAlign: "right",
            color: "primary",
          }}
        >
          Add
        </Button>
      </div>
    </>
  );
};

export default QualificationTable;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import {
  List,
  ListItem,
  ListItemText,
  TextField,
  Button,
  Typography,
  IconButton,
  Stack,
  Tooltip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import { DNA } from "react-loader-spinner";
import { motion } from "framer-motion";

const EducationalDegreeList = ({ isMobile }) => {
  const [degree, setDegree] = useState([]);
  const [textFieldValue, setTextFieldValue] = useState("");
  const [selectedId, setSelectedId] = useState(null);
  const [action, setAction] = useState("add");
  const [loadingDegree, setLoadingDegree] = useState(true);

  const fetchData = async () => {
    try {
      setLoadingDegree(true);
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/degree/get/all`);
      if (response && response.status === 200) {
        if (response.data && response.data.data) {
          setDegree(response.data.data);
        } else {
          setDegree([]);
          console.error("Error fetching educational degree");
        }
      } else {
        setDegree([]);
        console.error("Error fetching educational degree");
      }
      setLoadingDegree(false);
    } catch (error) {
      setDegree([]);
      console.error("Error fetching educational degree:", error);
      setLoadingDegree(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleCancel = () => {
    setTextFieldValue("");
    setSelectedId("");
    setAction("add");
  };

  const handleTextFieldChange = (event) => {
    setTextFieldValue(event.target.value);
  };

  const handleButtonClick = async () => {
    if (action === "add") {
      if (!textFieldValue) {
        toast.error("Please enter an educational degree first");
        return;
      }

      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/degree/post/new`, {
          degreeName: textFieldValue,
        });

        if (response && response.status === 201) {
          toast.success("Educational Degree Added Successfully");
          setTextFieldValue("");
          fetchData();
        }
      } catch (error) {
        if (error.response && error.response.status === 409) {
          console.error("Error adding educational degree:", error.response.data.message);
          toast.error("Entered Educational Degree Already Exists");
        } else {
          console.error("Error adding educational degree:", error);
          toast.error("Error adding educational degree");
        }
      }
    } else if (action === "edit") {
      if (!textFieldValue) {
        toast.error("Please fill educational degree first");
        return;
      }

      if (textFieldValue === degree.find((item) => item._id === selectedId)?.degreeName) {
        toast.error("No changes detected.");
        return;
      }

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/degree/post/selected/${selectedId}`,
          {
            degreeName: textFieldValue,
          },
        );

        if (response && response.status === 200) {
          toast.success("Educational Degree Updated Successfully");
          handleCancel();
          fetchData();
        }
      } catch (error) {
        if (error.response && error.response.status === 409) {
          console.error("Error updating educational degree:", error.response.data.message);
          toast.error("Entered Educational Degree Already Exists");
        } else {
          console.error("Error updating educational degree:", error);
          toast.error("Error updating educational degree");
        }
      }
    }
  };

  const handleEdit = (item) => {
    if (item) {
      setSelectedId(item._id);
      setTextFieldValue(item.degreeName);
      setAction("edit");
    }
  };

  const handleDelete = (item) => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}/api/degree/delete/selected/${item._id}`)
      .then((response) => {
        if (response.status === 200) {
          toast.success("Educational Degree Deleted Successfully");
          fetchData();
        }
      })
      .catch((error) => {
        console.error("Error deleting educational degree:", error);
      });
  };

  const handleDeleteConfirmation = (item) => {
    handleCancel();

    Swal.fire({
      title: "Confirm Deletion",
      text: "Are you sure you want to delete this educational degree?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(item);
      } else {
        // Do nothing
      }
    });
  };

  return (
    <>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={2}
        alignItems="center"
        justifyContent="center"
        style={{
          position: "sticky",
          top: 0,
          zIndex: 5,
          background: "#A52D59",
          padding: "12px",
        }}
      >
        <TextField
          margin="dense"
          id="educational degree"
          type="text"
          label="Educational Degree"
          value={textFieldValue}
          required
          onChange={handleTextFieldChange}
          placeholder="Enter Educational Degree"
          InputLabelProps={{ style: { color: "white" } }}
          autoComplete="off"
          sx={{
            "& input": {
              color: "white",
            },
            "& .MuiOutlinedInput-root.Mui-focused": {
              borderColor: "white",
              "& fieldset": {
                borderColor: "white",
              },
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "white",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "white",
            },
          }}
          fullWidth
        />
        <Stack direction="row">
          <Button
            variant="contained"
            onClick={handleButtonClick}
            sx={{
              ml: 2,
              backgroundColor: "white",
              color: "darkblue",
              ":hover": {
                backgroundColor: "#e0e0e0",
                color: "darkblue",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              },
            }}
            size="large"
          >
            {action === "add" ? "Add" : "Update"}
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={handleCancel}
            sx={{ ml: 2 }}
            size="large"
          >
            Cancel
          </Button>
        </Stack>
      </Stack>
      {loadingDegree ? (
        <motion.div>
          <DNA
            visible={true}
            height={isMobile ? "120" : "200"}
            width={isMobile ? "200" : "250"}
            color="#2B2A29"
            ariaLabel="dna-loading"
            wrapperStyle={{}}
            wrapperClass="dna-wrapper"
          />
        </motion.div>
      ) : (
        <List>
          {degree &&
            degree.map((item, index) => (
              <ListItem
                component="div"
                key={index}
                sx={{
                  backgroundColor: "#C47F9F",
                  borderRadius: 5,
                  "&:hover": {
                    backgroundColor: "#B54A7C",
                    cursor: "pointer",
                  },
                  userSelect: "none",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingRight: "10px",
                  padding: "10px",
                  marginBottom: "5px",
                }}
              >
                <ListItemText
                  primary={
                    <Typography
                      sx={{
                        ml: 2,
                        fontSize: isMobile ? "1rem" : "1.3rem",
                        color: "white",
                      }}
                    >
                      {item.degreeName}
                    </Typography>
                  }
                />
                <div>
                  <Tooltip title="Edit Educational Degree" arrow>
                    <IconButton onClick={() => handleEdit(item)}>
                      <EditIcon sx={{ color: "white" }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete Educational Degree" arrow>
                    <IconButton onClick={() => handleDeleteConfirmation(item)}>
                      <DeleteIcon sx={{ color: "white" }} />
                    </IconButton>
                  </Tooltip>
                </div>
              </ListItem>
            ))}
          {degree && degree.length === 0 && (
            <ListItem
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "60vh",
                textAlign: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "1.6rem",
                  textAlign: "center",
                }}
              >
                No Educational Degeree Data Found
              </Typography>
            </ListItem>
          )}
        </List>
      )}
    </>
  );
};

export default EducationalDegreeList;

import React, { useState, useEffect } from "react";
import { List, ListItem, ListItemText, Paper, Typography } from "@mui/material";
import axios from "axios";
import { DNA } from "react-loader-spinner";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const TopicList = ({ state, dispatch, isMobile }) => {
  const [topics, setTopics] = useState([]);
  const [showLoading, setShowLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/qna/get/all/topics`);

        if (response && response.status === 200 && response.data && response.data.data) {
          setTopics(response.data.data);
          setShowLoading(false);
        } else {
          setTopics([]);
          toast.error("No Topics Found, Please Try after sometime");
        }
      } catch (error) {
        console.error("No Topics found, Please try after sometime", error);

        if (error.response && [400, 404, 409, 500].includes(error.response.status)) {
          switch (error.response.status) {
            case 400:
              toast.error("Bad request. Please check your inputs");
              break;
            case 404:
              toast.error("Topic not found");
              break;
            case 409:
              toast.error("There is a conflict with the current state of the topic");
              break;
            case 500:
              toast.error("Server error while fetching topic, Please Try after sometime");
              break;
            default:
              toast.error("An error occurred while fetching topic, Please Try after sometime");
          }
        } else {
          toast.error("No Topics Found, Please Contact Developer");
        }
        setTopics([]);
      }
    };

    fetchData();
  }, [state.topicRefreshFlag, dispatch]);

  const handleItemClick = (item) => {
    dispatch({ type: "set_selected_topic_item", payload: item });
  };

  return (
    <>
      {showLoading ? (
        <motion.div>
          <DNA
            visible={true}
            height={isMobile ? "120" : "200"}
            width={isMobile ? "200" : "250"}
            color="#2B2A29"
            ariaLabel="dna-loading"
            wrapperStyle={{}}
            wrapperClass="dna-wrapper"
          />
        </motion.div>
      ) : (
        <List>
          {topics && topics.length === 0 ? (
            <Typography fontSize="2.2rem" align="center" mt={2}>
              No Topics found.
            </Typography>
          ) : (
            topics &&
            topics.map((item, index) => (
              <Paper
                key={item._id}
                elevation={6}
                sx={{
                  borderRadius: "20px",
                  mb: 1,
                  mx: 1,
                  overflow: "hidden",
                  backgroundColor: "rgba(255, 255, 255, 0.9)",
                }}
              >
                <ListItem
                  component="div"
                  key={index}
                  onClick={() => handleItemClick(item)}
                  sx={{
                    fontSize: isMobile ? "1.2rem" : "1.5rem",
                    backgroundColor:
                      state.selectedTopicItem && state.selectedTopicItem._id === item._id
                        ? "#890F3C"
                        : "white",
                    "&:hover": {
                      backgroundColor: "#E22C6F",
                      cursor: "pointer",
                    },
                    userSelect: "none",
                  }}
                >
                  <ListItemText
                    primary={
                      <Typography
                        sx={{
                          ml: 2,
                          fontSize: isMobile ? "1rem" : "1.3rem",
                          color:
                            state.selectedTopicItem && state.selectedTopicItem._id === item._id
                              ? "white"
                              : "black",
                        }}
                      >
                        {item.topicHeading}
                      </Typography>
                    }
                  />
                </ListItem>
              </Paper>
            ))
          )}
        </List>
      )}
    </>
  );
};

export default TopicList;

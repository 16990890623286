/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import LookUpList from "./LookUpList/LookUpList";
import LookUpDetails from "./LookUpDetails/LookUpDetails";
import { useScroll } from "../../../ScrollContext";
import { Paper, Stack } from "@mui/material";

const DemoPaper = styled(Paper)(({ theme, height, width }) => ({
  width: width,
  height: height,
  padding: theme.spacing(2),
  ...theme.typography.body2,
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  backgroundColor: "rgba(255, 255, 255, 0.7)",
  position: "relative",
  "& > div": {
    maxHeight: "100%",
    overflowY: "auto",
    paddingRight: theme.spacing(1),
  },
}));

const LookUp = ({ isMobile }) => {
  const [modelType, setModelType] = useState("Educational Degree");
  const { scrollToTop } = useScroll();

  useEffect(() => {
    scrollToTop();
  }, []);

  const containerStyle = {
    marginLeft: isMobile ? "5%" : "22%",
    marginBottom: isMobile ? "15%" : "3%",
    marginRight: "5%",
    marginTop: isMobile ? "25%" : "8%",
    transition: "margin 0.5s",
    zIndex: 3,
  };

  return (
    <div style={containerStyle}>
      <Stack direction="row" spacing={4} alignItems="center">
        {/* Paper 1 */}
        <DemoPaper
          height={isMobile ? "120vh" : "100vh"}
          width={isMobile ? "60%" : "30%"}
          square={false}
          elevation={24}
        >
          <PerfectScrollbar options={{ wheelPropagation: false }}>
            <LookUpList modelType={modelType} setModelType={setModelType} isMobile={isMobile} />
          </PerfectScrollbar>
        </DemoPaper>

        {/* Paper 2 */}
        <DemoPaper height={isMobile ? "120vh" : "100vh"} width="100%" square={false} elevation={24}>
          <PerfectScrollbar options={{ wheelPropagation: false }}>
            <LookUpDetails modelType={modelType} isMobile={isMobile} />
          </PerfectScrollbar>
        </DemoPaper>
      </Stack>
    </div>
  );
};

export default LookUp;

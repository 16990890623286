import React from "react";
import { List, ListItem, ListItemText, Typography } from "@mui/material";

const listData = [
  { id: 1, field: "Educational Degree" },
  { id: 2, field: "Educational College" },
  { id: 3, field: "Medicine Type" },
  { id: 4, field: "Medicine Delivery" },
  { id: 5, field: "Medicines" },
  { id: 6, field: "Pharma Companies" },
  { id: 7, field: "Symptoms" },
  { id: 8, field: "Diagnostic Disease" },
];

const LookUpList = ({ modelType, setModelType, isMobile }) => {
  return (
    <List>
      {listData &&
        listData.map((item, index) => (
          <ListItem
            component="div"
            key={index}
            onClick={() => setModelType(item.field)}
            sx={{
              backgroundColor: modelType === item.field ? "#890F3C" : "white",
              borderRadius: 5,
              "&:hover": {
                backgroundColor: "#E22C6F",
                cursor: "pointer",
              },
              userSelect: "none",
              mb: 1,
            }}
          >
            <ListItemText
              primary={
                <Typography
                  sx={{
                    ml: 2,
                    fontSize: isMobile ? "1rem" : "1.2rem",
                    color: modelType === item.field ? "white" : "black",
                  }}
                >
                  {item.field}
                </Typography>
              }
            />
          </ListItem>
        ))}
    </List>
  );
};

export default LookUpList;

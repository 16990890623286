/* eslint-disable react-hooks/exhaustive-deps */
import React, { useReducer, useRef, useEffect } from "react";
import Stack from "@mui/material/Stack";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import TopicTools from "./TopicTools";
import AddEditTopicModal from "./AddEditTopicModal";
import TopicList from "./TopicList";
import { useScroll } from "../../../ScrollContext";
import { DemoPaper } from "../DemoPaper";

const initialState = {
  topicList: [],
  topicModal: false,
  topicAction: "add",
  selectedTopicItem: null,
  topicRefreshFlag: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "set_topic_list":
      return { ...state, topicList: action.payload };
    case "set_topic_modal":
      return { ...state, topicModal: action.payload };
    case "set_topic_action":
      return { ...state, topicAction: action.payload };
    case "set_selected_topic_item":
      return { ...state, selectedTopicItem: action.payload };
    case "set_topic_refresh_flag":
      return { ...state, topicRefreshFlag: action.payload };
    default:
      return state;
  }
};

const Topic = ({ isMobile }) => {
  const fileInputRef = useRef(null);
  const [state, dispatch] = useReducer(reducer, initialState);
  const { scrollToTop } = useScroll();

  useEffect(() => {
    scrollToTop();
  }, []);

  const containerStyle = {
    marginLeft: isMobile ? "5%" : "25%",
    marginBottom: isMobile ? "15%" : "3%",
    marginRight: "5%",
    marginTop: isMobile ? "25%" : "8%",
    transition: "margin 0.5s",
    zIndex: 3,
  };

  return (
    <div style={containerStyle}>
      <Stack direction="column" spacing={2} alignItems="center">
        <DemoPaper
          height={isMobile ? "120vh" : "90vh"}
          square={false}
          elevation={24}
          isMobile={isMobile}
        >
          <TopicTools state={state} dispatch={dispatch} isMobile={isMobile} />
          <PerfectScrollbar options={{ wheelPropagation: false }}>
            <TopicList state={state} dispatch={dispatch} />
          </PerfectScrollbar>
        </DemoPaper>
      </Stack>
      <AddEditTopicModal
        state={state}
        dispatch={dispatch}
        isMobile={isMobile}
        fileInputRef={fileInputRef}
      />
    </div>
  );
};

export default Topic;

import { styled, Paper } from "@mui/material";

export const DemoPaper = styled(Paper)(({ theme, height, isMobile }) => ({
  width: isMobile ? "80%" : "100%",
  height: height || "50vh",
  padding: theme.spacing(2),
  ...theme.typography.body2,
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  backgroundColor: "rgba(255, 255, 255, 0.7)",
  position: "relative",
  "& > div": {
    maxHeight: "100%",
    overflowY: "auto",
    paddingRight: theme.spacing(1),
  },
}));

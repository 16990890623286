import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Stack, IconButton, Tooltip, Box } from "@mui/material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import axios from "axios";
import { DNA } from "react-loader-spinner";

const Questions = ({ state, dispatch, isMobile }) => {
  const [loading, setLoading] = useState(false);

  const handleDelete = (API) => {
    setLoading(true);
    axios
      .delete(API)
      .then((response) => {
        if (response.status === 200) {
          toast.success(`Question Deleted Successfully`);
          dispatch({
            type: "set_questions_refresh_flag",
            payload: !state.questionsRefreshFlag,
          });
        } else {
          toast.error("Error deleting deleting question, Please Try after sometime");
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error deleting question", error);

        if (error.response && [400, 404, 409, 500].includes(error.response.status)) {
          switch (error.response.status) {
            case 400:
              toast.error("Bad request. Please check selected question");
              break;
            case 404:
              toast.error("Seleted question not found");
              break;
            case 409:
              toast.error("There is a conflict with the current state of the question");
              break;
            case 500:
              toast.error("Server error while deleting question, Please Try after sometime");
              break;
            default:
              toast.error("An error occurred while deleting question, Please Try after sometime");
          }
        } else {
          toast.error("Error deleting deleting question, Please Contact Developer");
        }

        setLoading(false);
      });
  };

  const handleDeleteConfirmation = () => {
    const checkboxHtml = `
    <div style="display: flex; flex-direction: column; align-items: center;">
    <label style="margin-bottom: 5px;">
      Are you sure you want to delete this question?"
       </label>
      <div style="display: flex; flex-direction: column; align-items: start;">
        <label style="margin-bottom: 5px;">
          <input type="checkbox" id="checkbox" name="checkbox" value="value"> Delete Category and Question
        </label>
      </div>
    </div>
  `;

    Swal.fire({
      title: "Confirm Deletion",
      text: "Are you sure you want to delete this question?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
      html: checkboxHtml,
    }).then((result) => {
      if (result.isConfirmed) {
        const checkboxValue = document.getElementById("checkbox").checked;

        let API;
        const categoryId = state.selectedCategoryItem._id;
        const questionId = state.selectedQuestionItem._id;

        if (checkboxValue) {
          API = `${process.env.REACT_APP_API_URL}/api/qna/delete/category/question/${categoryId}/${questionId}`; // Replace with your actual value
        } else {
          API = `${process.env.REACT_APP_API_URL}/api/qna/delete/categoryonly/question/${categoryId}/${questionId}`; // Replace with your actual value
        }

        handleDelete(API);
      } else {
        // Handle cancellation
        // example codes to clear up
      }
    });
  };

  const dispatchEditQuestion = () => {
    dispatch({ type: "set_question_modal", payload: true });
    dispatch({
      type: "set_question_action",
      payload: "edit",
    });
  };

  const showToastNotification = (action) => {
    toast.warning(`Please select a question before ${action}.`);
  };

  return (
    <>
      {loading && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <DNA
            visible={true}
            height={isMobile ? "120" : "200"}
            width={isMobile ? "200" : "250"}
            color="#2B2A29"
            ariaLabel="dna-loading"
            wrapperStyle={{}}
            wrapperClass="dna-wrapper"
          />
        </div>
      )}
      <Stack direction="row" spacing={2}>
        <Box
          sx={{
            position: "absolute",
            top: isMobile ? 50 : 15,
            right: 20,
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
            justifyContent: "flex-end",
            zIndex: 4,
            margin: "10px",
          }}
        >
          <Tooltip title="Add Question" arrow>
            <IconButton
              edge="end"
              aria-label="add"
              onClick={(e) => {
                e.stopPropagation();
                dispatch({ type: "set_question_modal", payload: true });
                dispatch({
                  type: "set_question_action",
                  payload: "add",
                });
              }}
              sx={{ color: "black", mr: 1 }}
              size="large"
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Edit Question" arrow>
            <IconButton
              edge="end"
              aria-label="add"
              onClick={(e) => {
                e.stopPropagation();
                state.selectedQuestionItem
                  ? dispatchEditQuestion()
                  : showToastNotification("editing");
              }}
              sx={{ color: "black", mr: 1 }}
              size="large"
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete Question" arrow>
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={(e) => {
                e.stopPropagation();
                state.selectedQuestionItem
                  ? handleDeleteConfirmation()
                  : showToastNotification("deleting");
              }}
              sx={{ color: "black" }}
              size="large"
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Stack>
    </>
  );
};

export default Questions;

import React from "react";
import EducationalDegreeList from "./EducationalDegree/EducationalDegreeList";
import EducationalCollegeList from "./EducationalCollege/EducationalCollegeList";
import MedicineTypeList from "./MedicineType/MedicineTypeList";
import MedicineDeliveryList from "./MedicineDelivery/MedicineDeliveryList";
import MedicnesList from "./Medicnes/MedicnesList";
import PharmaCompaniesList from "./PharmaCompanies/PharmaCompaniesList";
import Symptoms from "./Symptoms/Symptoms";
import DaignosticDisease from "./DiagnosticDisease/DiagnosticDisease";

const LookUpDetails = ({ modelType, isMobile }) => {
  return (
    <>
      {modelType === "Educational Degree" && <EducationalDegreeList isMobile={isMobile} />}
      {modelType === "Educational College" && <EducationalCollegeList isMobile={isMobile} />}
      {modelType === "Medicine Type" && <MedicineTypeList isMobile={isMobile} />}
      {modelType === "Medicine Delivery" && <MedicineDeliveryList isMobile={isMobile} />}
      {modelType === "Medicines" && <MedicnesList isMobile={isMobile} />}
      {modelType === "Pharma Companies" && <PharmaCompaniesList isMobile={isMobile} />}
      {modelType === "Symptoms" && <Symptoms isMobile={isMobile} />}
      {modelType === "Diagnostic Disease" && <DaignosticDisease isMobile={isMobile} />}
    </>
  );
};

export default LookUpDetails;

import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import MedicineType from "./MedicineType";
import MedicineDelivery from "./MedicineDelivery";
import MedicineText from "./MedicineText";
import MedicineTable from "./MedicineTable";
import axios from "axios";
import { DNA } from "react-loader-spinner";
import { motion } from "framer-motion";

const MedicnesList = ({ isMobile }) => {
  // States for managing data
  const [medicineData, setMedicineData] = useState([]);
  const [medicineTypes, setMedicineTypes] = useState([]);
  const [selectedType, setSelectedType] = useState({});
  const [medicineDeliveries, setMedicineDeliveries] = useState([]);
  const [selectedDelivery, setSelectedDelivery] = useState({});

  const [englishName, setEnglishName] = useState("");
  const [sanskritName, setSanskritName] = useState("");
  const [action, setAction] = useState("add");
  const [refreshFlag, setRefreshFlag] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch medicine data from the API using Axios
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/medicine/get/all`);

        if (response && response.status === 200) {
          if (response.data && response.data.data) {
            const formattedData = response.data.data.map((item, index) => ({
              ...item,
              id: index + 1,
              medicineId: item._id,
              type: item.medicineTypeName.name,
              delivery: item.medicineDeliveryName.name,
              englishName: item.medicineName_EN,
              sanskritName: item.medicineName_SN,
            }));

            setMedicineData(formattedData);
          } else {
            setMedicineData([]);
            console.error("Error fetching medicine data");
          }

          setLoading(false);
        } else {
          setMedicineData([]);
          console.error("Error fetching medicine data");
        }
      } catch (error) {
        setMedicineData([]);
        console.error("Error fetching medicine data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [refreshFlag]);

  const clearState = () => {
    setEnglishName("");
    setSanskritName("");
    setSelectedType({});
    setSelectedDelivery({});
    setSelectedId("");
    setAction("add");
  };

  // Handle deleting an item
  const handleEdit = (data) => {
    setAction("edit");
    setSelectedId(data.medicineId);
    setEnglishName(data.englishName);
    setSanskritName(data.sanskritName);

    setSelectedType({
      _id: data.medicineTypeName.id,
      medicineTypeName: data.medicineTypeName.name,
    });

    setSelectedDelivery({
      _id: data.medicineDeliveryName.id,
      medicineDeliveryName: data.medicineDeliveryName.name,
    });
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
        style={{
          position: "sticky",
          top: 0,
          background: "#A52D59",
          zIndex: 1,
          padding: "5px",
        }}
      >
        <Grid item lg={3} md={3} sm={4} xs={2}>
          <MedicineType
            selectedType={selectedType}
            setSelectedType={setSelectedType}
            medicineTypes={medicineTypes}
            setMedicineTypes={setMedicineTypes}
          />
        </Grid>
        <Grid item lg={3} md={3} sm={4} xs={2}>
          <MedicineDelivery
            selectedDelivery={selectedDelivery}
            setSelectedDelivery={setSelectedDelivery}
            medicineDeliveries={medicineDeliveries}
            setMedicineDeliveries={setMedicineDeliveries}
          />
        </Grid>
        <MedicineText
          selectedType={selectedType}
          selectedDelivery={selectedDelivery}
          englishName={englishName}
          setEnglishName={setEnglishName}
          sanskritName={sanskritName}
          setSanskritName={setSanskritName}
          selectedId={selectedId}
          refresh={clearState}
          refreshFlag={refreshFlag}
          setRefreshFlag={setRefreshFlag}
          action={action}
        />
      </Grid>
      <Grid item xs={12} marginTop="5%">
        {loading ? (
          <motion.div>
            <DNA
              visible={true}
              height={isMobile ? "120" : "200"}
              width={isMobile ? "200" : "250"}
              color="#2B2A29"
              ariaLabel="dna-loading"
              wrapperStyle={{}}
              wrapperClass="dna-wrapper"
            />
          </motion.div>
        ) : medicineData && medicineData.length > 0 ? (
          <MedicineTable
            medicineData={medicineData}
            refreshFlag={refreshFlag}
            setRefreshFlag={setRefreshFlag}
            handleEdit={handleEdit}
            refresh={clearState}
            isMobile={isMobile}
          />
        ) : (
          <Typography fontSize="1.6rem" mt="15%">
            Medicine Data Not Found
          </Typography>
        )}
      </Grid>
    </>
  );
};

export default MedicnesList;

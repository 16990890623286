import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import CloseIcon from "@mui/icons-material/Close";
import "react-perfect-scrollbar/dist/css/styles.css";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Paper,
  ListItem,
  Grid,
  IconButton,
} from "@mui/material";
import { DNA } from "react-loader-spinner";
import { motion } from "framer-motion";

const IntakeFormDailog = ({ loading, isOpen, handleClose, intakeFormData }) => {
  if (!isOpen || !intakeFormData) {
    if (isOpen && loading) {
      return (
        <Dialog
          open={isOpen}
          onClose={handleClose}
          maxWidth="md"
          fullWidth
          sx={{ backgroundColor: "rgba(255, 255, 255, 0.7)" }}
        >
          <div style={{ textAlign: "center", padding: "20px" }}>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
            >
              <DNA
                visible={true}
                height={120}
                width={200}
                color="#2B2A29"
                ariaLabel="dna-loading"
                wrapperStyle={{}}
                wrapperClass="dna-wrapper"
              />
            </motion.div>
          </div>
        </Dialog>
      );
    }
    return null;
  }
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      sx={{ backgroundColor: "rgba(255, 255, 255, 0.7)" }}
    >
      <DialogTitle
        variant="body5"
        style={{
          textAlign: "center",
          color: "#fff",
          backgroundColor: "#721F4B",
          padding: "20px",
        }}
      >
        Patient Intake Form
      </DialogTitle>

      <DialogActions style={{ justifyContent: "center", borderTop: "1px solid #eee" }}>
        <IconButton
          sx={{ position: "absolute", top: 5, right: 5 }}
          onClick={handleClose}
          size="larger"
        >
          <CloseIcon
            style={{
              color: "#fff",
            }}
          />
        </IconButton>
      </DialogActions>
      <PerfectScrollbar style={{ width: "100%", height: "80vh" }}>
        <DialogContent
          style={{
            // margin: "20px",
            color: "#333",
            fontFamily: "Arial, sans-serif",
          }}
        >
          {intakeFormData ? (
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Paper sx={{ borderRadius: 3, boxShadow: "0px 4px 8px rgba(0, 0, 0,1)" }}>
                  <ListItem style={{ marginBottom: "10px" }}>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "1.4rem",
                        color: "#333",
                        fontWeight: "bold",
                        marginRight: "8px",
                      }}
                    >
                      Height:
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "1.4rem",
                        color: "#890F3C",
                      }}
                    >
                      {intakeFormData.height}
                    </Typography>
                  </ListItem>
                </Paper>
              </Grid>

              <Grid item xs={12} md={6}>
                <Paper sx={{ borderRadius: 3, boxShadow: "0px 4px 8px rgba(0, 0, 0,1)" }}>
                  <ListItem style={{ marginBottom: "10px" }}>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "1.4rem",
                        color: "#333",
                        fontWeight: "bold",
                        marginRight: "8px",
                      }}
                    >
                      Weight:
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "1.4rem",
                        color: "#890F3C",
                      }}
                    >
                      {intakeFormData.weight}
                    </Typography>
                  </ListItem>
                </Paper>
              </Grid>

              <Grid item xs={12} md={6}>
                <Paper sx={{ borderRadius: 3, boxShadow: "0px 4px 8px rgba(0, 0, 0,1)" }}>
                  <ListItem style={{ marginBottom: "10px" }}>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "1.4rem",
                        color: "#333",
                        fontWeight: "bold",
                        marginRight: "8px",
                      }}
                    >
                      Heart Rate:
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "1.4rem",
                        color: "#890F3C",
                      }}
                    >
                      {intakeFormData.heartRate}
                    </Typography>
                  </ListItem>
                </Paper>
              </Grid>

              <Grid item xs={12} md={6}>
                <Paper sx={{ borderRadius: 3, boxShadow: "0px 4px 8px rgba(0, 0, 0,1)" }}>
                  <ListItem style={{ marginBottom: "10px" }}>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "1.4rem",
                        color: "#333",
                        fontWeight: "bold",
                        marginRight: "8px",
                      }}
                    >
                      Blood Pressure:
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "1.4rem",
                        color: "#890F3C",
                      }}
                    >
                      {intakeFormData.bloodPressure}
                    </Typography>
                  </ListItem>
                </Paper>
              </Grid>

              <Grid item xs={12}>
                <Paper sx={{ borderRadius: 3, boxShadow: "0px 4px 8px rgba(0, 0, 0,1)" }}>
                  <ListItem
                    style={{
                      marginBottom: "10px",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "1.4rem",
                        color: "#333",
                        fontWeight: "bold",
                        marginRight: "8px",
                      }}
                    >
                      Chief Complaint:
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "1.4rem",
                        color: "#890F3C",
                        display: "flex",
                        alignItems: "flex-start",
                      }}
                    >
                      {intakeFormData.chiefComplaint}
                    </Typography>
                  </ListItem>
                </Paper>
              </Grid>

              <Grid item xs={12} md={6}>
                <Paper sx={{ borderRadius: 3, boxShadow: "0px 4px 8px rgba(0, 0, 0,1)" }}>
                  <ListItem
                    style={{
                      marginBottom: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "1.4rem",
                        color: "#333",
                        fontWeight: "bold",
                        marginRight: "8px",
                      }}
                    >
                      Past History:
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "1.4rem",
                        color: "#890F3C",
                      }}
                    >
                      {intakeFormData.pastHistory}
                    </Typography>
                  </ListItem>
                </Paper>
              </Grid>

              <Grid item xs={12} md={6}>
                <Paper sx={{ borderRadius: 3, boxShadow: "0px 4px 8px rgba(0, 0, 0,1)" }}>
                  <ListItem
                    style={{
                      marginBottom: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "1.4rem",
                        color: "#333",
                        fontWeight: "bold",
                        marginRight: "8px",
                      }}
                    >
                      Past Surgeries:{" "}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "1.4rem",
                        color: "#890F3C",
                      }}
                    >
                      {intakeFormData.pastSurgeries}
                    </Typography>
                  </ListItem>
                </Paper>
              </Grid>

              <Grid item xs={12} md={6}>
                <Paper sx={{ borderRadius: 3, boxShadow: "0px 4px 8px rgba(0, 0, 0,1)" }}>
                  <ListItem
                    style={{
                      marginBottom: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "1.4rem",
                        color: "#333",
                        fontWeight: "bold",
                        marginRight: "8px",
                      }}
                    >
                      Chronic Illnesses:{" "}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "1.4rem",
                        color: "#890F3C",
                      }}
                    >
                      {intakeFormData.chronicIllnesses}
                    </Typography>
                  </ListItem>
                </Paper>
              </Grid>

              <Grid item xs={12} md={6}>
                <Paper sx={{ borderRadius: 3, boxShadow: "0px 4px 8px rgba(0, 0, 0,1)" }}>
                  <ListItem
                    style={{
                      marginBottom: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "1.4rem",
                        color: "#333",
                        fontWeight: "bold",
                        marginRight: "8px",
                      }}
                    >
                      Current Medications:{" "}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "1.4rem",
                        color: "#890F3C",
                      }}
                    >
                      {intakeFormData.currentMedications}
                    </Typography>
                  </ListItem>
                </Paper>
              </Grid>

              <Grid item xs={12} md={6}>
                <Paper sx={{ borderRadius: 3, boxShadow: "0px 4px 8px rgba(0, 0, 0,1)" }}>
                  <ListItem
                    style={{
                      marginBottom: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "1.4rem",
                        color: "#333",
                        fontWeight: "bold",
                        marginRight: "8px",
                      }}
                    >
                      Allergies:{" "}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "1.4rem",
                        color: "#890F3C",
                      }}
                    >
                      {intakeFormData.allergies}
                    </Typography>
                  </ListItem>
                </Paper>
              </Grid>

              <Grid item xs={12} md={6}>
                <Paper sx={{ borderRadius: 3, boxShadow: "0px 4px 8px rgba(0, 0, 0,1)" }}>
                  <ListItem
                    style={{
                      marginBottom: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "1.4rem",
                        color: "#333",
                        fontWeight: "bold",
                        marginRight: "8px",
                      }}
                    >
                      Occupation:
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "1.4rem",
                        color: "#890F3C",
                      }}
                    >
                      {intakeFormData.occupation}
                    </Typography>
                  </ListItem>
                </Paper>
              </Grid>

              <Grid item xs={12} md={6}>
                <Paper sx={{ borderRadius: 3, boxShadow: "0px 4px 8px rgba(0, 0, 0,1)" }}>
                  <ListItem
                    style={{
                      marginBottom: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "1.4rem",
                        color: "#333",
                        fontWeight: "bold",
                        marginRight: "8px",
                      }}
                    >
                      Daily Work Environment:
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "1.4rem",
                        color: "#890F3C",
                      }}
                    >
                      {intakeFormData.dailyWorkEnvironment}
                    </Typography>
                  </ListItem>
                </Paper>
              </Grid>

              {intakeFormData && intakeFormData.dailyDiet && (
                <Grid item xs={12} md={6}>
                  <Paper sx={{ borderRadius: 3, boxShadow: "0px 4px 8px rgba(0, 0, 0,1)" }}>
                    <ListItem
                      style={{
                        marginBottom: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: "1.4rem",
                          color: "#333",
                          fontWeight: "bold",
                          marginRight: "8px",
                        }}
                      >
                        Daily Diet:{" "}
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: "1.4rem",
                          color: "#890F3C",
                        }}
                      >
                        {intakeFormData.dailyDiet.meals.join(", ")}
                      </Typography>
                    </ListItem>
                  </Paper>
                </Grid>
              )}

              {intakeFormData && intakeFormData.dailyDiet && (
                <Grid item xs={12} md={6}>
                  <Paper sx={{ borderRadius: 3, boxShadow: "0px 4px 8px rgba(0, 0, 0,1)" }}>
                    <ListItem
                      style={{
                        marginBottom: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: "1.4rem",
                          color: "#333",
                          fontWeight: "bold",
                          marginRight: "8px",
                        }}
                      >
                        Dietary Preferences:{" "}
                      </Typography>{" "}
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: "1.4rem",
                          color: "#890F3C",
                        }}
                      >
                        {intakeFormData.dailyDiet.dietaryPreferences}
                      </Typography>
                    </ListItem>
                  </Paper>
                </Grid>
              )}

              {intakeFormData && intakeFormData.exerciseHabits && (
                <Grid item xs={12} md={6}>
                  <Paper sx={{ borderRadius: 3, boxShadow: "0px 4px 8px rgba(0, 0, 0,1)" }}>
                    <ListItem
                      style={{
                        marginBottom: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: "1.4rem",
                          color: "#333",
                          fontWeight: "bold",
                          marginRight: "8px",
                        }}
                      >
                        Exercise Type:{" "}
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: "1.4rem",
                          color: "#890F3C",
                        }}
                      >
                        {intakeFormData.exerciseHabits.type}
                      </Typography>
                    </ListItem>
                  </Paper>
                </Grid>
              )}

              {intakeFormData && intakeFormData.exerciseHabits && (
                <Grid item xs={12} md={6}>
                  <Paper sx={{ borderRadius: 3, boxShadow: "0px 4px 8px rgba(0, 0, 0,1)" }}>
                    <ListItem
                      style={{
                        marginBottom: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: "1.4rem",
                          color: "#333",
                          fontWeight: "bold",
                          marginRight: "8px",
                        }}
                      >
                        Exercise Frequency:
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: "1.4rem",
                          color: "#890F3C",
                        }}
                      >
                        {" "}
                        {intakeFormData.exerciseHabits.frequency}
                      </Typography>
                    </ListItem>
                  </Paper>
                </Grid>
              )}

              {intakeFormData && intakeFormData.exerciseHabits && (
                <Grid item xs={12} md={6}>
                  <Paper sx={{ borderRadius: 3, boxShadow: "0px 4px 8px rgba(0, 0, 0,1)" }}>
                    <ListItem
                      style={{
                        marginBottom: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: "1.4rem",
                          color: "#333",
                          fontWeight: "bold",
                          marginRight: "8px",
                        }}
                      >
                        Exercise Duration:{" "}
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: "1.4rem",
                          color: "#890F3C",
                        }}
                      >
                        {intakeFormData.exerciseHabits.duration}
                      </Typography>
                    </ListItem>
                  </Paper>
                </Grid>
              )}

              {intakeFormData && intakeFormData.sleepPatterns && (
                <Grid item xs={12} md={6}>
                  <Paper sx={{ borderRadius: 3, boxShadow: "0px 4px 8px rgba(0, 0, 0,1)" }}>
                    <ListItem
                      style={{
                        marginBottom: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: "1.4rem",
                          color: "#333",
                          fontWeight: "bold",
                          marginRight: "8px",
                        }}
                      >
                        Sleep Average Hours:
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: "1.4rem",
                          color: "#890F3C",
                        }}
                      >
                        {intakeFormData.sleepPatterns.averageHours}
                      </Typography>
                    </ListItem>
                  </Paper>
                </Grid>
              )}

              {intakeFormData && intakeFormData.sleepPatterns && (
                <Grid item xs={12} md={6}>
                  <Paper sx={{ borderRadius: 3, boxShadow: "0px 4px 8px rgba(0, 0, 0,1)" }}>
                    <ListItem
                      style={{
                        marginBottom: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: "1.4rem",
                          color: "#333",
                          fontWeight: "bold",
                          marginRight: "8px",
                        }}
                      >
                        Sleep Quality:
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: "1.4rem",
                          color: "#890F3C",
                        }}
                      >
                        {intakeFormData.sleepPatterns.quality}
                      </Typography>
                    </ListItem>
                  </Paper>
                </Grid>
              )}
              <Grid item xs={12} md={6}>
                <Paper sx={{ borderRadius: 3, boxShadow: "0px 4px 8px rgba(0, 0, 0,1)" }}>
                  <ListItem
                    style={{
                      marginBottom: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "1.4rem",
                        color: "#333",
                        fontWeight: "bold",
                        marginRight: "8px",
                      }}
                    >
                      Substance Use:
                    </Typography>{" "}
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "1.4rem",
                        color: "#890F3C",
                      }}
                    >
                      {intakeFormData.substanceUse}
                    </Typography>
                  </ListItem>
                </Paper>
              </Grid>

              <Grid item xs={12} md={6}>
                <Paper sx={{ borderRadius: 3, boxShadow: "0px 4px 8px rgba(0, 0, 0,1)" }}>
                  <ListItem
                    style={{
                      marginBottom: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "1.4rem",
                        color: "#333",
                        fontWeight: "bold",
                        marginRight: "8px",
                      }}
                    >
                      Attempt:{" "}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "1.4rem",
                        color: "#890F3C",
                      }}
                    >
                      {intakeFormData.attempt}
                    </Typography>
                  </ListItem>
                </Paper>
              </Grid>
            </Grid>
          ) : (
            // Display a message indicating no intake form data
            <Typography variant="body1">No intake form data available for this patient.</Typography>
          )}
        </DialogContent>
      </PerfectScrollbar>
    </Dialog>
  );
};

export default IntakeFormDailog;

/* eslint-disable no-unused-vars */
import React, { useState, useContext } from "react";
import Cookies from "js-cookie";
import MailIcon from "@mui/icons-material/Mail";
import LockIcon from "@mui/icons-material/Lock";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Paper,
  CssBaseline,
  Container,
  Typography,
  Box,
  TextField,
  IconButton,
  InputAdornment,
  Button,
  Tooltip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import AuthContext from "../../AuthContext";
import axios from "axios";
import { toast } from "react-toastify";

const AdminLogin = ({ isMobile }) => {
  const { login } = useContext(AuthContext);

  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleImgClick = () => {
    navigate("/");
  };

  const handleLogin = async () => {
    try {
      if (!email || !password) {
        toast.error("Please fill in the required fields");
        return;
      }

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/admin-login/post/login`,
        {
          email,
          password,
        },
      );

      if (response && response.status === 200 && response.data && response.data.data) {
        login(response.data.data);

        Cookies.set("token", response.data.token);

        toast.success("Great to see you again! You're now logged in");

        navigate("/admin");
      } else {
        toast.error("Permission denied. Unauthorized admin login attempt detected!!!");
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Invalid admin email or password");
      } else if (error.response) {
        toast.error("Permission denied. Unauthorized admin login attempt detected!!!");
      } else {
        console.error(error);
        toast.error("Error logging in, please Contact Developer");
      }
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      style={{
        backgroundImage: `url(${
          isMobile
            ? `${process.env.PUBLIC_URL}/images/Portrait-Image.jpg`
            : `${process.env.PUBLIC_URL}/images/Landscape-Image.jpeg`
        })`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      <div onClick={handleImgClick} style={{ cursor: "pointer" }}>
        <Tooltip title="Navigate to Main Page" arrow>
          <motion.img
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            src={`${process.env.PUBLIC_URL}/images/Company-Logo.png`}
            alt="Overlay"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: isMobile ? "130px" : "140px",
              height: isMobile ? "130px" : "140px",
              zIndex: 1,
            }}
          />
        </Tooltip>
      </div>
      <CssBaseline />
      <Container maxWidth={isMobile ? "sm" : "md"}>
        <AnimatePresence>
          <Paper
            elevation={24}
            sx={{
              padding: isMobile ? "30px" : "50px",
              borderRadius: "40px",
              mt: isMobile ? "50%" : "20%",
              mb: "10%",
              backgroundColor: "rgba(255, 255, 255, 0.9)", // Light white with transparency
            }}
          >
            <Typography
              component="div"
              sx={{
                fontWeight: "bold",
                textAlign: "center",
                fontSize: "160%",
              }}
            >
              WELCOME TO THE ADMIN PORTAL. PLEASE SIGN IN TO ACCESS ADMINISTRATIVE FEATURES
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
              <TextField
                fullWidth
                label="Email"
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onKeyPress={
                  isMobile
                    ? null
                    : (e) => {
                        if (e.key === "Enter") {
                          e.preventDefault(); // Prevent the default action
                          handleLogin();
                        }
                      }
                }
                placeholder="Enter Email"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MailIcon />
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{ style: { color: "black" } }}
                autoComplete="off"
                sx={{
                  "& input": {
                    color: "black",
                  },
                  "& .MuiOutlinedInput-root.Mui-focused": {
                    borderColor: "black",
                    "& fieldset": {
                      borderColor: "black",
                    },
                  },
                }}
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
              <TextField
                fullWidth
                label="Password"
                variant="outlined"
                type={showPassword ? "text" : "password"}
                value={password}
                placeholder="Enter Password"
                onChange={(e) => setPassword(e.target.value)}
                onKeyPress={
                  isMobile
                    ? null
                    : (e) => {
                        if (e.key === "Enter") {
                          e.preventDefault(); // Prevent the default action
                          handleLogin();
                        }
                      }
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickShowPassword} edge="end">
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{ style: { color: "black" } }}
                autoComplete="off"
                sx={{
                  "& input": {
                    color: "black",
                  },
                  "& .MuiOutlinedInput-root.Mui-focused": {
                    borderColor: "black",
                    "& fieldset": {
                      borderColor: "black",
                    },
                  },
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Button
                variant="contained"
                onClick={handleLogin}
                sx={{
                  borderRadius: 28,
                  backgroundColor: "white",
                  fontWeight: "bold",
                  color: "black",
                  fontSize: "1rem",
                  padding: "9px 18px",
                  border: "2px solid black", // Adding a black border
                  "&:hover": {
                    backgroundColor: "black", // Change background color on hover
                    color: "white", // Change text color on hover
                    border: "2px solid white", // Change border color on hover
                  },
                  mt: 4,
                }}
              >
                Admin Login
              </Button>
            </Box>
          </Paper>
        </AnimatePresence>
      </Container>
    </motion.div>
  );
};

export default AdminLogin;

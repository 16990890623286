/* eslint-disable no-unused-vars */
import React, { useReducer } from "react";
import { Container, Paper, Tooltip } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import countries from "../../country.json";
import DoctorMobileField from "./DoctorMobileField";
import DoctorMobileOtp from "./DoctorMobileOtp";

const initialState = {
  component: "login mobile fields",
  // selectedCountry: countries.find((country) => country.name === "India"),
  // countryCode: "+91",
  // mobileNumber: "",
  otp: "",
  mobileNumberFlag: "",
  otpFlag: false,
  loginNumber: "",
  loginCountry: countries.find((country) => country.name === "India"),
  loginMobileCode: "+91",
  loginNumberFlag: "",
  loginOtp: "",
  loginOtpFlag: false,
  showLoading: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "set_component":
      return { ...state, component: action.payload };
    case "set_name":
      return { ...state, name: action.payload };
    case "set_selected_country":
      return { ...state, selectedCountry: action.payload };
    case "set_country_code":
      return { ...state, countryCode: action.payload };
    case "set_mobile_number":
      return { ...state, mobileNumber: action.payload };
    case "set_otp":
      return { ...state, otp: action.payload };
    case "set_mobile_number_flag":
      return { ...state, mobileNumberFlag: action.payload };
    case "set_otp_flag":
      return { ...state, otpFlag: action.payload };
    case "set_login_number":
      return { ...state, loginNumber: action.payload };
    case "set_login_country":
      return { ...state, loginCountry: action.payload };
    case "set_login_mobile_code":
      return { ...state, loginMobileCode: action.payload };
    case "set_login_number_flag":
      return { ...state, loginNumberFlag: action.payload };
    case "set_login_otp":
      return { ...state, loginOtp: action.payload };
    case "set_login_otp_flag":
      return { ...state, loginOtpFlag: action.payload };
    case "set_show_loading":
      return { ...state, showLoading: action.payload };
    default:
      return state;
  }
};

const DoctorLogin = ({ isMobile }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();

  const handleCloseLoading = (logic) => {
    dispatch({
      type: "set_show_loading",
      payload: logic,
    });
  };

  const handleImgClick = () => {
    navigate("/");
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      style={{
        backgroundImage: `url(${
          isMobile
            ? `${process.env.PUBLIC_URL}/images/Portrait-Image.jpg`
            : `${process.env.PUBLIC_URL}/images/Landscape-Image.jpeg`
        })`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      <div onClick={handleImgClick} style={{ cursor: "pointer" }}>
        <Tooltip title="Navigate to Main Page" arrow>
          <motion.img
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            src={`${process.env.PUBLIC_URL}/images/Company-Logo.png`}
            alt="Overlay"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: isMobile ? "130px" : "160px",
              height: isMobile ? "130px" : "160px",
              zIndex: 1,
            }}
          />
        </Tooltip>
      </div>
      <Container maxWidth={isMobile ? "sm" : "md"}>
        <AnimatePresence>
          <Paper
            elevation={24}
            style={{
              padding: isMobile ? "30px" : "100px",
              zIndex: 2,
              borderRadius: "40px",
              marginTop: "50px",
              backgroundColor: "rgba(255, 255, 255, 0.9)",
            }}
          >
            {state.component === "login mobile fields" && (
              <DoctorMobileField
                state={state}
                dispatch={dispatch}
                closeLoading={handleCloseLoading}
                isMobile={isMobile}
              />
            )}
            {state.component === "login mobile otp" && (
              <DoctorMobileOtp
                state={state}
                dispatch={dispatch}
                closeLoading={handleCloseLoading}
                isMobile={isMobile}
              />
            )}
          </Paper>
        </AnimatePresence>
      </Container>
    </motion.div>
  );
};

export default DoctorLogin;

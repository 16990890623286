/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  Button,
  Select,
  MenuItem,
  Divider,
  DialogContent,
  Typography,
  Box,
  TextField,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import InputLabel from "@mui/material/InputLabel";

const AddTimingModal = ({ open, handleClose, handleSave, selectedTiming }) => {
  const [day, setDay] = useState("Monday");
  const [from, setTimeFrom] = useState("");
  const [to, setTimeTo] = useState("");

  useEffect(() => {
    if (selectedTiming) {
      setDay(selectedTiming.day);
      setTimeFrom(selectedTiming.from ? dayjs(selectedTiming.from, "hh:mm A") : null);
      setTimeTo(selectedTiming.to ? dayjs(selectedTiming.to, "hh:mm A") : null);
    } else {
      setDay("Monday");
      setTimeFrom(null);
      setTimeTo(null);
    }
  }, [selectedTiming]);

  const handleDayChange = (event) => {
    setDay(event.target.value);
  };

  const handleTimeFromChange = (newValue) => {
    setTimeFrom(newValue);
  };

  const handleTimeToChange = (newValue) => {
    setTimeTo(newValue);
  };

  const handleSaveClick = () => {
    const formattedTimeFrom = from ? from.format("hh:mm A") : "";
    const formattedTimeTo = to ? to.format("hh:mm A") : "";

    const formattedTimeFromWithAMPM = formattedTimeFrom ? `${formattedTimeFrom} ` : "";
    const formattedTimeToWithAMPM = formattedTimeTo ? `${formattedTimeTo} ` : "";
    console.log("formattedTimeFrom", formattedTimeFromWithAMPM);
    console.log("formattedTimeTo", formattedTimeToWithAMPM);

    const newTiming = { day, from: formattedTimeFromWithAMPM, to: formattedTimeToWithAMPM };
    handleSave(newTiming);
    handleClose();

    setDay("Monday");
    setTimeFrom(null);
    setTimeTo(null);
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle
          sx={{
            fontSize: "1.6rem",
          }}
          align="left"
        >
          {selectedTiming ? "Update Timings" : "Add Timings"}{" "}
        </DialogTitle>
        <Divider sx={{ bgcolor: "black" }} />

        <DialogContent>
          <Box display="flex" alignItems="center" mb={2}>
            <Typography
              variant="subtitle1"
              mr={2}
              sx={{
                color: "black",
              }}
            >
              Day
            </Typography>

            <Select
              value={day}
              onChange={handleDayChange}
              flex={1}
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "black",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "black",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "black",
                },
                color: "black",
                "& .MuiSvgIcon-root": {
                  color: "black",
                },
                width: "50%",
              }}
            >
              <MenuItem value="Monday">Monday</MenuItem>
              <MenuItem value="Tuesday">Tuesday</MenuItem>
              <MenuItem value="Wednesday">Wednesday</MenuItem>
              <MenuItem value="Thursday">Thursday</MenuItem>
              <MenuItem value="Friday">Friday</MenuItem>
              <MenuItem value="Saturday">Saturday</MenuItem>
              <MenuItem value="Sunday">Sunday</MenuItem>
            </Select>
          </Box>

          <Box display="flex" alignItems="center" mb={2}>
            <Typography
              variant="subtitle1"
              mr={2}
              sx={{
                color: "black",
              }}
            >
              From
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["TimePicker"]}>
                <TimePicker
                  label="Basic time picker"
                  value={from}
                  onChange={handleTimeFromChange}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "black",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "black",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "black",
                    },
                    color: "black",
                    "& .MuiSvgIcon-root": {
                      color: "black",
                    },
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Box>

          <Box display="flex" alignItems="center" mb={2}>
            <Typography
              variant="subtitle1"
              mr={2}
              sx={{
                color: "black",
              }}
            >
              To
            </Typography>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["TimePicker"]}>
                <TimePicker
                  label="Basic time picker"
                  value={to}
                  onChange={handleTimeToChange}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "black",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "black",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "black",
                    },
                    color: "black",
                    "& .MuiSvgIcon-root": {
                      color: "black",
                    },
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Box>

          <Divider sx={{ margin: "16px 0", bgcolor: "black" }} />

          <Box display="flex" alignItems="center">
            <Button
              onClick={handleSaveClick}
              sx={{
                fontWeight: "bold",
              }}
            >
              {selectedTiming ? "Update" : "Save"}
            </Button>
            <Button
              onClick={handleClose}
              sx={{
                fontWeight: "bold",
              }}
            >
              Cancel
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddTimingModal;

import React, { useReducer } from "react";
import { motion } from "framer-motion";
import SideBar from "../SideBar";
import Categories from "./Categories/Categories";
import QaEditor from "./QA/QaEditor";
import Topic from "./Topic/Topic";
import Questions from "./Questions/Questions";
import Doctors from "./Doctors/Doctors";
import LookUp from "./LookUp/LookUp";
import Patients from "./Patients/Patients";
import { Box, Grid } from "@mui/material";
import Header from "../Header";
import CompanyTimingsHolidays from "./Company Timings/CompanyTimingsHolidays";
import Users from "./Users/Users";
import OfflineUsers from "./Sales/OfflineUsers/OfflineUsers";
import Vendors from "./Purchases/Vendors/Vendors";
import MedicineQuotes from "./Sales/MedicineQuotes/MedicineQuotes";
import MedicineInvoices from "./Sales/MedicineInvoices/MedicineInvoices";
import SubscriptionInvoices from "./Sales/SubscriptionInvoices/SubscriptionInvoices";
import InventoriesRequest from "./Purchases/InventoriesRequest/InventoriesRequest";
import PurchaseItems from "./Purchases/PurchaseItems/PurchaseItems";
import PurchaseInvoices from "./Purchases/PurchaseInvoices/PurchaseInvoices";
import InventoryList from "./Inventory/InventoryList/InventoryList";
import ExpiredInventory from "./Inventory/ExpiredInventory/ExpiredInventory";

const initialState = {
  component: "Categories",
  isMobileDrawerOpen: false,
  selectedOption: "Categories",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "set_component":
      return { ...state, component: action.payload };
    case "set_is_mobile_drawer_open":
      return { ...state, isMobileDrawerOpen: action.payload };
    case "set_selected_option":
      return { ...state, selectedOption: action.payload };
    default:
      return state;
  }
};

const Admin = ({ isMobile }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const options = [
    { name: "Categories" },
    { name: "Q&A" },
    { name: "Questions" },
    { name: "Topic" },
    { name: "Users" },
    { name: "Patients" },
    { name: "Doctors" },
    // { name: "Lookup" },
    // {
    //   name: "Sales",
    //   subOptions: [
    //     { name: "Offline Users" },
    //     { name: "Medicine Quotes" },
    //     { name: "Medicine Invoices" },
    //     { name: "Subscription Invoices" },
    //   ],
    // },
    // {
    //   name: "Purchases",
    //   subOptions: [
    //     { name: "Vendors" },
    //     { name: "Raise Inventories Request" },
    //     { name: "Purchase Items" },
    //     { name: "Purchase Invoices" },
    //   ],
    // },
    // {
    //   name: "Inventory",
    //   subOptions: [{ name: "Inventory List" }, { name: "Expired Inventory" }],
    // },
    { name: "Company Timings" },
  ];

  return (
    <Grid container style={{ margin: 0, padding: 0, width: "100%", height: "100vh" }}>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/Screen.jpg)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "100vh",
          margin: 0,
          padding: 0,
          width: "100%",
          position: "absolute",
          top: 0,
          left: 0,
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
            justifyContent: "flex-end",
            zIndex: 2,
          }}
        >
          <Header />
        </Box>
        <SideBar state={state} dispatch={dispatch} options={options} isMobile={isMobile} />
        <>
          {state.component === "Categories" && <Categories isMobile={isMobile} />}
          {state.component === "Q&A" && <QaEditor isMobile={isMobile} />}
          {state.component === "Questions" && <Questions isMobile={isMobile} />}
          {state.component === "Topic" && <Topic isMobile={isMobile} />}
          {state.component === "Users" && <Users isMobile={isMobile} />}
          {state.component === "Patients" && <Patients isMobile={isMobile} />}
          {state.component === "Doctors" && <Doctors isMobile={isMobile} />}
          {state.component === "Lookup" && <LookUp isMobile={isMobile} />}
          {state.component === "Company Timings" && <CompanyTimingsHolidays isMobile={isMobile} />}

          {/* Sales */}
          {state.component === "Offline Users" && <OfflineUsers isMobile={isMobile} />}
          {state.component === "Medicine Quotes" && <MedicineQuotes isMobile={isMobile} />}
          {state.component === "Medicine Invoices" && <MedicineInvoices isMobile={isMobile} />}
          {state.component === "Subscription Invoices" && (
            <SubscriptionInvoices isMobile={isMobile} />
          )}

          {/* Purchases */}
          {state.component === "Vendors" && <Vendors isMobile={isMobile} />}
          {state.component === "Raise Inventories Request" && (
            <InventoriesRequest isMobile={isMobile} />
          )}
          {state.component === "Purchase Items" && <PurchaseItems isMobile={isMobile} />}
          {state.component === "Purchase Invoices" && <PurchaseInvoices isMobile={isMobile} />}

          {/* Inventory */}
          {state.component === "Inventory List" && <InventoryList isMobile={isMobile} />}
          {state.component === "Expired Inventory" && <ExpiredInventory isMobile={isMobile} />}
        </>
      </motion.div>
    </Grid>
  );
};

export default Admin;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Typography, Grid, ListItem, Tab, Tabs, Paper, Box } from "@mui/material";
import UpcomingAppointmentsTable from "./UpcomingAppointmentsTable";

const PatientDetails = ({ patient, isMobile }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    event.preventDefault();
    setSelectedTab(newValue);
  };

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "black", position: "sticky", top: "0", zIndex: 1 }}>
        <Tabs value={selectedTab} onChange={handleTabChange} aria-label="Patient Details Tabs">
          <Tab
            label="Patient Details"
            style={{
              color: selectedTab === 0 ? "white" : "black",
              backgroundColor: selectedTab === 0 ? "#890F3C" : "transparent",
              cursor: "pointer",
            }}
            onClick={(event) => handleTabChange(event, 0)}
          />
          <Tab
            label="Upcoming Appointments"
            style={{
              color: selectedTab === 1 ? "white" : "black",
              backgroundColor: selectedTab === 1 ? "#890F3C" : "transparent",
              cursor: "pointer",
            }}
            onClick={(event) => handleTabChange(event, 1)}
          />
        </Tabs>
      </Box>

      <Box sx={{ mt: 2, mx: 2 }}>
        {selectedTab === 0 && (
          <>
            <Typography variant="h5" gutterBottom fontSize="1.8rem" sx={{ mb: 2 }}>
              Patient Details
            </Typography>
            {patient ? (
              <Grid container spacing={4} alignItems="center" sx={{ mb: 18 }}>
                <Grid item xs={12} md={5} lg={5}>
                  <Paper sx={{ borderRadius: 5 }}>
                    <ListItem sx={{ display: "flex", alignItems: "center" }}>
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: "1.4rem",
                          color: "#333",
                          fontWeight: "bold",
                          marginRight: "8px",
                        }}
                      >
                        Full Name:
                      </Typography>
                      <Typography variant="body1" sx={{ fontSize: "1.4rem", color: "#890F3C" }}>
                        {patient.name}
                      </Typography>
                    </ListItem>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={3} lg={5}>
                  <Paper sx={{ borderRadius: 5 }}>
                    <ListItem sx={{ display: "flex", alignItems: "center" }}>
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: "1.4rem",
                          color: "#333",
                          fontWeight: "bold",
                          marginRight: "8px",
                        }}
                      >
                        Gender:
                      </Typography>
                      <Typography variant="body1" sx={{ fontSize: "1.4rem", color: "#890F3C" }}>
                        {patient.gender}
                      </Typography>
                    </ListItem>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={2} lg={2}>
                  <Paper sx={{ borderRadius: 5 }}>
                    <ListItem sx={{ display: "flex", alignItems: "center" }}>
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: "1.4rem",
                          color: "#333",
                          fontWeight: "bold",
                          marginRight: "8px",
                        }}
                      >
                        Age:
                      </Typography>
                      <Typography variant="body1" sx={{ fontSize: "1.4rem", color: "#890F3C" }}>
                        {patient.age}
                      </Typography>
                    </ListItem>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={8} lg={5}>
                  <Paper sx={{ borderRadius: 5 }}>
                    <ListItem sx={{ display: "flex", alignItems: "center" }}>
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: "1.4rem",
                          color: "#333",
                          fontWeight: "bold",
                          marginRight: "8px",
                        }}
                      >
                        Email:
                      </Typography>
                      <Typography variant="body1" sx={{ fontSize: "1.4rem", color: "#890F3C" }}>
                        {patient.email}
                      </Typography>
                    </ListItem>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4} lg={5}>
                  <Paper sx={{ borderRadius: 5 }}>
                    <ListItem sx={{ display: "flex", alignItems: "center" }}>
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: "1.4rem",
                          color: "#333",
                          fontWeight: "bold",
                          marginRight: "8px",
                        }}
                      >
                        Mobile Number:
                      </Typography>
                      <Typography variant="body1" sx={{ fontSize: "1.4rem", color: "#890F3C" }}>
                        {patient.mobileNumber}
                      </Typography>
                    </ListItem>
                  </Paper>
                </Grid>
              </Grid>
            ) : (
              <Typography variant="body1">Patient data not available.</Typography>
            )}
          </>
        )}

        {selectedTab === 1 && (
          <>
            <Typography gutterBottom fontSize="1.8rem">
              Upcoming Appointments
            </Typography>
            <UpcomingAppointmentsTable isMobile={isMobile} patient={patient} />
          </>
        )}
      </Box>
    </>
  );
};

export default PatientDetails;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../AuthContext";
import { Menu, MenuItem, IconButton, Avatar, Tooltip, ListItemIcon } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";
import AuthContext from "../AuthContext";

const Header = ({ isMobile }) => {
  const { user, logout } = useAuth(AuthContext);

  const menuVariants = {
    hidden: { opacity: 0, scale: 0.95 },
    visible: { opacity: 1, scale: 1 },
  };

  const buttonVariants = {
    hidden: { x: 50, opacity: 0 },
    visible: { x: 0, opacity: 1 },
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuItemVariants = {
    hidden: { x: 100, opacity: 0 },
    visible: { x: 0, opacity: 1 },
  };

  const handleLogOut = () => {
    logout(user);
  };

  return (
    <motion.div initial="hidden" animate="visible" exit="hidden" variants={menuVariants}>
      <motion.div
        variants={buttonVariants}
        initial="hidden"
        animate="visible"
        transition={{ delay: 0.4 }}
      >
        <IconButton
          id="avatar-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          disableTouchRipple
          sx={{
            mr: isMobile ? 1 : 5,
            color: "black",
            width: "auto",
            height: "auto",
            "&:hover": {
              backgroundColor: "transparent",
            },
            "&:focus": {
              backgroundColor: "transparent",
            },
          }}
        >
          <AnimatePresence mode="wait">
            <motion.div
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.5 }}
              transition={{ duration: 0.3 }}
            >
              <Tooltip title="View Profile" arrow>
                <Avatar
                  alt="User Avatar"
                  src={`${process.env.PUBLIC_URL}/images/Male.png`}
                  sx={{ width: 50, height: 50 }}
                />
              </Tooltip>
            </motion.div>
          </AnimatePresence>
        </IconButton>
      </motion.div>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "avatar-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          fontSize: "1rem",
          "& .MuiPaper-root": {
            backgroundColor: "rgba(255, 255, 255, 1)",
            backdropFilter: "blur(5px)",
          },
        }}
        PaperProps={{
          sx: {
            mt: 1,
            overflow: "visible",
            "&::after": {
              content: '""',
              display: "block",
              position: "absolute",
              top: "-10px",
              right: isMobile ? 20 : 28,
              borderLeft: "10px solid transparent",
              borderRight: "10px solid transparent",
              borderBottom: "10px solid rgba(255, 255, 255, 1)", // Match the arrow color with the Paper's background
            },
          },
        }}
      >
        {user ? (
          <motion.div
            variants={menuItemVariants}
            initial="hidden"
            animate="visible"
            transition={{ staggerChildren: 0.1 }}
          >
            <MenuItem
              key="username"
              sx={{
                fontWeight: "bold",
                color: "black",
                mt: -1,
                fontSize: "1.2rem", // Increase font size
                padding: "10px 15px", // Increase padding
              }}
              divider
            >
              <ListItemIcon>
                <PersonIcon fontSize="medium" sx={{ color: "black" }} />
              </ListItemIcon>
              <span
                className="username"
                style={{
                  maxWidth: "140px", // Set a maximum width for the username
                  overflow: "hidden", // Hide overflowed content
                  textOverflow: "ellipsis", // Add ellipsis to overflowed content
                  whiteSpace: "nowrap", // Prevent text wrapping
                }}
              >
                {user ? user.profileName : "Guest"}
              </span>
            </MenuItem>
            <Link
              to="/"
              onClick={handleLogOut}
              style={{
                textDecoration: "none",
                color: "inherit",
                fontWeight: "bold",
              }}
              key="logout"
            >
              <MenuItem
                sx={{
                  fontWeight: "bold",
                  color: "black",
                  mb: -1,
                  fontSize: "1.2rem", // Increase font size
                  padding: "10px 15px", // Increase padding
                }}
              >
                <ListItemIcon>
                  <LogoutIcon fontSize="medium" sx={{ color: "black" }} />
                </ListItemIcon>
                Logout &nbsp;&nbsp;
              </MenuItem>
            </Link>
          </motion.div>
        ) : (
          <motion.div
            variants={menuItemVariants}
            initial="hidden"
            animate="visible"
            transition={{ staggerChildren: 0.1 }}
          >
            <Link
              to="/admin/login"
              onClick={handleClose}
              style={{
                textDecoration: "none",
                color: "inherit",
              }}
              key="login"
            >
              <MenuItem
                sx={{
                  fontWeight: "bold",
                  color: "black",
                  my: -1,
                  fontSize: "1.2rem", // Increase font size
                  padding: "10px 15px", // Increase padding
                }}
              >
                <ListItemIcon>
                  <LoginIcon fontSize="medium" sx={{ color: "black" }} />
                </ListItemIcon>
                Login &nbsp;&nbsp;
              </MenuItem>
            </Link>
          </motion.div>
        )}
      </Menu>
    </motion.div>
  );
};

export default Header;

import React, { useReducer } from "react";
import { motion } from "framer-motion";
import SideBar from "../SideBar";
import DoctorDetails from "./DoctorDetails/DoctorDetails";
import UpcomingAppointments from "./UpcomingAppointments/UpcomingAppointments";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { Grid, Box } from "@mui/material";
import Patients from "./Patients/Patients";
import Header from "../Header";

const DemoPaper = styled(Paper)(({ theme, isMobile }) => ({
  width: isMobile ? "80%" : "90%",
  height: isMobile ? "100vh" : "65vh",
  margin: isMobile ? "auto" : undefined,
  marginTop: "7rem",
  padding: theme.spacing(2),
  ...theme.typography.body2,
  textAlign: "center",
  flexDirection: "column",
  justifyContent: "space-between",
  position: "relative",
  "& > div": {
    maxHeight: "100%",
    paddingRight: theme.spacing(1),
  },
  backgroundColor: "rgba(255, 255, 255, 0.9)",
}));

const initialState = {
  component: "Doctor Details",
  isMobileDrawerOpen: false,
  selectedOption: "Doctor Details",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "set_component":
      return { ...state, component: action.payload };
    case "set_is_mobile_drawer_open":
      return { ...state, isMobileDrawerOpen: action.payload };
    case "set_selected_option":
      return { ...state, selectedOption: action.payload };
    default:
      return state;
  }
};

const Doctor = ({ isMobile }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const options = [
    { name: "Doctor Details" },
    { name: "Patients" },
    { name: "Upcoming Appointments" },
  ];

  const containerStyle = {
    marginLeft: isMobile ? undefined : "25%",
    marginBottom: "5%",
    transition: "margin 0.5s",
    zIndex: 3,
  };

  return (
    <Grid container style={{ margin: 0, padding: 0, width: "100%", height: "100vh" }}>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/Screen.jpg)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "100vh",
          margin: 0,
          padding: 0,
          width: "100%",
          position: "absolute",
          top: 0,
          left: 0,
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
            justifyContent: "flex-end",
            zIndex: 2,
          }}
        >
          <Header />
        </Box>
        <SideBar state={state} dispatch={dispatch} options={options} isMobile={isMobile} />
        <div style={containerStyle}>
          <DemoPaper square={false} elevation={24} isMobile={isMobile}>
            {state.component === "Doctor Details" && <DoctorDetails isMobile={isMobile} />}
            {state.component === "Patients" && <Patients />}
            {state.component === "upcoming appointments" && (
              <UpcomingAppointments isMobile={isMobile} />
            )}
          </DemoPaper>
        </div>
      </motion.div>
    </Grid>
  );
};

export default Doctor;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import PatientsTable from "./PatientsTable";
import PatientDetails from "./PatientDetails";
import axios from "axios";
import { DNA } from "react-loader-spinner";
import { useScroll } from "../../../ScrollContext";
import { DemoPaper } from "../DemoPaper";
import { Breadcrumbs, Stack } from "@mui/material";

const Patients = ({ isMobile }) => {
  const [patients, setPatients] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [patientDetail, setPatientDetail] = useState("patient details");
  const [patientLabel, setPatientLabel] = useState(["Patients"]);
  const [showLoading, setShowLoading] = useState(true);
  const { scrollToTop } = useScroll();

  useEffect(() => {
    scrollToTop();
  }, []);

  const containerStyle = {
    marginLeft: isMobile ? "5%" : "25%",
    marginBottom: isMobile ? "15%" : "3%",
    marginRight: "5%",
    marginTop: isMobile ? "25%" : "5%",
    transition: "margin 0.5s",
    zIndex: 3,
  };

  const columns = [
    { field: "id", headerName: "ID", width: 120, headerClassName: "custom-header" },
    {
      field: "Name",
      headerName: " Name",
      width: 150,
      headerClassName: "custom-header",
      flex: isMobile ? undefined : 1,
      renderCell: (params) => (
        <div
          style={{ cursor: "pointer", textDecoration: "underline", color: "black" }}
          onClick={() => {
            console.log("Name clicked:", params.row.name);
            setSelectedPatient(params.row);
            setPatientLabel([...patientLabel, params.row.name]);
            setPatientDetail("selected patient");
            setPatientLabel([...patientLabel, params.row.name]);
          }}
        >
          {params.row.name}
        </div>
      ),
    },
    {
      field: "lastVisit",
      headerName: "Last Visit",
      headerClassName: "custom-header",
      width: 130,
      flex: isMobile ? undefined : 1,
    },

    {
      field: "upcomingAppointment",
      headerName: "Upcoming Appointments",
      width: 200, // Adjust width as needed
      headerClassName: "custom-header",
      flex: isMobile ? undefined : 1,
      renderCell: (params) => {
        if (params.row.appointments && params.row.appointments.length > 0) {
          const { date, startTime } = params.row.appointments[0];
          const formattedDate = new Date(date).toISOString().split("T")[0]; // Format date as YYYY-MM-DD
          return <span> {`${formattedDate} at ${startTime}`} </span>;
        } else {
          return <span>No upcoming appointments</span>;
        }
      },
    },
  ];

  useEffect(() => {
    const fetchPatients = async () => {
      try {
        setShowLoading(true); // Show loading spinner
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/patient/get/all/list`,
        );

        const patientsWithId = response.data.data.map((patient, index) => ({
          ...patient,
          id: index + 1, // Use index + 1 as the unique ID
          serialNumber: index + 1, // Use index + 1 as the serial number
        }));

        setPatients(patientsWithId);
        console.log("patientsWithId", patientsWithId);

        // Fetch appointments using patient emails
        setShowLoading(false); // Hide loading spinner after fetching data
      } catch (error) {
        console.error("Error fetching patients list:", error);
        setShowLoading(false); // Hide loading spinner if there's an error
      }
    };

    fetchPatients();
  }, []);

  const handleRowClick = (params) => {
    setSelectedPatient(params.row);
    setPatientLabel([...patientLabel, params.row.name]);
    setPatientDetail("selected patient");
  };

  const handleBreadcrumbClick = (index) => {
    setPatientLabel((prevPatientLabel) => prevPatientLabel.slice(0, index + 1));

    if (index === 0) {
      setSelectedPatient(null);
      setPatientDetail("patient details");
    } else {
      setSelectedPatient(patients[index - 1]);
      setPatientDetail("selected patient");
    }
  };

  return (
    <div style={containerStyle}>
      <Stack direction="column" spacing={2} alignItems="center">
        <DemoPaper
          height={isMobile ? "120vh" : "70vh"}
          square={false}
          elevation={24}
          isMobile={isMobile}
        >
          <Breadcrumbs aria-label="breadcrumb">
            {patientLabel &&
              patientLabel.map((label, index) => (
                <span
                  key={index}
                  onClick={() => handleBreadcrumbClick(index)}
                  style={{
                    cursor: "pointer",
                    fontSize: "20px",
                    fontWeight: "bold",
                    color: "black",
                    marginRight: "5px",
                  }}
                >
                  {index === 0 ? "Patients" : label}
                </span>
              ))}
          </Breadcrumbs>
          {showLoading ? (
            <DNA
              visible={true}
              height={isMobile ? "120" : "200"}
              width={isMobile ? "200" : "250"}
              color="#2B2A29"
              ariaLabel="dna-loading"
              wrapperStyle={{}}
              wrapperClass="dna-wrapper"
            />
          ) : patientDetail === "patient details" ? (
            <PatientsTable rows={patients} columns={columns} onRowClick={handleRowClick} />
          ) : (
            patientDetail === "selected patient" &&
            selectedPatient && (
              <PatientDetails
                patient={selectedPatient}
                isMobile={isMobile}
                appointments={selectedPatient.appointments}
              />
            )
          )}
        </DemoPaper>
      </Stack>
    </div>
  );
};

export default Patients;

const headerStyles = `
  .custom-header {
    background-color: #721F4B; /* Set the background color for the header */
    color: white; /* Set the text color for the header */
    font-weight: bold; /* Set the font weight for the header text */
    font-size: 16px; /* Set the font size for the header text */
  }
`;
// Inject the styles into the component
const styleElement = document.createElement("style");
styleElement.innerHTML = headerStyles;
document.head.appendChild(styleElement);

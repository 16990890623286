/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { IconButton, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { DataGrid } from "@mui/x-data-grid";

const DiagnosticDiseaseList = ({ diseases, onEdit, onDelete, isMobile }) => {
  const handleEdit = (id) => {
    onEdit(diseases.find((disease) => disease._id === id));
  };
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      responsive: true,
    },
    {
      field: "diseaseName",
      headerName: "Name",
      width: isMobile ? 250 : undefined,
      flex: isMobile ? undefined : 1,
      responsive: true,
    },
    {
      field: "diseaseCode",
      headerName: "Disease Code",
      width: isMobile ? 250 : undefined,
      flex: isMobile ? undefined : 1,
      responsive: true,
    },
    {
      field: "diseaseName_EN",
      headerName: "Sanskrit Name EN",
      width: isMobile ? 250 : undefined,
      flex: isMobile ? undefined : 1,
      responsive: true,
    },
    {
      field: "diseaseName_SN",
      headerName: "Sanskrit Name SN",
      width: isMobile ? 250 : undefined,
      flex: isMobile ? undefined : 1,
      responsive: true,
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      width: 150,
      renderCell: (params) => (
        <div>
          <Tooltip title="Edit Disease" arrow>
            <IconButton onClick={() => handleEdit(params.row._id)}>
              <EditIcon sx={{ color: "black" }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete Disease" arrow>
            <IconButton onClick={() => onDelete(params.row._id)}>
              <DeleteIcon sx={{ color: "black" }} />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  const diseasesWithIds =
    diseases &&
    diseases.map((disease, index) => ({
      id: index + 1,
      ...disease,
    }));

  return (
    <div style={{ width: "100%" }}>
      <DataGrid
        rows={diseasesWithIds || [{ id: "no data available", noData: true }]}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        sx={{
          backgroundColor: "rgba(240, 240, 240, 0.7)", // Set transparent background color
          "& .MuiDataGrid-columnHeader, & .MuiDataGrid-row": {
            fontSize: isMobile ? undefined : "1.1rem",
          },
          "& .MuiDataGrid-cell": {
            color: "black", // Set text color of cell data
          },
        }}
        components={{
          NoRowsOverlay: () => (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                width: "100%",
              }}
            ></div>
          ),
        }}
      />
    </div>
  );
};

export default DiagnosticDiseaseList;

/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { IconButton, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { DNA } from "react-loader-spinner";
import { motion } from "framer-motion";

const DoctorsTable = ({
  isMobile,
  onEdit,
  dispatch,
  state,
  doctorRefreshFlag,
  setDoctorRefreshFlag,
}) => {
  const [doctors, setDoctors] = useState([]);
  const [showLoading, setShowLoading] = useState(true);

  const handleDeleteDoctor = (item) => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}/api/doctor/delete/selected/${item.doctorId}`)
      .then((response) => {
        if (response.status === 200) {
          toast.success("Doctor Details Deleted Successfully");

          setDoctorRefreshFlag(!doctorRefreshFlag);
        }
      })
      .catch((error) => {
        console.error("Error deleting medicine delivery", error);
      });
  };

  // Handle deleting an item
  const handleOpenDeleteConfirmation = (data) => {
    Swal.fire({
      title: "Confirm Deletion",
      text: "Are you sure you want to delete this doctor details?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDeleteDoctor(data);
      } else {
      }
    });
  };

  useEffect(() => {
    const fetchDoctors = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/doctor/get/all`);
        if (response && response.status === 200 && response.data && response.data.data) {
          setDoctors(response.data.data);
          // console.log("fetchdoctorsDetails", response.data.data);
          setShowLoading(false);
          setDoctorRefreshFlag(false);
        } else {
          setShowLoading(false);
          toast.error("Error fetching doctor details, Please Try after sometime");
        }
      } catch (error) {
        if (error.response && [400, 404, 409, 500].includes(error.response.status)) {
          toast.error("Error fetching doctor details, Please Try after sometime");
        } else {
          toast.error("Error fetching doctor details, Please Contact developer");
        }
      }
    };
    fetchDoctors();
  }, [doctorRefreshFlag, setDoctorRefreshFlag]);

  const handleEditDoctor = (doctor) => {
    onEdit(doctor);
    // console.log("Selected Doctor edit:", selectedDoctor);
  };

  const handleDoctorClick = (doctor) => {
    // console.log("doctor", doctor.doctorId);
    dispatch({ type: "set_doctor_label", payload: [...state.doctorLabel, doctor.fullName] });
    dispatch({ type: "set_doctor", payload: "selected doctor" });
    dispatch({ type: "set_selected_doctor_id", payload: doctor.doctorId }); // Dispatch action to set selected doctor ID
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      headerClassName: "custom-header",
    },
    {
      field: "fullName",
      headerName: "Doctor Name",
      width: 150,
      headerClassName: "custom-header",
      renderCell: (params) => (
        <div
          style={{
            textDecoration: "underline",
            color: "black",
            cursor: "pointer",
          }}
          onClick={(e) => handleDoctorClick(params.row)}
        >
          {params.row.fullName}
        </div>
      ),
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      width: 170,
      headerClassName: "custom-header",
    },
    {
      field: "email",
      headerName: "Email",
      width: 170,
      headerClassName: "custom-header",
    },
    // {
    //   field: "state",
    //   headerName: "State",
    //   width: 130,
    //   headerClassName: "custom-header",
    // },
    // {
    //   field: "country",
    //   headerName: "Country",
    //   width: 110,
    //   headerClassName: "custom-header",
    // },
    {
      field: "noOfVists",
      headerName: "No Of Vists",
      width: 150,
      headerClassName: "custom-header",
    },
    {
      field: "lastVist",
      headerName: "Last Visit",
      width: 150,
      // flex: isMobile ? undefined : 1,
      headerClassName: "custom-header",
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      width: 163,
      headerClassName: "custom-header",
      renderCell: (params) => (
        <div>
          <Tooltip title="Edit Doctor" arrow>
            <IconButton>
              <EditIcon onClick={() => handleEditDoctor(params.row)} sx={{ color: "black" }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete Doctor" arrow>
            <IconButton onClick={() => handleOpenDeleteConfirmation(params.row)}>
              <DeleteIcon sx={{ color: "black" }} size={isMobile ? 24 : 50} />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  const rowsWithHandlers =
    doctors &&
    doctors.map((row, index) => ({
      id: index + 1,
      doctorId: row._id,
      fullName: row.fullName,
      phoneNumber: row.phoneNumber,
      email: row.email,
      state: row.state,
      country: row.country,
      handleEdit: handleEditDoctor,
      handleDelete: handleOpenDeleteConfirmation,
    }));

  return (
    <>
      {showLoading ? (
        <motion.div>
          <DNA
            visible={true}
            height={isMobile ? "120" : "200"}
            width={isMobile ? "200" : "250"}
            color="#2B2A29"
            ariaLabel="dna-loading"
            wrapperStyle={{}}
            wrapperClass="dna-wrapper"
          />
        </motion.div>
      ) : (
        <div style={{ width: "100%", marginTop: 30, height: "170%" }}>
          <DataGrid
            rows={rowsWithHandlers || [{ id: "no data available", noData: true }]}
            columns={columns}
            pageSize={5}
            autoHeight
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 6 },
              },
            }}
            sx={{
              backgroundColor: "white", // Set transparent background color
              "& .MuiDataGrid-columnHeader, & .MuiDataGrid-row": {
                fontSize: isMobile ? undefined : "1.2rem",
                borderRight: "1px solid #e0e0e0",
              },
              "& .MuiDataGrid-cell": {
                color: "black", // Set text color of cell data
              },
            }}
            pageSizeOptions={[6]}
          />
        </div>
      )}
    </>
  );
};

export default DoctorsTable;

const headerStyles = `
  .custom-header {
    background-color: #CE3C73; /* Set the background color for the header */
    color: white; /* Set the text color for the header */
    font-weight: bold; /* Set the font weight for the header text */
    font-size: 16px; /* Set the font size for the header text */
    border-bottom: 1px solid #e0e0e0;
    borderRight: "1px solid #e0e0e0",

  }
`;

// Inject the styles into the component
const styleElement = document.createElement("style");
styleElement.innerHTML = headerStyles;
document.head.appendChild(styleElement);

import axios from "axios";
import Cookies from "js-cookie";
import React, { createContext, useContext, useState } from "react";
import { toast } from "react-toastify";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  const login = (userData) => {
    setUser(userData);

    const jsonData = JSON.stringify(userData);

    // Encode the data in Base64
    const encodedData = btoa(jsonData);

    // Store the encoded data in localStorage
    localStorage.setItem("user", encodedData);
  };

  const logout = async (userData) => {
    if (userData) {
      await axios
        .post(`${process.env.REACT_APP_API_URL}/api/admin-login/post/logout`, userData)
        .then((response) => {
          if (response && response.status === 200) {
            toast.success("You're logged out. Remember, you're always welcome back!");

            const encodedData = localStorage.getItem("user");
            if (encodedData) {
              // Decode the Base64 string
              const decodedData = atob(encodedData);

              // Convert the JSON string back to an object
              JSON.parse(decodedData);

              // Remove user information from local storage
              localStorage.removeItem("user", decodedData);
            }

            Cookies.remove("token");

            setUser(null);
          } else {
            toast.error("An unexpected error occurred while logging out. Please try again later.");
          }
        })
        .catch((error) => {
          // Handle error
          console.error("Error clearing token cookie", error);
          toast.error("An error occurred while logging out. Please try again later.");
        });
    }
  };

  return <AuthContext.Provider value={{ user, login, logout }}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export default AuthContext;

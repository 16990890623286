/* eslint-disable react-hooks/exhaustive-deps */
import React, { useReducer, useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import CategoryGroupList from "./CategoryGroup/CategoryGroupList";
import CategoryList from "./Category/CategoryList";
import SubCategoryList from "./SubCategory/SubCategoryList";
import QuestionsList from "./Questions/QuestionsList";
import SubCategory from "./SubCategory/SubCategory";
import CategoryGroup from "./CategoryGroup/CategoryGroup";
import Category from "./Category/Category";
import Questions from "./Questions/Questions";
import AddQuestionsModal from "./AddQuestionsModal";
import AddEditCategoryModal from "./AddEditCategoryModal";
import { Stack, Typography } from "@mui/material";
import { DemoPaper } from "../DemoPaper";
import { useScroll } from "../../../ScrollContext";

const initialState = {
  categoryModal: false,
  categoryAction: "",
  selectedCategoryGroupItem: "",
  selectedCategoryItem: "",
  selectedSubCategoryItem: "",
  questionModal: false,
  selectedQuestionItem: "",
  modelType: "",
  modelName: "",
  categoryGroupRefreshFlag: false,
  categoryRefreshFlag: false,
  subCategoryRefreshFlag: false,
  questionsRefreshFlag: false,
  questions: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case "set_category_modal":
      return { ...state, categoryModal: action.payload };
    case "set_category_action":
      return { ...state, categoryAction: action.payload };
    case "set_selected_categorygroup_item":
      return { ...state, selectedCategoryGroupItem: action.payload };
    case "set_selected_category_item":
      return { ...state, selectedCategoryItem: action.payload };
    case "set_selected_subcategory_item":
      return { ...state, selectedSubCategoryItem: action.payload };
    case "set_question_modal":
      return { ...state, questionModal: action.payload };
    case "set_selected_question_item":
      return { ...state, selectedQuestionItem: action.payload };
    case "set_model_type":
      return { ...state, modelType: action.payload };
    case "set_model_name":
      return { ...state, modelName: action.payload };
    case "set_categorygroup_refresh_flag":
      return { ...state, categoryGroupRefreshFlag: action.payload };
    case "set_category_refresh_flag":
      return { ...state, categoryRefreshFlag: action.payload };
    case "set_subCategory_refresh_flag":
      return { ...state, subCategoryRefreshFlag: action.payload };
    case "set_questions_refresh_flag":
      return { ...state, questionsRefreshFlag: action.payload };
    case "set_questions":
      return { ...state, questions: action.payload };
    default:
      return state;
  }
};

const Categories = ({ isMobile }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { scrollToTop } = useScroll();

  useEffect(() => {
    scrollToTop();
  }, []);

  const handleAddItem = (data) => {
    switch (data) {
      case "categoryGroup":
        dispatch({
          type: "set_categorygroup_refresh_flag",
          payload: !state.categoryGroupRefreshFlag,
        });
        break;
      case "category":
        dispatch({
          type: "set_category_refresh_flag",
          payload: !state.categoryRefreshFlag,
        });
        break;
      case "subCategory":
        dispatch({
          type: "set_subCategory_refresh_flag",
          payload: !state.subCategoryRefreshFlag,
        });
        break;
      case "questions":
        dispatch({
          type: "set_questions_refresh_flag",
          payload: !state.questionsRefreshFlag,
        });
        break;
      default:
        throw new Error("Invalid category action");
    }
  };

  const containerStyle = {
    marginLeft: isMobile ? "5%" : "25%",
    marginBottom: isMobile ? "15%" : "3%",
    marginRight: "5%",
    marginTop: isMobile ? "25%" : "8%",
    transition: "margin 0.5s",
    zIndex: 3,
  };

  return (
    <div style={containerStyle}>
      <Stack direction="column" spacing={4} alignItems="center">
        <DemoPaper
          square={false}
          elevation={24}
          isMobile={isMobile}
          height={isMobile ? "50vh" : "40vh"}
        >
          <Typography
            sx={{
              fontSize: isMobile ? "1.7rem" : "2rem",
            }}
            align="left"
          >
            Category Group
          </Typography>
          <CategoryGroup
            state={state}
            dispatch={dispatch}
            isMobile={isMobile}
            onAddItem={handleAddItem}
          />
          <PerfectScrollbar options={{ wheelPropagation: false }}>
            <CategoryGroupList state={state} dispatch={dispatch} isMobile={isMobile} />
          </PerfectScrollbar>
        </DemoPaper>

        <DemoPaper
          square={false}
          elevation={24}
          isMobile={isMobile}
          height={isMobile ? "60vh" : "50vh"}
        >
          <Typography
            sx={{
              fontSize: isMobile ? "1.7rem" : "2rem",
            }}
            align="left"
          >
            Category
          </Typography>
          <Category
            state={state}
            dispatch={dispatch}
            isMobile={isMobile}
            onAddItem={handleAddItem}
          />
          <PerfectScrollbar options={{ wheelPropagation: false }}>
            <CategoryList state={state} dispatch={dispatch} isMobile={isMobile} />
          </PerfectScrollbar>
        </DemoPaper>

        <DemoPaper
          square={false}
          elevation={24}
          isMobile={isMobile}
          height={isMobile ? "90vh" : "60vh"}
        >
          <Typography
            sx={{
              fontSize: isMobile ? "1.7rem" : "2rem",
            }}
            align="left"
          >
            Sub Category
          </Typography>
          <SubCategory
            state={state}
            dispatch={dispatch}
            isMobile={isMobile}
            onAddItem={handleAddItem}
          />
          <PerfectScrollbar options={{ wheelPropagation: false }}>
            <SubCategoryList state={state} dispatch={dispatch} isMobile={isMobile} />
          </PerfectScrollbar>
        </DemoPaper>

        <DemoPaper
          square={false}
          elevation={24}
          isMobile={isMobile}
          height={isMobile ? "100vh" : "70vh"}
        >
          <Typography
            sx={{
              fontSize: isMobile ? "1.7rem" : "2rem",
            }}
            align="left"
          >
            Questions
          </Typography>
          <Questions
            state={state}
            dispatch={dispatch}
            isMobile={isMobile}
            onAddItem={handleAddItem}
          />
          <PerfectScrollbar options={{ wheelPropagation: false }}>
            <QuestionsList state={state} dispatch={dispatch} isMobile={isMobile} />
          </PerfectScrollbar>
        </DemoPaper>
      </Stack>

      {isMobile && <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
      <AddEditCategoryModal
        state={state}
        dispatch={dispatch}
        onAddItem={handleAddItem}
        isMobile={isMobile}
      />
      <AddQuestionsModal
        state={state}
        dispatch={dispatch}
        onAddItem={handleAddItem}
        isMobile={isMobile}
      />
    </div>
  );
};

export default Categories;

import React from "react";
import { DataGrid } from "@mui/x-data-grid";

const PatientsTable = ({ rows, columns, isMobile }) => {
  return (
    <div style={{ height: isMobile ? 250 : 500, width: "100%" }}>
      {rows && rows.length > 0 ? (
        <DataGrid
          rows={rows}
          columns={columns}
          sx={{
            backgroundColor: "white",
            "& .MuiDataGrid-columnHeader, & .MuiDataGrid-row": {
              fontSize: isMobile ? undefined : "1.2rem",
            },
            "& .MuiDataGrid-cell": {
              color: "black", // Set text color of cell data
            },
          }}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          disableColumnMenu={true}
        />
      ) : (
        <p>No data available</p>
      )}
    </div>
  );
};

export default PatientsTable;

import React from "react";
import Stack from "@mui/material/Stack";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, IconButton, Tooltip } from "@mui/material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import axios from "axios";

const Questions = ({ state, dispatch, isMobile, onAddItem }) => {
  const handleDelete = () => {
    const subCategoryId = state.selectedSubCategoryItem._id;
    const questionId = state.selectedQuestionItem._id;

    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/api/qna/delete/subcategory/question/${subCategoryId}/${questionId}`,
      )
      .then((response) => {
        if (response.status === 200) {
          toast.success(`Question Deleted Successfully`);
          onAddItem("questions");
        } else {
          toast.error("Error deleting deleting question, Please Try after sometime");
        }
      })
      .catch((error) => {
        console.error("Error deleting question", error);

        if (error.response && [400, 404, 409, 500].includes(error.response.status)) {
          switch (error.response.status) {
            case 400:
              toast.error("Bad request. Please check selected question");
              break;
            case 404:
              toast.error("Seleted question not found");
              break;
            case 409:
              toast.error("There is a conflict with the current state of the question");
              break;
            case 500:
              toast.error("Server error while deleting question, Please Try after sometime");
              break;
            default:
              toast.error("An error occurred while deleting question, Please Try after sometime");
          }
        } else {
          toast.error("Error deleting deleting question, Please Contact Developer");
        }
      });
  };

  const handleDeleteConfirmation = () => {
    Swal.fire({
      title: "Confirm Deletion",
      text: "Are you sure you want to remove this question?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete();
      } else {
        // example codes to clear up
      }
    });
  };

  const showToastNotification = (action) => {
    toast.warning(`Please select a question before ${action}.`);
  };

  return (
    <Stack direction="row" spacing={1}>
      <Box
        sx={{
          position: "absolute",
          top: 15,
          right: 20,
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-end",
          justifyContent: "flex-end",
          zIndex: 4,
        }}
      >
        <Tooltip title="Add Question" arrow>
          <IconButton
            edge="end"
            aria-label="add"
            onClick={() => {
              dispatch({ type: "set_question_modal", payload: true });
            }}
            sx={{ color: "black", mr: 1 }}
            size="large"
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Remove Question" arrow>
          <IconButton
            edge="end"
            aria-label="delete"
            onClick={() => {
              state.selectedQuestionItem
                ? handleDeleteConfirmation()
                : showToastNotification("deleting");
            }}
            sx={{ color: "black" }}
            size="large"
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
    </Stack>
  );
};

export default Questions;

/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { AuthProvider, useAuth } from "./AuthContext";
import AdminLogin from "./components/AdminAuth/AdminLogin";
import Admin from "./components/AdminComponent/Admin";
import { ToastContainer } from "react-toastify";
import { useMediaQuery } from "@mui/material";
import { ScrollProvider } from "./ScrollContext";
import DoctorLogin from "./components/DoctorAuth/DoctorLogin";
import Doctor from "./components/DoctorComponent/Doctor";
import LandingPage from "./components/LandingPage";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: "Montserrat", // Apply Montserrat as the global font
  },
});

const imagesToPreload = [
  "/images/Company-Logo.png",
  "/images/Male.png",
  "/images/Landscape-Image.jpeg",
  "/images/Portrait-Image.jpg",
  "/images/contact/Email.png",
  "/images/contact/Instagram.png",
  "/images/contact/WhatsApp.png",
  "/images/Screen.jpg",
  // Add all other images similarly
];

const preloadImages = () => {
  imagesToPreload.forEach((image) => {
    const img = new Image();
    img.src = image;
  });
};

const pageTransition = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};

function App() {
  return (
    <AuthProvider>
      <ScrollProvider>
        <ThemeProvider theme={theme}>
          <AppContent />
        </ThemeProvider>
      </ScrollProvider>
    </AuthProvider>
  );
}

function AppContent() {
  const { user, login } = useAuth();
  const location = useLocation();
  const scrollbarRef = useRef(null);
  const isMobile = useMediaQuery("(max-width: 800px)");

  const adminUser = process.env.REACT_APP_ADMIN_USER;

  useEffect(() => {
    preloadImages();
  }, [location]);

  useEffect(() => {
    if (scrollbarRef.current) {
      // Directly access the DOM node and set scrollTop to 0
      const container = scrollbarRef.current._container;
      if (container) {
        container.scrollTop = 0;
      }
    }
  }, [location]);

  useEffect(() => {
    const handleScrollToTop = () => {
      if (scrollbarRef.current) {
        // Accessing the PerfectScrollbar method or the container's DOM property
        const ps = scrollbarRef.current;
        if (ps && ps.scrollTop) {
          ps.scrollTop(0);
        } else if (ps && ps._container) {
          // Checking if _container is the correct reference
          ps._container.scrollTop = 0;
        }
      }
    };

    window.addEventListener("scrollToTop", handleScrollToTop);
    return () => window.removeEventListener("scrollToTop", handleScrollToTop);
  }, []);

  useEffect(() => {
    const encodedData = localStorage.getItem("user");
    if (encodedData) {
      const decodedData = atob(encodedData);

      const userData = JSON.parse(decodedData);

      login(userData);
    }
  }, []); // Empty dependency array ensures the effect runs only once on mount

  const isAdmin = user && adminUser.includes(user.profileName);

  const isLoggedIn = !!user;

  return (
    <PerfectScrollbar ref={scrollbarRef} style={{ width: "100%", height: "100vh" }}>
      <AnimatePresence>
        <Routes>
          {/* Welcome route */}
          <Route
            path="/"
            element={
              <motion.div {...pageTransition}>
                <LandingPage isMobile={isMobile} />
              </motion.div>
            }
          />

          <Route
            path="/admin/login"
            element={
              <motion.div {...pageTransition}>
                <AdminLogin isMobile={isMobile} />
              </motion.div>
            }
          />

          {isAdmin && (
            <Route
              path="/admin"
              element={
                <motion.div {...pageTransition}>
                  <Admin isMobile={isMobile} />
                </motion.div>
              }
            />
          )}

          {/* <Route
            path="/doctor/login"
            element={
              <motion.div {...pageTransition}>
                <DoctorLogin isMobile={isMobile} />
              </motion.div>
            }
          />

          {isLoggedIn && (
            <Route
              path="/doctor"
              element={
                <motion.div {...pageTransition}>
                  <Doctor isMobile={isMobile} />
                </motion.div>
              }
            />
          )} */}
        </Routes>

        <ToastContainer
          position={isMobile ? "top-center" : "top-right"}
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </AnimatePresence>
    </PerfectScrollbar>
  );
}

export default App;

import React from "react";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton, Tooltip, Box, Stack } from "@mui/material";
import Swal from "sweetalert2";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CategoryGroup = ({ state, dispatch, isMobile, onAddItem }) => {
  const handleDelete = () => {
    const categoryGroupId = state.selectedCategoryGroupItem._id;

    axios
      .delete(`${process.env.REACT_APP_API_URL}/api/qna/delete/categorygroup/${categoryGroupId}`)
      .then((response) => {
        if (response.status === 200) {
          toast.success(`Category Group Deleted Successfully`);

          dispatch({ type: "set_selected_categorygroup_item", payload: "" });
          dispatch({ type: "set_selected_category_item", payload: "" });
          dispatch({ type: "set_selected_subcategory_item", payload: "" });
          dispatch({ type: "set_selected_question_item", payload: "" });
          onAddItem("categoryGroup");
        } else {
          toast.error("Error deleting category group, Please Try after sometime");
        }
      })
      .catch((error) => {
        console.error("Error deleting category group", error);

        if (error.response && [400, 404, 409, 500].includes(error.response.status)) {
          switch (error.response.status) {
            case 400:
              toast.error("Bad request. Please check selected category group");
              break;
            case 404:
              toast.error("Seleted category group not found");
              break;
            case 409:
              toast.error("There is a conflict with the current state of the category group");
              break;
            case 500:
              toast.error("Server error while deleting category group, Please Try after sometime");
              break;
            default:
              toast.error(
                "An error occurred while deleting category group, Please Try after sometime",
              );
          }
        } else {
          toast.error("Error deleting category group, Please Contact Developer");
        }
      });
  };

  const handleDeleteConfirmation = () => {
    Swal.fire({
      title: "Confirm Deletion",
      text: "Are you sure you want to delete this category group?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete();
      } else {
        // extra code
      }
    });
  };

  const dispatchEditCategoryGroup = () => {
    dispatch({ type: "set_category_modal", payload: true });
    dispatch({ type: "set_category_action", payload: "edit" });
    dispatch({
      type: "set_model_type",
      payload: "categoryGroup",
    });
    dispatch({
      type: "set_model_name",
      payload: "Category Group",
    });
  };

  const showToastNotification = (action) => {
    toast.warning(`Please select a category group before ${action}.`);
  };

  return (
    <Stack direction="row" spacing={1} isMobile={isMobile}>
      <Box
        sx={{
          position: "absolute",
          top: isMobile ? 50 : 15,
          right: 20,
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-end",
          justifyContent: "flex-end",
          zIndex: 4,
        }}
      >
        <Tooltip title="Add Category Group" arrow>
          <IconButton
            edge="end"
            aria-label="add"
            onClick={() => {
              dispatch({ type: "set_category_modal", payload: true });
              dispatch({ type: "set_category_action", payload: "add" });
              dispatch({
                type: "set_model_type",
                payload: "categoryGroup",
              });
              dispatch({
                type: "set_model_name",
                payload: "Category Group",
              });
            }}
            sx={{ color: "black", mr: isMobile ? 0 : 1 }}
            size="large"
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Edit Category Group" arrow>
          <IconButton
            edge="end"
            aria-label="edit"
            onClick={() => {
              state.selectedCategoryGroupItem
                ? dispatchEditCategoryGroup()
                : showToastNotification("editing");
            }}
            sx={{ color: "black", mr: isMobile ? 0 : 1 }}
            size="large"
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete Category Group" arrow>
          <IconButton
            edge="end"
            aria-label="delete"
            onClick={() => {
              state.selectedCategoryGroupItem
                ? handleDeleteConfirmation()
                : showToastNotification("deleting");
            }}
            sx={{ color: "black" }}
            size="large"
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
    </Stack>
  );
};

export default CategoryGroup;

/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import axios from "axios";
import { DemoPaper } from "../DemoPaper";
import UserTable from "./UserTable";
import { toast } from "react-toastify";
import { Stack, Typography } from "@mui/material";

const Users = ({ isMobile }) => {
  const containerStyle = {
    marginLeft: isMobile ? "5%" : "25%",
    marginBottom: isMobile ? "15%" : "3%",
    marginRight: "5%",
    marginTop: isMobile ? "25%" : "5%",
    transition: "margin 0.5s",
    zIndex: 3,
  };

  const [usersList, setUsersList] = useState([]);
  const [showLoading, setShowLoading] = useState(false);

  const fetchUsers = async () => {
    try {
      setShowLoading(true);
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/users/get/all/list`);

      if (response && response.status === 200 && response.data && response.data.data) {
        const usersWithId = response.data.data.map((user, index) => ({
          ...user,
          id: index + 1,
        }));

        setUsersList(usersWithId);
      } else {
        setUsersList(null);
      }
    } catch (error) {
      setUsersList(null);
      console.error("Error fetching data:", error);

      if (error.response && [400, 404, 409, 500].includes(error.response.status)) {
        switch (error.response.status) {
          case 404:
            toast.error("User's details not found");
            break;
          case 500:
            toast.error(
              "An error occurred while fetching user's details, Please Try after sometime",
            );
            break;
          default:
            toast.error(
              "An error occurred while fetching user's details, Please Try after sometime",
            );
        }
      } else {
        toast.error("An error occurred while fetching user's details, Please Contact Developer");
      }
    }
    setShowLoading(false);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <Stack direction="column" alignItems="center" style={containerStyle}>
      <DemoPaper
        height={isMobile ? "120vh" : "80vh"}
        square={false}
        elevation={24}
        isMobile={isMobile}
      >
        <Typography
          variant={isMobile ? "h6" : "h5"}
          align="center"
          sx={{
            my: isMobile ? undefined : "2%",
            textTransform: "uppercase",
            fontWeight: "bold",
          }}
        >
          Access the 7D Living portal at 7dliving.com by logging in as a user
        </Typography>
        <UserTable isMobile={isMobile} users={usersList} showLoading={showLoading} />
      </DemoPaper>
    </Stack>
  );
};

export default Users;

import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { DNA } from "react-loader-spinner";
import { motion } from "framer-motion";
import { Typography } from "@mui/material";

const UserTable = ({ isMobile, users, showLoading }) => {
  const handleOpenUserPage = (userId) => {
    const userApiUrl = process.env.REACT_APP_USER_API;

    if (!userApiUrl) {
      console.error("REACT_APP_USER_API is not defined");
      return;
    }

    window.open(`${userApiUrl}/${userId}`, "_blank");
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 100,
      responsive: true,
      headerClassName: "custom-header-cell",
    },
    {
      field: "name",
      headerName: "Name",
      width: isMobile ? 250 : undefined,
      flex: isMobile ? undefined : 1,
      responsive: true,
      headerClassName: "custom-header-cell",
      renderCell: (params) => (
        <div
          style={{ cursor: "pointer", textDecoration: "underline", color: "black" }}
          onClick={() => {
            handleOpenUserPage(params.row.userId);
          }}
        >
          {params.row.name}
        </div>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      width: isMobile ? 250 : undefined,
      flex: isMobile ? undefined : 1,
      responsive: true,
      headerClassName: "custom-header-cell",
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      width: isMobile ? 200 : undefined,
      flex: isMobile ? undefined : 1,
      responsive: true,
      headerClassName: "custom-header-cell",
    },
    {
      field: "gender",
      headerName: "Gender",
      width: isMobile ? 120 : 140,
      responsive: true,
      headerClassName: "custom-header-cell",
    },
    {
      field: "age",
      headerName: "Age",
      width: isMobile ? 80 : 130,
      responsive: true,
      headerClassName: "custom-header-cell",
    },
  ];

  const rowsWithHandlers =
    users &&
    users.map((row, index) => ({
      id: index + 1,
      userId: row._id,
      name: row.name,
      email: row.email,
      phoneNumber: row.mobileNumber,
      gender: row.gender,
      age: row.age,
    }));

  return (
    <>
      {showLoading ? (
        <motion.div>
          <DNA
            visible={true}
            height={isMobile ? "120" : "200"}
            width={isMobile ? "200" : "250"}
            color="#2B2A29"
            ariaLabel="dna-loading"
            wrapperStyle={{}}
            wrapperClass="dna-wrapper"
          />
        </motion.div>
      ) : (
        <div style={{ width: "100%" }}>
          {users && users.length > 0 ? (
            <DataGrid
              rows={rowsWithHandlers}
              columns={columns}
              autoHeight
              sx={{
                backgroundColor: "rgba(255, 255, 255, 1)",
                fontSize: isMobile ? "1rem" : "1.2rem",
                fontWeight: 600,
              }}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: isMobile ? 15 : 6 },
                },
              }}
            />
          ) : (
            <Typography
              variant={isMobile ? "body1" : "body2"}
              align="center"
              sx={{
                mt: "3%",
                textTransform: "uppercase",
              }}
            >
              No User's Details Found
            </Typography>
          )}
        </div>
      )}
    </>
  );
};

export default UserTable;

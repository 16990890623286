import React from "react";
import { IconButton, Grid, Tooltip, Divider, Drawer } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SideBarList from "./SideBarList";
import { GiHamburgerMenu } from "react-icons/gi";
import { useNavigate } from "react-router-dom";

const SideBar = ({ state, dispatch, options, isMobile }) => {
  const navigate = useNavigate();

  const toggleMobileDrawer = () => {
    dispatch({
      type: "set_is_mobile_drawer_open",
      payload: !state.isMobileDrawerOpen,
    });
  };

  const handleImgClick = () => {
    navigate("/");
  };

  return (
    <>
      {/* Mobile Drawer */}
      {isMobile ? (
        <>
          {isMobile && !state.isMobileDrawerOpen && (
            <IconButton
              sx={{
                position: "fixed",
                top: 10,
                left: 10,
                zIndex: 1,
              }}
              onClick={toggleMobileDrawer}
            >
              <GiHamburgerMenu size={30} color="white" />
            </IconButton>
          )}
          <Drawer
            anchor="left"
            open={state.isMobileDrawerOpen}
            onClose={toggleMobileDrawer}
            variant="temporary"
            sx={{ zIndex: 1 }}
          >
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <Tooltip title="Navigate to Main Page" arrow>
                <img
                  onClick={handleImgClick}
                  src={`${process.env.PUBLIC_URL}/images/Logo.png`}
                  alt="Logo"
                  style={{ width: 120, height: 120, cursor: "pointer" }}
                />
              </Tooltip>
            </Grid>

            <Divider sx={{ bgcolor: "rgba(0, 0, 0, 1)" }} />
            <SideBarList state={state} dispatch={dispatch} options={options} isMobile={isMobile} />

            <IconButton
              sx={{ position: "absolute", top: 5, right: 5 }}
              onClick={toggleMobileDrawer}
            >
              <CloseIcon />
            </IconButton>
          </Drawer>
        </>
      ) : (
        //PC view
        <Drawer
          variant="permanent"
          anchor="left"
          PaperProps={{
            style: { width: "20%", backgroundColor: "rgba(255, 255, 255, 0.9)" },
          }}
          sx={{
            flexShrink: 0,
            mx: 100,
          }}
        >
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Tooltip title="Navigate to Main Page" arrow>
              <img
                src={`${process.env.PUBLIC_URL}/images/Logo.png`}
                alt="Logo"
                style={{ width: 100, height: 100, margin: 5, cursor: "pointer" }}
                onClick={handleImgClick}
              />
            </Tooltip>
          </Grid>
          <Divider sx={{ bgcolor: "rgba(0, 0, 0, 1)" }} />

          <SideBarList state={state} dispatch={dispatch} options={options} />
        </Drawer>
      )}
    </>
  );
};

export default SideBar;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import axios from "axios";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddQuestionsList from "./Questions/AddQuestionsList";
import UpdateQuestionList from "./Questions/UpdateQuestionList";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Divider } from "@mui/material";
import { DNA } from "react-loader-spinner";

const AddEditQuestionsModal = ({ state, dispatch, isMobile }) => {
  const questionFileInputRef = useRef(null); // Create a ref for questionImage input
  const answerFileInputRef = useRef(null);
  const scrollbarRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const [answerRefs, setAnswerRefs] = useState([]);

  const resetScroll = () => {
    if (scrollbarRef.current && scrollbarRef.current._container) {
      scrollbarRef.current._container.scrollTop = 0;
    }
  };

  useEffect(() => {
    // Adjust the refs array based on the number of answers
    setAnswerRefs(state.questions.answers.map((_, i) => answerRefs[i] || React.createRef()));
  }, [state.questions.answers.length]);

  const handleClearState = () => {
    dispatch({ type: "set_question_modal", payload: false });
    dispatch({
      type: "set_questions",
      payload: {
        prompt: "",
        questiontext: "",
        questionimage: "",
        answers: [
          {
            answer: "",
            answerimage: "",
            results: [{ result: "", value: "" }],
          },
        ],
      },
    });
    dispatch({ type: "set_question_image_url", payload: "" });
    dispatch({ type: "set_answer_image_url", payload: null });
    dispatch({ type: "set_question_action", payload: "add" });

    if (questionFileInputRef.current) {
      questionFileInputRef.current.value = "";
    }

    if (answerFileInputRef.current) {
      answerFileInputRef.current.value = "";
    }
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    handleClearState();
  };

  const handleAdd = async () => {
    setLoading(true);

    try {
      const isInvalidData =
        !state.questions.questiontext.trim() ||
        !state.questions.answers.every(
          (answer) =>
            !!answer.answer.trim() &&
            answer.results.every((result) => !!result.result.trim() && !!result.value.trim()),
        );

      if (isInvalidData) {
        toast.error("Please fill in all fields.");
        setLoading(false);
        return;
      }

      const jsonData = {
        prompt: state.questions.prompt,
        questiontext: state.questions.questiontext,
        questionimage: state.questions.questionimage ? state.questions.questionimage : null,
        answers: state.questions.answers.map((answer) => {
          const answerImageName = answer.answerimage ? answer.answerimage.name : null;
          return {
            answer: answer.answer,
            answerimage: answerImageName, // Assign the file name
            results: answer.results,
          };
        }),
      };

      const formData = new FormData();
      formData.append("jsonData", JSON.stringify(jsonData));

      // Append questionimage and answerimage files to formData
      if (state.questions.questionimage) {
        formData.append("questionimage", state.questions.questionimage);
      }
      state.questions.answers.forEach((answer) => {
        if (answer.answerimage) {
          formData.append("answerimage", answer.answerimage);
        }
      });

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/qna/post/each/category/qna/${state.selectedCategoryItem._id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );

      if (response && response.status === 201) {
        dispatch({
          type: "set_questions_refresh_flag",
          payload: !state.questionsRefreshFlag,
        });
        toast.success("Data added successfully!");
        handleClearState();
      } else {
        toast.error("Error sending data to the backend");
      }
      setLoading(false);
    } catch (error) {
      console.error("Error sending data to the backend:", error);

      if (error.response && [400, 404, 409, 500].includes(error.response.status)) {
        switch (error.response.status) {
          case 400:
            toast.error("Bad request. Please check your inputs");
            break;
          case 404:
            toast.error("Question data not found");
            break;
          case 409:
            toast.error("There is a conflict with the current state of the question data");
            break;
          case 500:
            toast.error("Server error while adding question data, Please Try after sometime");
            break;
          default:
            toast.error("An error occurred while adding question data, Please Try after sometime");
        }
      } else {
        toast.error("Error sending data to the backend,  Please Contact Developer");
      }
      setLoading(false);
    }
  };

  const handleEdit = async () => {
    setLoading(true);

    try {
      const isInvalidData =
        !state.questions.prompt.trim() ||
        !state.questions.questiontext.trim() ||
        !state.questions.answers.every(
          (answer) =>
            !!answer.answer.trim() &&
            answer.results.every((result) => !!result.result.trim() && !!result.value.trim()),
        );

      if (isInvalidData) {
        toast.error("Please fill in all fields.");
        setLoading(false);
        return;
      }

      const jsonData = {
        prompt: state.questions.prompt,
        questiontext: state.questions.questiontext,
        questionimage: state.questions.questionimage
          ? state.questions.questionimage
          : state.questionImageURL
            ? state.questionImageURL.substring(state.questionImageURL.lastIndexOf("/") + 1)
            : null,
        answers: state.questions.answers.map((answer, index) => {
          const answerImageName = answer.answerimage
            ? answer.answerimage.name
            : state.answerImageURL[index]
              ? state.answerImageURL[index].substring(
                  state.answerImageURL[index].lastIndexOf("/") + 1,
                )
              : null;
          return {
            answer: answer.answer,
            answerimage: answerImageName, // Assign the file name
            results: answer.results,
          };
        }),
      };

      console.log("jsonData", jsonData);

      const formData = new FormData();
      formData.append("jsonData", JSON.stringify(jsonData));

      // Append questionimage and answerimage files to formData
      if (state.questions.questionimage) {
        formData.append("questionimage", state.questions.questionimage);
      }
      state.questions.answers.forEach((answer, index) => {
        if (answer.answerimage) {
          formData.append("answerimage", answer.answerimage);
        }
      });

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/qna/update/each/question/${state.selectedQuestionItem._id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );

      if (response && response.status === 200) {
        toast.success("Data updated successfully!");
        dispatch({
          type: "set_questions_refresh_flag",
          payload: !state.questionsRefreshFlag,
        });
        handleClearState();
      } else {
        toast.error("Error sending data to the backend");
      }
      setLoading(false);
    } catch (error) {
      console.error("Error sending data to the backend:", error);

      if (error.response && [400, 404, 409, 500].includes(error.response.status)) {
        switch (error.response.status) {
          case 400:
            toast.error("Bad request. Please check your inputs");
            break;
          case 404:
            toast.error("Question data not found");
            break;
          case 409:
            toast.error("There is a conflict with the current state of the question data");
            break;
          case 500:
            toast.error("Server error while updating question data, Please Try after sometime");
            break;
          default:
            toast.error(
              "An error occurred while updating question data, Please Try after sometime",
            );
        }
      } else {
        toast.error("Failed to update data!, Please Contact Developer");
      }

      setLoading(false);
    }
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={state.questionModal}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle fontSize="1.6rem">
        {state.questionAction === "add" ? `Add New Question` : "Edit Question"}
      </DialogTitle>

      <Divider sx={{ bgcolor: "black" }} />

      <PerfectScrollbar ref={scrollbarRef}>
        <DialogContent>
          {loading ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <DNA
                visible={true}
                height={isMobile ? "120" : "200"}
                width={isMobile ? "200" : "250"}
                color="#2B2A29"
                ariaLabel="dna-loading"
                wrapperStyle={{}}
                wrapperClass="dna-wrapper"
              />
            </div>
          ) : state.questionAction === "add" ? (
            <AddQuestionsList
              state={state}
              dispatch={dispatch}
              questionFileInputRef={questionFileInputRef}
              answerFileInputRefs={answerRefs}
            />
          ) : (
            <UpdateQuestionList
              state={state}
              dispatch={dispatch}
              questionFileInputRef={questionFileInputRef}
              answerFileInputRefs={answerRefs}
              resetScroll={resetScroll}
            />
          )}
        </DialogContent>
      </PerfectScrollbar>

      <Divider sx={{ bgcolor: "black" }} />

      <DialogActions>
        <Button
          variant="outlined"
          color="primary"
          onClick={state.questionAction === "add" ? handleAdd : handleEdit}
          startIcon={state.questionAction === "add" ? <SaveIcon /> : <BorderColorIcon />}
        >
          {state.questionAction === "add" ? "Add" : "Edit"}
        </Button>
        <Button variant="outlined" color="error" onClick={handleClose} startIcon={<CloseIcon />}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddEditQuestionsModal;

import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  Button,
  TextField,
  DialogActions,
  Divider,
  DialogContent,
} from "@mui/material";
import dayjs from "dayjs";

const AddHolidays = ({ open, handleClose, handleSave, selectedHoliday, handleUpdate }) => {
  const [holidayName, setHolidayName] = useState("");
  const [date, setDate] = useState("");

  useEffect(() => {
    if (selectedHoliday) {
      setHolidayName(selectedHoliday.holidayName);

      const formattedDate = dayjs(selectedHoliday.date).format("YYYY-MM-DD");

      setDate(formattedDate);
    } else {
      setHolidayName("");
      setDate("");
    }
  }, [selectedHoliday]);

  const handleNameChange = (event) => {
    const { value } = event.target;
    setHolidayName(value);
  };

  const handleDateChange = (event) => {
    console.log("handleDateChange called");
    const { value } = event.target;
    // console.log("date", value);
    setDate(value);
  };

  const handleSaveClick = () => {
    if (holidayName && date) {
      const formattedDate = dayjs(date).format("YYYY-MM-DD");

      console.log("formattedDate", formattedDate);
      if (selectedHoliday) {
        handleUpdate({ ...selectedHoliday, holidayName: holidayName, date: formattedDate });
        console.log("updated holiday", Response.data);
      } else {
        handleSave({ holidayName: holidayName, date: formattedDate });
        console.log("created holiday", Response.data);
      }
      setHolidayName("");
      setDate("");
      handleClose();
      setHolidayName(null);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle
        sx={{
          fontSize: "1.6rem",
        }}
        align="left"
      >
        {selectedHoliday ? "Update Holiday" : "Add Holiday"}
      </DialogTitle>{" "}
      <Divider sx={{ bgcolor: "black" }} />
      <DialogContent>
        <TextField
          margin="dense"
          label="Holiday Name"
          type="text"
          fullWidth
          value={holidayName}
          onChange={handleNameChange}
          InputLabelProps={{
            style: { color: "black", fontWeight: "bold" },
          }}
          autoComplete="off"
          sx={{
            "& input": {
              color: "black",
            },
            "& .MuiOutlinedInput-root.Mui-focused": {
              borderColor: "black",
              "& fieldset": {
                borderColor: "black",
              },
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "black",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "black",
            },
          }}
        />
        <TextField
          margin="dense"
          type="date"
          fullWidth
          value={date}
          onChange={handleDateChange}
          InputLabelProps={{
            style: { color: "black", fontWeight: "bold" },
          }}
          autoComplete="off"
          sx={{
            "& input": {
              color: "black",
            },
            "& .MuiOutlinedInput-root.Mui-focused": {
              borderColor: "black",
              "& fieldset": {
                borderColor: "black",
              },
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "black",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "black",
            },
          }}
        />
      </DialogContent>
      <Divider sx={{ bgcolor: "black" }} />
      <DialogActions>
        <Button onClick={handleSaveClick}>{selectedHoliday ? "Update" : "Save"}</Button>{" "}
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddHolidays;

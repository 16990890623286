import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { DNA } from "react-loader-spinner";
import { motion } from "framer-motion";
import { Typography } from "@mui/material";

const VendorsTable = ({ isMobile, vendors, showLoading }) => {
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 80,
      responsive: true,
      headerClassName: "custom-header-cell",
    },
    {
      field: "name",
      headerName: "Name",
      width: isMobile ? 250 : undefined,
      flex: isMobile ? undefined : 1,
      responsive: true,
      headerClassName: "custom-header-cell",
    },
    {
      field: "address",
      headerName: "Address",
      width: isMobile ? 250 : undefined,
      flex: isMobile ? undefined : 1,
      responsive: true,
      headerClassName: "custom-header-cell",
    },
    {
      field: "contactNumber",
      headerName: "Contact Number",
      width: isMobile ? 200 : undefined,
      flex: isMobile ? undefined : 1,
      responsive: true,
      headerClassName: "custom-header-cell",
    },
    {
      field: "telephoneNumber",
      headerName: "Telephone Number",
      width: isMobile ? 200 : undefined,
      flex: isMobile ? undefined : 1,
      responsive: true,
      headerClassName: "custom-header-cell",
    },
    {
      field: "contactPerson",
      headerName: "Contact Person Name",
      width: isMobile ? 200 : undefined,
      flex: isMobile ? undefined : 1,
      responsive: true,
      headerClassName: "custom-header-cell",
    },
    {
      field: "email",
      headerName: "Email",
      width: isMobile ? 200 : undefined,
      flex: isMobile ? undefined : 1,
      responsive: true,
      headerClassName: "custom-header-cell",
    },
    {
      field: "panNo",
      headerName: "PAN Number",
      width: isMobile ? 200 : 150,
      responsive: true,
      headerClassName: "custom-header-cell",
    },
    {
      field: "gstNo",
      headerName: "GST Number",
      width: isMobile ? 200 : 150,
      responsive: true,
      headerClassName: "custom-header-cell",
    },
  ];

  const rowsWithHandlers =
    vendors &&
    vendors.map((row, index) => ({
      id: index + 1,
      vendorId: row._id,
      name: row.companyName,
      address: row.companyAddress,
      contactNumber: row.companyContactNumber,
      telephoneNumber: row.companyTelephoneNumber,
      contactPerson: row.companyContactPerson,
      email: row.companyEmail,
      panNo: row.companyPanNo,
      gstNo: row.companyGSTNo,
    }));

  return (
    <>
      {showLoading ? (
        <motion.div>
          <DNA
            visible={true}
            height={isMobile ? "120" : "200"}
            width={isMobile ? "200" : "250"}
            color="#2B2A29"
            ariaLabel="dna-loading"
            wrapperStyle={{}}
            wrapperClass="dna-wrapper"
          />
        </motion.div>
      ) : (
        <div style={{ width: "100%" }}>
          {vendors && vendors.length > 0 ? (
            <DataGrid
              rows={rowsWithHandlers}
              columns={columns}
              autoHeight
              sx={{
                backgroundColor: "rgba(255, 255, 255, 1)",
                fontWeight: 600,
              }}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: isMobile ? 15 : 10 },
                },
              }}
            />
          ) : (
            <Typography
              variant={isMobile ? "h6" : "h2"}
              align="center"
              sx={{
                mt: "3%",
                fontWeight: "bold",
              }}
            >
              No vendor's Details Found
            </Typography>
          )}
        </div>
      )}
    </>
  );
};

export default VendorsTable;

import React from "react";
import { TextField, Button, Grid } from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";

const MedicineText = ({
  selectedType,
  selectedDelivery,
  englishName,
  setEnglishName,
  sanskritName,
  setSanskritName,
  selectedId,
  refresh,
  action,
  refreshFlag,
  setRefreshFlag,
}) => {
  const handleAddOrUpdate = async () => {
    if (
      !(selectedType._id || selectedType.id) ||
      !(selectedDelivery._id || selectedDelivery.id) ||
      !englishName ||
      !sanskritName
    ) {
      toast.error("Please fill in all the medicine fields");
      return;
    }

    const requestData = {
      medicineDetail: {
        medicineTypeName: selectedType._id || selectedType.id,
        medicineDeliveryName: selectedDelivery._id || selectedDelivery.id,
        medicineName_EN: englishName,
        medicineName_SN: sanskritName,
      },
    };

    try {
      if (action === "add") {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/medicine/post/new`,
          requestData,
        );

        if (response && response.status === 201) {
          toast.success("Medicine Added Successfully");
          refresh();
          setRefreshFlag(!refreshFlag);
        } else {
          console.error("Failed to add new medicine");
        }
      } else if (action === "edit") {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/medicine/post/selected/${selectedId}`,
            requestData,
          );

          if (response.status === 200) {
            toast.success("Medicine Updated Successfully");
            refresh();
            setRefreshFlag(!refreshFlag);
          } else {
            console.error("Failed to update medicine");
          }
        } catch (error) {
          console.error("Error updating medicine", error);
        }
      }
    } catch (error) {
      console.error("Error adding/updating medicine", error);
    }
  };

  const handleCancel = () => {
    refresh();
  };

  return (
    <>
      <Grid item xs={12} md={6} lg={3} sm={2}>
        <TextField
          margin="dense"
          id="medicine name(e)"
          type="text"
          label="Medicine Name(E)"
          value={englishName}
          onChange={(e) => setEnglishName(e.target.value)}
          placeholder="English Name"
          InputLabelProps={{ style: { color: "white" } }}
          autoComplete="off"
          sx={{
            "& input": {
              color: "white",
            },
            "& .MuiOutlinedInput-root.Mui-focused": {
              borderColor: "white",
              "& fieldset": {
                borderColor: "white",
              },
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "white",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "white",
            },
          }}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6} lg={3} sm={2}>
        <TextField
          margin="dense"
          id="medicine name(s)"
          type="text"
          label="Medicine Name(S)"
          value={sanskritName}
          onChange={(e) => setSanskritName(e.target.value)}
          placeholder="Sanskrit Name"
          InputLabelProps={{ style: { color: "white" } }}
          autoComplete="off"
          sx={{
            "& input": {
              color: "white",
            },
            "& .MuiOutlinedInput-root.Mui-focused": {
              borderColor: "white",
              "& fieldset": {
                borderColor: "white",
              },
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "white",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "white",
            },
          }}
          fullWidth
        />
      </Grid>
      <Grid sx={{ ml: "auto", mt: 2 }}>
        <Button
          variant="contained"
          onClick={handleAddOrUpdate}
          sx={{
            ml: 2,
            backgroundColor: "white",
            color: "darkblue",
            ":hover": {
              backgroundColor: "#e0e0e0",
              color: "darkblue",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            },
          }}
          size="large"
        >
          {action === "add" ? "Add" : "Update"}
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={handleCancel}
          sx={{ ml: 2 }}
          size="large"
        >
          Cancel
        </Button>
      </Grid>
    </>
  );
};

export default MedicineText;

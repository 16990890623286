import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const DoctorsTimingsTable = ({ isMobile, timings, handleEditTiming, handleDelete }) => {
  const parseTimeString = (timeString) => {
    const [time, ampm] = timeString.split(" ");
    const [hours, minutes] = time.split(":");
    let hours24 = parseInt(hours, 10);
    if (ampm === "PM" && hours24 !== 12) {
      hours24 += 12;
    } else if (ampm === "AM" && hours24 === 12) {
      hours24 = 0;
    }
    return new Date(2000, 0, 1, hours24, parseInt(minutes, 10));
  };

  const columns = [
    { field: "id", headerName: "id", width: 200, headerClassName: "custom-header" },
    { field: "day", headerName: "Day", width: 200, headerClassName: "custom-header" },
    {
      field: "from",
      headerName: "Time From",
      width: 200,
      headerClassName: "custom-header",
      valueGetter: (params) => {
        if (params && params.row && params.row.from) {
          const from =
            typeof params.row.from === "string"
              ? parseTimeString(params.row.from)
              : params.row.from;
          return from.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
        }
        return "";
      },
    },
    {
      field: "to",
      headerName: "Time To",
      width: 200,
      headerClassName: "custom-header",
      valueGetter: (params) => {
        if (params && params.row && params.row.to) {
          const to =
            typeof params.row.to === "string" ? parseTimeString(params.row.to) : params.row.to;
          return to.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
        }
        return "";
      },
    },

    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      width: 200,
      headerClassName: "custom-header",
      renderCell: (params) => (
        <div>
          <Tooltip title="Edit timing" arrow>
            <IconButton aria-label="edit" onClick={() => handleEditTiming(params.row)}>
              <EditIcon sx={{ color: "black" }} />
            </IconButton>
          </Tooltip>

          <Tooltip title="Delete timing" arrow>
            <IconButton
              edge="end"
              aria-label="delete"
              size="large"
              onClick={() => handleDelete(params.row.timeId)}
            >
              <DeleteIcon sx={{ color: "black" }} />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  const rows =
    timings &&
    timings.map((timing, index) => ({
      timeId: timing._id,
      id: index + 1,
      day: timing.day,
      from: timing.from,
      to: timing.to,
    }));

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        height: 350,
        width: "100%",
        // margin: "auto",
      }}
    >
      <DataGrid
        rows={rows || [{ id: "no timings available", noData: true }]}
        columns={columns}
        initialState={
          {
            // pagination: {
            //   paginationModel: { page: 0, pageSize: 5 },
            // },
          }
        }
        sx={{
          backgroundColor: "white", // Set transparent background color
          "& .MuiDataGrid-columnHeader, & .MuiDataGrid-row": {
            fontSize: isMobile ? undefined : "1.2rem",
          },
          "& .MuiDataGrid-cell": {
            color: "black", // Set text color of cell data
          },
        }}
        pageSizeOptions={[6]}
        rowsPerPageOptions={[5, 10, 20]}
        checkboxSelection={false}
        disableSelectionOnClick
      />
    </div>
  );
};

export default DoctorsTimingsTable;

const headerStyles = `
  .custom-header {
    background-color: #CE3C73; /* Set the background color for the header */
    color: white; /* Set the text color for the header */
    font-weight: bold; /* Set the font weight for the header text */
    font-size: 16px; /* Set the font size for the header text */
  }
`;

// Inject the styles into the component
const styleElement = document.createElement("style");
styleElement.innerHTML = headerStyles;
document.head.appendChild(styleElement);

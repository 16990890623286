import React from "react";
import {
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Grid,
  TextField,
  InputAdornment,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import { motion } from "framer-motion";
import axios from "axios";
import { toast } from "react-toastify";
import { Email } from "@mui/icons-material";
import PersonIcon from "@mui/icons-material/Person";
import BusinessIcon from "@mui/icons-material/Business";
import CallIcon from "@mui/icons-material/Call";
import PhonelinkRingIcon from "@mui/icons-material/PhonelinkRing";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import PaymentsIcon from "@mui/icons-material/Payments";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const AddNewVendors = ({
  isOpen,
  modalClose,
  vendorData,
  setVendorData,
  loading,
  setLoading,
  isMobile,
}) => {
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmitVendor = async () => {
    if (
      !vendorData?.companyName ||
      !vendorData?.companyEmail ||
      !vendorData?.companyContactPerson ||
      !vendorData?.companyContactNumber ||
      !vendorData?.companyAddress ||
      !vendorData?.companyPanNo
    ) {
      toast.error(
        "It appears that some information is missing. Please ensure all fields are filled out.",
      );
      return;
    } else if (!validateEmail(vendorData.companyEmail)) {
      toast.error("Oops! That doesn't look like a valid email address. Please check and try again");
      return;
    }

    if (vendorData?.companyContactNumber?.length < 10) {
      toast.error("Please ensure that the mobile number is correct");
      return;
    }

    setLoading(true);
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/vendors/post/new`, {
        vendorDetails: vendorData,
      })
      .then((response) => {
        if (response && response.status === 201) {
          toast.success("Vendor Added Successfully");
        }
        setLoading(false);
      })
      .catch((error) => {
        if (error.response && [400, 404, 409, 500].includes(error.response.status)) {
          console.error("Error sending OTP:", error);

          switch (error.response.status) {
            case 400:
              toast.error(
                "Oops! It seems like the mobile number is incorrect. Please provide a valid one.",
              );
              break;
            case 404:
              toast.error(
                "Sorry, we couldn't find any account associated with the provided mobile number. Please sign up to get started.",
              );
              break;
            case 409:
              toast.error("Vendor Already Exists's");
              break;
            case 500:
              toast.error("An error occurred while adding vendor. Please try again later.");
              break;
            default:
              toast.error("An error occurred while adding vendor. Please try again later.");
          }
        } else {
          toast.error("An error occurred while adding vendor. Please try again later.");
        }
        setLoading(false);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSubmitVendor();
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSubmitVendor();
    }
  };

  const handleTelephoneChange = (e) => {
    let newValue = e.target.value.replace(/\D/g, "").slice(0, 15); // Limit to 15 digits

    if (newValue.length > 4) {
      // Insert "-" after the first 4 digits
      newValue = newValue.slice(0, 4) + "-" + newValue.slice(4);
    }
    setVendorData({
      ...vendorData,
      companyTelephoneNumber: newValue,
    });
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Dialog
        onClose={modalClose}
        aria-labelledby="simple-dialog-title"
        open={isOpen}
        fullWidth
        maxWidth="lg"
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "white",
          },
        }}
      >
        <form onSubmit={handleSubmit}>
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              fontSize: isMobile ? "1.2rem" : "1.4rem",
              fontWeight: "bold",
            }}
          >
            <strong>Add Vendor's Company</strong>
            <IconButton onClick={modalClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <Divider sx={{ bgcolor: "black" }} />
          <DialogContent sx={{ overflow: "hidden" }}>
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={12} sm={6}>
                <TextField
                  placeholder="Company Name"
                  id="name"
                  variant="standard"
                  value={vendorData.companyName}
                  onChange={(e) =>
                    setVendorData({
                      ...vendorData,
                      companyName: e.target.value,
                    })
                  }
                  onKeyPress={isMobile ? undefined : handleKeyPress}
                  fullWidth
                  required
                  autoComplete="off"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <BusinessIcon fontSize="large" sx={{ color: "black" }} />
                      </InputAdornment>
                    ),
                    style: { fontSize: "1.5rem" },
                  }}
                  InputLabelProps={{ style: { color: "black" } }}
                  sx={{
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "black",
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  placeholder="Telephone Number"
                  variant="standard"
                  value={vendorData.companyTelephoneNumber}
                  onChange={handleTelephoneChange}
                  onKeyPress={isMobile ? undefined : handleKeyPress}
                  fullWidth
                  autoComplete="off"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CallIcon fontSize="large" sx={{ color: "black" }} />
                      </InputAdornment>
                    ),
                    style: { fontSize: "1.5rem" },
                  }}
                  InputLabelProps={{ style: { color: "black" } }}
                  sx={{
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "black",
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  placeholder="Company Mobile Contact Number"
                  variant="standard"
                  value={vendorData.companyContactNumber}
                  onChange={(e) =>
                    setVendorData({
                      ...vendorData,
                      companyContactNumber: e.target.value.replace(/\D/g, "").slice(0, 13),
                    })
                  }
                  onKeyPress={isMobile ? undefined : handleKeyPress}
                  fullWidth
                  autoComplete="off"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PhonelinkRingIcon fontSize="large" sx={{ color: "black" }} />
                      </InputAdornment>
                    ),
                    style: { fontSize: "1.5rem" },
                  }}
                  InputLabelProps={{ style: { color: "black" } }}
                  sx={{
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "black",
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  placeholder="Company Contact Person Name"
                  variant="standard"
                  value={vendorData.companyContactPerson}
                  onChange={(e) =>
                    setVendorData({
                      ...vendorData,
                      companyContactPerson: e.target.value.replace(/[^A-Za-z\s]/g, ""),
                    })
                  }
                  onKeyPress={isMobile ? undefined : handleKeyPress}
                  fullWidth
                  autoComplete="off"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonIcon fontSize="large" sx={{ color: "black" }} />
                      </InputAdornment>
                    ),
                    style: { fontSize: "1.5rem" },
                  }}
                  InputLabelProps={{ style: { color: "black" } }}
                  sx={{
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "black",
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  placeholder="Company Email ID"
                  variant="standard"
                  value={vendorData.companyEmail}
                  onChange={(e) =>
                    setVendorData({
                      ...vendorData,
                      companyEmail: e.target.value.toLowerCase().replace(/\s/g, ""),
                    })
                  }
                  onKeyPress={isMobile ? undefined : handleKeyPress}
                  fullWidth
                  autoComplete="off"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Email fontSize="large" sx={{ color: "black" }} />
                      </InputAdornment>
                    ),
                    style: { fontSize: "1.5rem" },
                  }}
                  InputLabelProps={{ style: { color: "black" } }}
                  sx={{
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "black",
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  placeholder="Company Pan Card Number"
                  variant="standard"
                  value={vendorData.companyPanNo}
                  onChange={(e) =>
                    setVendorData({
                      ...vendorData,
                      companyPanNo: e.target.value,
                    })
                  }
                  onKeyPress={isMobile ? undefined : handleKeyPress}
                  fullWidth
                  autoComplete="off"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CreditCardIcon fontSize="large" sx={{ color: "black" }} />
                      </InputAdornment>
                    ),
                    style: { fontSize: "1.5rem" },
                  }}
                  InputLabelProps={{ style: { color: "black" } }}
                  sx={{
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "black",
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  placeholder="Company GST Number"
                  variant="standard"
                  value={vendorData.companyGSTNo}
                  onChange={(e) =>
                    setVendorData({
                      ...vendorData,
                      companyGSTNo: e.target.value,
                    })
                  }
                  onKeyPress={isMobile ? undefined : handleKeyPress}
                  fullWidth
                  autoComplete="off"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PaymentsIcon fontSize="large" sx={{ color: "black" }} />
                      </InputAdornment>
                    ),
                    style: { fontSize: "1.5rem" },
                  }}
                  InputLabelProps={{ style: { color: "black" } }}
                  sx={{
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "black",
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  placeholder="Company Address"
                  variant="standard"
                  value={vendorData.companyAddress}
                  onChange={(e) =>
                    setVendorData({
                      ...vendorData,
                      companyAddress: e.target.value,
                    })
                  }
                  multiline
                  maxRows={7}
                  fullWidth
                  autoComplete="off"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LocationOnIcon fontSize="large" sx={{ color: "black" }} />
                      </InputAdornment>
                    ),
                    style: { fontSize: "1.5rem" },
                  }}
                  InputLabelProps={{ style: { color: "black" } }}
                  sx={{
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "black",
                    },
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>

          <Divider sx={{ bgcolor: "black" }} />
          <DialogActions>
            <Button
              variant="contained"
              type="submit"
              sx={{
                backgroundColor: "#72225e",
                fontWeight: "bold",
                ":hover": {
                  backgroundColor: "#8C3C78",
                },
              }}
              startIcon={<SaveIcon />}
              disabled={loading}
            >
              Submit
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={modalClose}
              startIcon={<CloseIcon />}
            >
              Cancel
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </motion.div>
  );
};

export default AddNewVendors;

/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import ScheduleIcon from "@mui/icons-material/Schedule";
import DeleteIcon from "@mui/icons-material/Delete";
import "react-perfect-scrollbar/dist/css/styles.css";
import { Button, Tooltip } from "@mui/material";
import IntakeFormDailog from "./IntakeFormDailog";
import { toast } from "react-toastify";
import { DNA } from "react-loader-spinner";
import { motion } from "framer-motion";

const UpcomingAppointments = ({ isMobile, selectedDoctorId }) => {
  const [appointments, setAppointments] = useState([]);
  const [selectedIntakeFormData, setSelectedIntakeFormData] = useState(null);
  const [intakeFormDialogOpen, setIntakeFormDialogOpen] = useState(false);
  const [selectedDoctor, setSelectedDoctor] = useState("");
  const [loading, setLoading] = useState(false); // Add loading state

  useEffect(() => {
    const fetchDoctorList = async () => {
      try {
        const response = await axios.get(
          `${process.env.appointment}/api/appointment/get/upcoming/selected/doctor/${selectedDoctorId}`,
        );
        setAppointments(response.data.data);
        // console.log("patientEmail", response.data.data[0]._id);
        // console.log("fetchAppointments", response.data.data);
      } catch (error) {}
    };

    fetchDoctorList();
  }, [selectedDoctorId]);

  // useEffect(() => {
  //   const fetchIntakeForm = async (patientId) => {
  //     try {
  //       const response = await axios.get(
  //         `${process.env.REACT_APP_API_URL}/api/patient/get/selected/intake-form/${patientId}`,
  //       );
  //       if (response.data.success) {
  //         setSelectedIntakeFormData(response.data.data[0]);
  //         console.log("fetchIntakeForm", response);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching intake form data:", error);
  //     }
  //   };

  //   if (selectedDoctor) {
  //     fetchIntakeForm(selectedDoctor);
  //   }
  // }, [selectedDoctor]);

  const handleIntakeFormClick = async (patientId) => {
    setSelectedIntakeFormData(null); // Reset intake form data
    setIntakeFormDialogOpen(true);
    setLoading(true); // Set loading to true before fetching data

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/patient/get/selected/intake-form/${patientId}`,
      );

      if (response.status === 200 && response.data.success) {
        if (response.data.data) {
          setSelectedIntakeFormData(response.data.data[0]);
          setLoading(false);
        } else {
          console.error("Intake form data not found.");
          toast.info("This patient hasn't filled out the intake form yet.");
          setLoading(false);
        }
      } else {
        console.error("Error fetching intake form data. Unexpected status:", response.status);
        toast.error("Failed to fetch intake form data. Please try again later.");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching intake form data:", error);
      toast.error("Failed to fetch intake form data. Please try again later.");
      setLoading(false);
    }
  };

  const handleCloseIntakeFormDialog = (reason) => {
    if (reason && reason === "backdropClick") return;
    setIntakeFormDialogOpen(false);
    // console.log("intakeFormDialogOpen", intakeFormDialogOpen);
  };

  const appointmentsWithId =
    appointments &&
    appointments.map((appointment, index) => ({
      ...appointment,
      id: index + 1,
      patient: appointment.patientEmail,
    }));

  const handleCheckboxChange = (event, id) => {
    const updatedAppointments =
      appointments &&
      appointments.map((appointment) =>
        appointment.id === id ? { ...appointment, createVisit: event.target.checked } : appointment,
      );
    setAppointments(updatedAppointments);
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 30,
      flex: 1,
      headerClassName: "custom-header",
    },
    {
      field: "dateTime",
      headerName: "Time",
      width: 200,
      flex: 1,
      headerClassName: "custom-header",
      renderCell: (params) => (
        <div>
          <span>{params.row.startTime}</span>
        </div>
      ),
    },
    {
      field: "patient",
      headerName: "Patient",
      width: 200,
      headerClassName: "custom-header",
      flex: 1,
    },
    {
      field: "intakeForm",
      headerName: "Intake Form",
      width: 130,
      headerClassName: "custom-header",
      flex: 1,
      renderCell: (params) => (
        <Button onClick={() => handleIntakeFormClick(params.row.patientId)}>View Form</Button>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      headerClassName: "custom-header",
      renderCell: () => (
        <div>
          <ScheduleIcon />
          <DeleteIcon />
        </div>
      ),
    },
    {
      field: "createVisit",
      headerName: "Create Visit",
      width: 150,
      flex: 1,
      headerClassName: "custom-header",
      renderCell: (params) => (
        <Tooltip title="Create Visit">
          {/* <Checkbox
              checked={params.row.createVisit || false}
              onChange={(event) => handleCheckboxChange(event, params.row.id)}
            /> */}
        </Tooltip>
      ),
    },
  ];

  return (
    <div style={{ height: 430, width: "100%" }}>
      <DataGrid
        rows={appointmentsWithId || [{ id: "no data available", noData: true }]}
        columns={columns}
        disableColumnMenu={true}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 6 },
          },
        }}
        pageSizeOptions={[6]}
        disableSelectionOnClick
        autoHeight
        sx={{
          overflow: "hidden",

          backgroundColor: "white", // Set transparent background color
          "& .MuiDataGrid-columnHeader, & .MuiDataGrid-row": {
            fontSize: isMobile ? undefined : "1.2rem",
          },
          "& .MuiDataGrid-cell": {
            color: "black", // Set text color of cell data
          },
        }}
      />

      <IntakeFormDailog
        isOpen={intakeFormDialogOpen}
        handleClose={handleCloseIntakeFormDialog}
        intakeFormData={selectedIntakeFormData}
        patientName={selectedDoctor}
        loading={loading} // Pass loading state to the IntakeFormDialog
        isMobile={isMobile} // If needed
      />
    </div>
  );
};

export default UpcomingAppointments;

const headerStyles = `
  .custom-header {
    background-color: #CE3C73; /* Set the background color for the header */
    color: white; /* Set the text color for the header */
    font-weight: bold; /* Set the font weight for the header text */
    font-size: 16px; /* Set the font size for the header text */
  }
`;

// Inject the styles into the component
const styleElement = document.createElement("style");
styleElement.innerHTML = headerStyles;
document.head.appendChild(styleElement);

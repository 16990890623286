import React, { createContext, useContext } from "react";

const ScrollContext = createContext({
  scrollToTop: () => {},
});

export function useScroll() {
  return useContext(ScrollContext);
}

export const ScrollProvider = ({ children }) => {
  const scrollToTop = () => {
    const event = new CustomEvent("scrollToTop");
    window.dispatchEvent(event);
  };

  return <ScrollContext.Provider value={{ scrollToTop }}>{children}</ScrollContext.Provider>;
};

export default ScrollProvider;

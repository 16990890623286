import React, { useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import UpcomingAppointments from "./UpcomingAppoinmnets/UpcomingAppointments";
import Visits from "./Visits/Visits";
import DoctorDetails from "./DoctorDetails/DoctorDetails";
import DoctorsWeeklyTimings from "./DoctorsWeeklyTimings/DoctorsWeeklyTimings";
import CalendarSlots from "./Slots/CalendarSlots";

const CustomTabPanel = ({ value, index, children }) => {
  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const ViewDoctor = ({ state, selectedDoctor, onViewSchedule }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    event.preventDefault();
    setValue(newValue);
  };

  return (
    <div>
      <Box
        sx={{ borderBottom: 1, display: "flex", borderColor: "black", position: "sticky", top: 0 }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="basic tabs example"
          sx={{
            "& .MuiTabs-indicator": {
              backgroundColor: "#9D083F", // Change the indicator color here
            },
            "& .MuiTabs-scrollButtons.Mui-disabled": {
              opacity: 0.3, // Custom styling for disabled scroll buttons
            },
          }}
        >
          <Tab
            label="Doctor Details"
            style={{
              color: value === 0 ? "white" : "black",
              backgroundColor: value === 0 ? "#DB4F84" : "transparent",
              cursor: "pointer",
              fontSize: "1.2rem",
            }}
            onClick={(event) => handleChange(event, 0)}
          />
          <Tab
            label="Upcoming Appointments"
            style={{
              color: value === 1 ? "white" : "black",
              backgroundColor: value === 1 ? "#DB4F84" : "transparent",
              cursor: "pointer",
              fontSize: "1.2rem",
            }}
            onClick={(event) => handleChange(event, 1)}
          />
          <Tab
            label="Visits"
            style={{
              color: value === 2 ? "white" : "black",
              backgroundColor: value === 2 ? "#DB4F84" : "transparent",
              cursor: "pointer",
              fontSize: "1.2rem",
            }}
            onClick={(event) => handleChange(event, 2)}
          />
          <Tab
            label="Weekly Timings"
            style={{
              color: value === 3 ? "white" : "black",
              backgroundColor: value === 3 ? "#DB4F84" : "transparent",
              cursor: "pointer",
              fontSize: "1.2rem",
            }}
            onClick={(event) => handleChange(event, 3)}
          />
          <Tab
            label="Slots"
            style={{
              color: value === 4 ? "white" : "black",
              backgroundColor: value === 4 ? "#DB4F84" : "transparent",
              cursor: "pointer",
              fontSize: "1.2rem",
            }}
            onClick={(event) => handleChange(event, 4)}
          />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        {/* {console.log("selectedDoctor", selectedDoctor)}
        {console.log("selectedDoctorId", state.selectedDoctorId)} */}

        <DoctorDetails
          selectedDoctor={selectedDoctor}
          selectedDoctorId={state.selectedDoctorId}
          onViewSchedule={onViewSchedule}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <UpcomingAppointments selectedDoctorId={state.selectedDoctorId} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <Visits />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <DoctorsWeeklyTimings selectedDoctorId={state.selectedDoctorId} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
        <CalendarSlots selectedDoctorId={state.selectedDoctorId} />
      </CustomTabPanel>
    </div>
  );
};

export default ViewDoctor;

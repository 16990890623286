/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Button, Box } from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import AddDoctorsTimingModal from "./AddDoctorsTimingModal";
import DoctorsTimingsTable from "./DoctorsTimingsTable";
import Swal from "sweetalert2";

const DoctorsWeeklyTimings = ({ selectedDoctorId }) => {
  const [timings, setTimings] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [timingToEdit, setTimingToEdit] = useState(null);

  useEffect(() => {
    fetchAllTimings();
  }, []);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setIsEditMode(false);
    setTimingToEdit(null);
  };

  const handleCancelClick = () => {
    setIsEditMode(false);
    setTimingToEdit(null);
    handleCloseModal();
  };

  const handleAddTiming = (newTiming) => {
    const adjustedTiming = {
      ...newTiming,
      from: convertTo12HourFormat(newTiming.from),
      to: convertTo12HourFormat(newTiming.to),
    };

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/doctor-timings/post/new/doctor/timings/${selectedDoctorId}`,
        adjustedTiming,
      )
      .then((response) => {
        if (response.status === 201) {
          console.log("New timing created:", response.data);
          toast.success("Timing Added Successfully");
          fetchAllTimings();
        } else {
          console.error("Error creating new timing. Unexpected status:", response.status);
          toast.error("Doctor timings exceed company timings for the specified day");
        }
      })
      .catch((error) => {
        console.error("Error creating new timing:", error);
        toast.error("Doctor timings exceed company timings for the specified day");
      });
  };

  const handleUpdateTiming = (updatedTiming) => {
    const adjustedTiming = {
      ...updatedTiming,
      from: convertTo12HourFormat(updatedTiming.from),
      to: convertTo12HourFormat(updatedTiming.to),
    };
    console.log("timeId", timingToEdit.timeId);

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/doctor-timings/post/update/doctor/timings/${selectedDoctorId}/${timingToEdit.timeId}`,
        adjustedTiming,
      )
      .then((response) => {
        if (response.status === 200) {
          console.log("Timing updated:", response.data);
          toast.success("Timing Updated Successfully");
          fetchAllTimings();
        } else {
          console.error("Error updating timing. Unexpected status:", response.status);
          toast.error("Doctor timings exceed company timings for the specified day");
        }
      })
      .catch((error) => {
        console.error("Error updating timing:", error);
        toast.error("Doctor timings exceed company timings for the specified day");
      });
  };

  const handleSaveTiming = (newTiming) => {
    if (isEditMode) {
      handleUpdateTiming(newTiming);
    } else {
      handleAddTiming(newTiming);
    }
  };

  const fetchAllTimings = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/doctor-timings/get/all/doctor/timings/${selectedDoctorId}`,
      );
      if (response && response.status === 200 && response.data && response.data.data) {
        const fetchedTimings = response.data.data.doctorTiming.map((timing) => ({
          ...timing,
          timeId: timing._id,
        }));
        setTimings(fetchedTimings);
      } else {
        console.error("Error fetching doctor timings:", response);
        // toast.error("Error fetching doctor timings");
      }
    } catch (error) {
      if (error.response && [400, 404, 409, 500].includes(error.response.status)) {
        console.error("Error fetching doctor timings:");

        // toast.error("Error fetching doctor timings");
      } else {
        // toast.error("Error fetching doctor timings");
      }
    }
  };

  const convertTo12HourFormat = (time) => {
    const [hours, minutes, ampm] = time.split(/[:\s]/);
    let hour = parseInt(hours, 10);
    const adjustedHour = hour % 12 || 12;
    return `${adjustedHour}:${minutes} ${ampm}`;
  };

  const handleEditTiming = (timing) => {
    setOpenModal(true);
    setIsEditMode(true);
    setTimingToEdit(timing);
  };

  useEffect(() => {
    // console.log("timingToEdit", timingToEdit);
  }, [timingToEdit]);

  const handleDeleteTiming = (doctorId, timingId) => {
    console.log("doctorId", doctorId);
    console.log("timingId", timingId);

    axios
      .delete(`${process.env.REACT_APP_API_URL}/api/doctor-timings/delete/${doctorId}/${timingId}`)
      .then((response) => {
        if (response.status === 200) {
          console.log("Timing deleted successfully", response.data);
          toast.success("Timing Deleted Successfully");
          setTimings(timings.filter((timing) => timing.timeId !== timingId));
          setTimingToEdit(null);
        } else {
          console.error("Error deleting timing. Unexpected status:", response.status);
        }
      })
      .catch((error) => {
        console.error("Error deleting timing:", error);
      });
  };

  const handleOpenDeleteConfirmation = (doctorId, timingId) => {
    console.log("TimingId", timingId);

    Swal.fire({
      title: "Confirm Deletion",
      text: "Are you sure you want to delete this disease?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDeleteTiming(doctorId, timingId);
      }
    });
  };

  const handleUpdateSlots = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/doctor-slots/post/create/slot/${selectedDoctorId}`,
      )
      .then((response) => {
        if (response.status === 200) {
          console.log("Slots updated for 2 months", response.data.data);
          toast.success("Slots updated successfully for 2 months");
          // fetchAllTimings();
        } else {
          console.error("Error updating slots. Unexpected status:", response.status);
        }
      })
      .catch((error) => {
        console.error("Error updating slots:", error);
      });
  };

  const handleOpenUpdateSlotsConfirmation = () => {
    Swal.fire({
      title: "Confirm Update",
      text: "Are you sure you want to update slots for the next 2 months?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        handleUpdateSlots();
      }
    });
  };

  return (
    <div style={{ height: 430, width: "100%", overflow: "hidden" }}>
      <Box mb={1} display="flex" justifyContent="flex-end">
        <Button
          onClick={handleOpenModal}
          variant="contained"
          sx={{
            color: "primary",
          }}
        >
          Add Timing
        </Button>
        <Button
          onClick={handleOpenUpdateSlotsConfirmation}
          variant="contained"
          sx={{
            color: "primary",
            ml: 2,
          }}
        >
          Update Slots
        </Button>
      </Box>
      <div style={{ overflowY: "auto" }}>
        <AddDoctorsTimingModal
          open={openModal}
          handleClose={handleCancelClick}
          handleSave={handleSaveTiming}
          isEditMode={isEditMode}
          timingToEdit={timingToEdit}
        />
        <DoctorsTimingsTable
          timings={timings}
          handleEditTiming={handleEditTiming}
          handleDelete={(timingId) => handleOpenDeleteConfirmation(selectedDoctorId, timingId)}
        />
      </div>
    </div>
  );
};

export default DoctorsWeeklyTimings;

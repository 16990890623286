import React from "react";
import {
  TextField,
  Button,
  Grid,
  ListItemIcon,
  ListItemText,
  Box,
  FormControl,
  Select,
  MenuItem,
  InputAdornment,
} from "@mui/material";
import "react-perfect-scrollbar/dist/css/styles.css";
import axios from "axios";
import { Email, Wc } from "@mui/icons-material";
import PersonIcon from "@mui/icons-material/Person";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import { toast } from "react-toastify";
import countries from "../../../../../country.json";
import { motion } from "framer-motion";
import { DNA } from "react-loader-spinner";

const AddNewOfflineUserFields = ({
  userData,
  setUserData,
  showOTP,
  setShowOTP,
  loading,
  setLoading,
  numberChange,
  setNumberChange,
  setComponent,
  variants,
  isMobile,
}) => {
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleGenderSelection = (event) => {
    setUserData({ ...userData, gender: event.target.value });
  };

  const handleLogin = async () => {
    if (!userData.mobileNumber || !userData.email || !userData.name || !userData.age) {
      toast.error(
        "It appears that some information is missing. Please ensure all field(s) are filled out",
      );
      return;
    } else if (!validateEmail(userData.email)) {
      toast.error("Oops! That doesn't look like a valid email address. Please check and try again");
      return;
    }

    if (userData.mobileNumber.length < 10) {
      toast.error("Please ensure that the mobile number is correct");
      return;
    }

    setLoading(true);
    if (numberChange === userData.mobileNumber) {
      await axios
        .post(`${process.env.REACT_APP_API_URL}/api/users/post/request/otp`, {
          userDetail: { countryCode: userData.countryCode, mobileNumber: userData.mobileNumber },
        })
        .then((response) => {
          if (response && response.status === 200) {
            toast.success("The verification code has been sent to your number");
            setNumberChange("");
            setShowOTP(true);
            setComponent("otp component");
          }
          setLoading(false);
        })
        .catch((error) => {
          if (error.response && [400, 404, 409, 500].includes(error.response.status)) {
            console.error("Error sending OTP:", error);

            switch (error.response.status) {
              case 400:
                toast.error(
                  "Oops! It seems like the mobile number is incorrect. Please provide a valid one",
                );
                break;
              case 404:
                toast.error(
                  "Sorry, we couldn't find any account associated with the provided mobile number. Please sign up to get started",
                );
                break;
              case 409:
                toast.error("There is a conflict with the current state of the mobile number");
                break;
              case 500:
                toast.error("An error occurred while sending OTP. Please try again later");
                break;
              default:
                toast.error("An error occurred while sending OTP. Please try again later");
            }
          } else {
            toast.error("An error occurred while sending OTP. Please try again later");
          }
          setShowOTP(false);
          setLoading(false);
        });
    } else if (showOTP) {
      setComponent("otp component");
      setLoading(false);
    }
  };

  const handleAgeChange = (e) => {
    let newValue = e.target.value.replace(/\D/g, "").slice(0, 3);

    if (parseInt(newValue) < 120 || newValue === "") {
      setUserData({ ...userData, age: newValue });
    }
  };

  const handleCountryChange = (e) => {
    setUserData({
      ...userData,
      countryCode: e.target.value.dial_code,
      selectedCountry: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleLogin();
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleLogin();
    }
  };

  if (loading) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
          <Grid item>
            <DNA
              visible={true}
              height={isMobile ? "120" : "200"}
              width={isMobile ? "200" : "250"}
              color="#2B2A29"
              ariaLabel="dna-loading"
              wrapperStyle={{}}
              wrapperClass="dna-wrapper"
            />
          </Grid>
        </Grid>
      </motion.div>
    );
  }

  return (
    <FormControl onSubmit={handleSubmit}>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} sm={6}>
          <motion.div variants={variants} initial="hidden" animate="visible">
            <TextField
              placeholder="Full Name"
              id="name"
              variant="standard"
              value={userData.name}
              onChange={(e) =>
                setUserData({ ...userData, name: e.target.value.replace(/[^A-Za-z\s]/g, "") })
              }
              onKeyPress={isMobile ? undefined : handleKeyPress}
              fullWidth
              required
              autoComplete="off"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonIcon fontSize="large" sx={{ color: "black" }} />
                  </InputAdornment>
                ),
                style: { fontSize: "1.5rem" },
              }}
              InputLabelProps={{ style: { color: "black" } }}
              sx={{
                "& .MuiInput-underline:after": {
                  borderBottomColor: "black",
                },
              }}
            />
          </motion.div>
        </Grid>

        <Grid item xs={12} sm={6}>
          <motion.div variants={variants} initial="hidden" animate="visible">
            <Box display="flex">
              <FormControl sx={{ mr: 1, mt: 0.5 }} variant="standard">
                <Select
                  value={userData.selectedCountry}
                  onChange={handleCountryChange}
                  displayEmpty
                  sx={{
                    "&:focus": {
                      borderColor: "black",
                    },
                    "&:hover": {
                      borderColor: "black",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "black",
                    },
                    "& .MuiMenuItem-root.Mui-selected": {
                      color: "black",
                    },
                  }}
                >
                  {countries.map((country, index, array) => (
                    <MenuItem
                      key={country.name}
                      value={country}
                      sx={{
                        mt: index === 0 ? -1 : "normal",
                        mb: index === array.length - 1 ? -1 : "normal",
                      }}
                      divider
                    >
                      <ListItemIcon>
                        <img src={country.flag_url} alt="" width="30" height="20" />
                      </ListItemIcon>
                      <ListItemText primary={country.code} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                placeholder="Mobile Number"
                id="mobile number"
                variant="standard"
                value={userData.mobileNumber}
                onChange={(e) => {
                  setUserData({
                    ...userData,
                    mobileNumber: e.target.value.replace(/\D/g, "").slice(0, 13),
                  });

                  setNumberChange(e.target.value.replace(/\D/g, "").slice(0, 13));
                }}
                onKeyPress={isMobile ? undefined : handleKeyPress}
                fullWidth
                required
                autoComplete="off"
                InputProps={{
                  style: { fontSize: "1.5rem" },
                }}
                InputLabelProps={{ style: { color: "black" } }}
                sx={{
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "black",
                  },
                }}
              />
            </Box>
          </motion.div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <motion.div variants={variants} initial="hidden" animate="visible">
            <TextField
              placeholder="Email"
              id="email"
              variant="standard"
              value={userData.email}
              onChange={(e) =>
                setUserData({
                  ...userData,
                  email: e.target.value.toLowerCase().replace(/\s/g, ""),
                })
              }
              onKeyPress={isMobile ? undefined : handleKeyPress}
              fullWidth
              required
              autoComplete="off"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Email fontSize="large" sx={{ color: "black" }} />
                  </InputAdornment>
                ),
                style: { fontSize: "1.5rem" },
              }}
              InputLabelProps={{ style: { color: "black" } }}
              sx={{
                "& .MuiInput-underline:after": {
                  borderBottomColor: "black",
                },
              }}
            />
          </motion.div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <motion.div variants={variants} initial="hidden" animate="visible">
            <FormControl fullWidth variant="standard">
              <Select
                value={userData.gender}
                onChange={handleGenderSelection}
                displayEmpty
                inputProps={{ "aria-label": "Gender" }}
                InputLabelProps={{ style: { color: "black" } }}
                sx={{
                  fontSize: "1.5rem",
                  "&:focus": {
                    borderColor: "black",
                  },
                  "&:hover": {
                    borderColor: "black",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "black",
                  },
                  "& .MuiMenuItem-root.Mui-selected": {
                    color: "black",
                  },
                }}
                startAdornment={
                  <InputAdornment position="start">
                    <Wc fontSize="large" sx={{ color: "black" }} />
                  </InputAdornment>
                }
              >
                <MenuItem value="" disabled sx={{ mt: -1, fontSize: "1.5rem" }} divider>
                  None
                </MenuItem>
                <MenuItem value="male" sx={{ fontSize: "1.5rem" }} divider>
                  Male
                </MenuItem>
                <MenuItem value="female" sx={{ mb: -1, fontSize: "1.5rem" }} divider>
                  Female
                </MenuItem>
              </Select>
            </FormControl>
          </motion.div>
        </Grid>

        <Grid item xs={12} sm={4}>
          <motion.div variants={variants} initial="hidden" animate="visible">
            <TextField
              placeholder="Age"
              id="age"
              variant="standard"
              value={userData.age}
              onChange={handleAgeChange}
              onKeyPress={isMobile ? undefined : handleKeyPress}
              fullWidth
              required
              autoComplete="off"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PermContactCalendarIcon fontSize="large" sx={{ color: "black" }} />
                  </InputAdornment>
                ),
                style: { fontSize: "1.5rem" },
              }}
              InputLabelProps={{ style: { color: "black" } }}
              sx={{
                "& .MuiInput-underline:after": {
                  borderBottomColor: "black",
                },
              }}
            />
          </motion.div>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12} sm={6}>
              <motion.div variants={variants} initial="hidden" animate="visible">
                <Button
                  variant="contained"
                  fullWidth
                  type="submit"
                  onClick={handleLogin}
                  style={{
                    background: "#2B2A29",
                    borderRadius: "40px",
                    fontSize: isMobile ? "1rem" : "1.3rem",
                    textTransform: "capitalize",
                  }}
                >
                  Send Verification Code
                </Button>
              </motion.div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FormControl>
  );
};

export default AddNewOfflineUserFields;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import axios from "axios";

const MedicineDelivery = ({
  selectedDelivery,
  setSelectedDelivery,
  medicineDeliveries,
  setMedicineDeliveries,
}) => {
  useEffect(() => {
    // Fetch medicine deliveries from the API
    const fetchMedicineDeliveries = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/medicine-delivery/get/all`,
        );

        if (response && response.status === 200) {
          if (response.data && response.data.data) {
            setMedicineDeliveries(response.data.data);
          } else {
            setMedicineDeliveries([]);
            console.error("Error fetching medicine deliveries");
          }
        } else {
          setMedicineDeliveries([]);
          console.error("Error fetching medicine deliveries");
        }
      } catch (error) {
        setMedicineDeliveries([]);
        console.error("Error fetching medicine deliveries:", error);
      }
    };

    fetchMedicineDeliveries();
  }, []);

  const handleDeliveryChange = (event) => {
    const selectedDelivery = medicineDeliveries.find(
      (delivery) => delivery.medicineDeliveryName === event.target.value,
    );

    setSelectedDelivery(selectedDelivery || {}); // Use a default value if selectedDelivery is undefined
  };

  return (
    <FormControl fullWidth>
      <InputLabel
        id="medicine delivery"
        sx={{
          color: "white",
          "&.Mui-focused": {
            color: "white",
          },
        }}
      >
        Medicine Delivery
      </InputLabel>
      <Select
        labelId="medicine delivery"
        label="Medicine Delivery"
        value={selectedDelivery.medicineDeliveryName || ""}
        onChange={handleDeliveryChange}
        sx={{
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "white",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "white",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "white",
          },
          color: "white",
          "& .MuiSvgIcon-root": {
            color: "white",
          },
        }}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {medicineDeliveries &&
          medicineDeliveries.map((delivery) => (
            <MenuItem key={delivery._id} value={delivery.medicineDeliveryName}>
              {delivery.medicineDeliveryName}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default MedicineDelivery;

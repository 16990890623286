import React, { useState, useEffect } from "react";
import {
  List,
  ListItem,
  ListItemText,
  TextField,
  Button,
  Typography,
  IconButton,
  Stack,
  Tooltip,
} from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import { DNA } from "react-loader-spinner";
import { motion } from "framer-motion";

const MedicineDeliveryList = ({ isMobile }) => {
  const [medicineDelivery, setMedicineDelivery] = useState([]);
  const [textFieldValue, setTextFieldValue] = useState("");
  const [selectedId, setSelectedId] = useState(null);
  const [action, setAction] = useState("add");
  const [deliveryLoading, setDeliveryLoading] = useState(true);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/medicine-delivery/get/all`,
      );

      if (response && response.status === 200) {
        if (response.data && response.data.data) {
          setMedicineDelivery(response.data.data);
        } else {
          setMedicineDelivery([]);
          console.error("Error fetching medicine delivery");
        }
      } else {
        setMedicineDelivery([]);
        console.error("Error fetching medicine delivery");
      }
      setDeliveryLoading(false);
    } catch (error) {
      setMedicineDelivery([]);
      console.error("Error fetching medicine delivery:", error);
      setDeliveryLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleCancel = () => {
    setTextFieldValue("");
    setSelectedId("");
    setAction("add");
  };

  const handleTextFieldChange = (event) => {
    setTextFieldValue(event.target.value);
  };

  const handleButtonClick = async () => {
    if (action === "add") {
      if (!textFieldValue) {
        toast.error("Please enter a medicine delivery first");
        return;
      }

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/medicine-delivery/post/new`,
          {
            medicineDeliveryName: textFieldValue,
          },
        );

        if (response && response.status === 201) {
          toast.success("Medicine Delivery Added Successfully");
          handleCancel();
          fetchData();
        }
      } catch (error) {
        if (error.response && error.response.status === 409) {
          console.error("Error adding medicine delivery:", error.response.data.message);
          toast.error("Entered Medicine Delivery Already Exists");
        } else {
          console.error("Error adding medicine delivery:", error);
          toast.error("Error adding medicine delivery");
        }
      }
    } else if (action === "edit") {
      if (!textFieldValue) {
        toast.error("Please fill medicine delivery first");
        return;
      }

      // Check if the text field value has changed
      if (
        textFieldValue ===
        medicineDelivery.find((item) => item._id === selectedId)?.medicineDeliveryName
      ) {
        toast.error("No changes detected.");
        return;
      }

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/medicine-delivery/post/selected/${selectedId}`,
          {
            medicineDeliveryName: textFieldValue,
          },
        );

        if (response && response.status === 200) {
          toast.success("Medicine Delivery Updated Successfully");
          handleCancel();
          fetchData();
        }
      } catch (error) {
        if (error.response && error.response.status === 409) {
          console.error("Error adding medicine delivery:", error.response.data.message);
          toast.error("Entered Medicine Delivery Already Exists");
        } else {
          console.error("Error adding medicine delivery:", error);
          toast.error("Error adding medicine delivery");
        }
      }
    }
  };

  const handleEdit = (item) => {
    if (item) {
      setSelectedId(item._id);
      setTextFieldValue(item.medicineDeliveryName);
      setAction("edit");
    }
  };

  const handleDelete = (item) => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}/api/medicine-delivery/delete/selected/${item._id}`)
      .then((response) => {
        if (response.status === 200) {
          toast.success(`Medicine Delivery Deleted Successfully`);
          fetchData();
        }
      })
      .catch((error) => {
        // Handle error, show error message, etc.
        console.error("Error deleting medicine delivery", error);
      });
  };

  const handleDeleteConfirmation = (item) => {
    handleCancel();

    Swal.fire({
      title: "Confirm Deletion",
      text: "Are you sure you want to delete this medicine delivery?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(item);
      } else {
        // example codes to clear up
      }
    });
  };

  return (
    <>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={2}
        alignItems="center"
        justifyContent="center"
        style={{
          position: "sticky",
          top: 0,
          background: "#A52D59",
          zIndex: 1,
          padding: "12px",
        }}
      >
        <TextField
          margin="dense"
          id="medicine delivery"
          type="text"
          label="Medicine Delivery"
          value={textFieldValue}
          required
          onChange={handleTextFieldChange}
          placeholder="Enter Medicine Delivery"
          InputLabelProps={{ style: { color: "white" } }}
          autoComplete="off"
          sx={{
            "& input": {
              color: "white",
            },
            "& .MuiOutlinedInput-root.Mui-focused": {
              borderColor: "white",
              "& fieldset": {
                borderColor: "white",
              },
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "white",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "white",
            },
          }}
          fullWidth
        />
        <Stack direction="row">
          <Button
            variant="contained"
            onClick={handleButtonClick}
            sx={{
              ml: 2,
              backgroundColor: "white",
              color: "darkblue",
              ":hover": {
                backgroundColor: "#e0e0e0",
                color: "darkblue",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              },
            }}
            size="large"
          >
            {action === "add" ? "Add" : "Update"}
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={handleCancel}
            sx={{ ml: 2 }}
            size="large"
          >
            Cancel
          </Button>
        </Stack>
      </Stack>
      {deliveryLoading ? (
        <motion.div>
          <DNA
            visible={true}
            height={isMobile ? "120" : "200"}
            width={isMobile ? "200" : "250"}
            color="#2B2A29"
            ariaLabel="dna-loading"
            wrapperStyle={{}}
            wrapperClass="dna-wrapper"
          />
        </motion.div>
      ) : medicineDelivery && medicineDelivery.length > 0 ? (
        <List>
          {medicineDelivery.map((item, index) => (
            <ListItem
              component="div"
              key={index}
              sx={{
                backgroundColor: "#C47F9F",
                borderRadius: 5,
                "&:hover": {
                  backgroundColor: "#B54A7C",
                  cursor: "pointer",
                },
                userSelect: "none",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingRight: "10px",
                padding: "10px",
                marginBottom: "5px",
              }}
            >
              <ListItemText
                primary={
                  <Typography
                    sx={{
                      ml: 2,
                      fontSize: isMobile ? "1rem" : "1.3rem",
                      color: "black",
                    }}
                  >
                    {item.medicineDeliveryName}
                  </Typography>
                }
              />
              <div>
                <Tooltip title="Edit Medicine Delivery" arrow>
                  <IconButton
                    onClick={() => {
                      handleEdit(item);
                    }}
                  >
                    <EditIcon sx={{ color: "white" }} />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete Medicine Delivery" arrow>
                  <IconButton
                    onClick={() => {
                      handleDeleteConfirmation(item);
                    }}
                  >
                    <DeleteIcon sx={{ color: "white" }} />
                  </IconButton>
                </Tooltip>
              </div>
            </ListItem>
          ))}
        </List>
      ) : (
        <ListItem
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "30vh",
            textAlign: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "1.6rem",
              textAlign: "center",
            }}
          >
            No Medicine Delivery Data Found
          </Typography>
        </ListItem>
      )}
    </>
  );
};

export default MedicineDeliveryList;

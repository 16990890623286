import React, { useEffect, useRef, useState } from "react";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Swal from "sweetalert2";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Divider,
  Box,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { DNA } from "react-loader-spinner";

const AddEditTopicModal = ({ state, dispatch, isMobile }) => {
  const [prompt, setPrompt] = useState("");
  const [code, setCode] = useState("");
  const [header, setHeader] = useState("");
  const [description, setDescription] = useState("");
  const [file, setFile] = useState();
  const [videoLink, setVideoLink] = useState("");
  const [imageURL, setImageURL] = useState("");
  const [loading, setLoading] = useState(false);

  const fileInputRef = useRef(null);

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;

    setPrompt("");
    setCode("");
    setHeader("");
    setDescription("");
    setVideoLink("");
    setFile("");
    setImageURL("");

    dispatch({ type: "set_topic_modal", payload: false });
    dispatch({ type: "set_topic_action", payload: "" });
  };

  const handleAdd = async () => {
    setLoading(true);

    try {
      if (!header || !description || !code) {
        toast.error("Topic header, description and code are required");
        setLoading(false);
        return;
      }

      const requestData = {
        prompt,
        code,
        header,
        description,
        videoLink,
        image: file,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/qna/post/new/topic`,
        requestData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        },
      );

      if (response.status === 201) {
        toast.success(`New Topic Added Successfully`);
        handleClose();
        dispatch({
          type: "set_topic_refresh_flag",
          payload: !state.topicRefreshFlag,
        });
      } else {
        toast.error("Error Adding Topics, Please Try after sometime");
      }
    } catch (error) {
      console.error("Error adding in topics:", error);

      if (error.response && [400, 404, 409, 500].includes(error.response.status)) {
        switch (error.response.status) {
          case 400:
            toast.error("Bad request. Please check your inputs");
            break;
          case 404:
            toast.error("Topic not found");
            break;
          case 409:
            toast.error("There is a conflict with the current state of the topic");
            break;
          case 500:
            toast.error("Server error while adding topic, Please Try after sometime");
            break;
          default:
            toast.error("An error occurred while adding topic, Please Try after sometime");
        }
      } else {
        toast.error("Error Adding Topics, Please Contact Developer");
      }
    }
    setLoading(false);
  };

  const handleEdit = async () => {
    setLoading(true);

    try {
      if (!header || !description || !code) {
        toast.error("Both header and description are required");
        setLoading(false);
        return;
      }

      const requestData = {
        prompt,
        code,
        header,
        description,
        videoLink,
        image: file ? file : imageURL ? imageURL.substring(imageURL.lastIndexOf("/") + 1) : null,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/qna/post/topic/edit/${state.selectedTopicItem._id}`,
        requestData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        },
      );

      if (response && response.status === 200) {
        toast.success(`Topic Edited Successfully`);
        dispatch({
          type: "set_topic_refresh_flag",
          payload: !state.topicRefreshFlag,
        });
        handleClose();
      } else {
        toast.error("Error while updating Topics, Please Try after sometime");
      }
    } catch (error) {
      console.error("Error updating in topics:", error);

      if (error.response && [400, 404, 409, 500].includes(error.response.status)) {
        switch (error.response.status) {
          case 400:
            toast.error("Bad request. Please check your inputs");
            break;
          case 404:
            toast.error("Topic not found");
            break;
          case 409:
            toast.error("There is a conflict with the current state of the topic");
            break;
          case 500:
            toast.error("Server error while updating topic, Please Try after sometime");
            break;
          default:
            toast.error("An error occurred while updating topic, Please Try after sometime");
        }
      } else {
        toast.error("Error while updating Topics, Please Contact Developer");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (state.topicAction === "add") {
      setPrompt("");
      setCode("");
      setHeader("");
      setDescription("");
      setVideoLink("");
      setFile("");
      setImageURL("");
    } else if (state.topicAction === "edit") {
      if (state.selectedTopicItem) {
        setLoading(true);

        axios
          .get(
            `${process.env.REACT_APP_API_URL}/api/qna/get/topic/edit/${state.selectedTopicItem._id}`,
          )
          .then((response) => {
            if (response.status === 200 && response.data && response.data.data) {
              const data = response.data.data;
              setPrompt(data.prompt);
              setCode(data.topicCode);
              setHeader(data.topicHeading);
              setDescription(data.topicDescription);
              setVideoLink(data.topicVideoLink);

              if (data.topicImage) {
                setImageURL(
                  `https://7dstatic.s3.ap-south-1.amazonaws.com/trial/${data.topicImage}`,
                );
                setFile(null);
              } else {
                setFile("");
                setImageURL("");
              }
            } else {
              toast.error("Error fetching topic, Please try after sometime");
            }
          })
          .catch((error) => {
            console.error("Error fetching topic:", error);

            if (error.response && [400, 404, 409, 500].includes(error.response.status)) {
              switch (error.response.status) {
                case 400:
                  toast.error("Bad request. Please check your inputs");
                  break;
                case 404:
                  toast.error("Topic not found");
                  break;
                case 409:
                  toast.error("There is a conflict with the current state of the topic");
                  break;
                case 500:
                  toast.error("Server error while fetching topic, Please Try after sometime");
                  break;
                default:
                  toast.error("An error occurred while fetching topic, Please Try after sometime");
              }
            } else {
              toast.error("Error fetching topic, Please Contact Developer");
            }
          });

        setLoading(false);
      }
    }
  }, [state.topicAction, state.selectedTopicItem]);

  const fileSelected = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      if (file) {
        setFile(file);
        setImageURL("");
      }
    } else {
      console.error("No image selected");
    }
  };

  const handleCKEditorChange = (event, editor) => {
    setDescription(editor.getData());
  };

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleRemove = () => {
    setFile("");
    setImageURL("");
  };

  const handleCancel = () => {
    Swal.fire({
      title: "Confirm Deletion",
      text: "Are you sure you want to remove selected or existing image ???",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
      didOpen: () => {
        document.querySelector(".swal2-container").style.zIndex = "2000";
      },
    }).then((result) => {
      if (result.isConfirmed) {
        handleRemove();
      } else {
        // example codes to clear up
      }
    });
  };

  return (
    <Dialog open={state.topicModal} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle fontSize="1.5rem">
        {state.topicAction === "add" ? `Add New Topic` : "Edit Topic"}
      </DialogTitle>

      <Divider sx={{ bgcolor: "black" }} />
      <PerfectScrollbar options={{ wheelPropagation: false }}>
        <DialogContent>
          {loading ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <DNA
                visible={true}
                height={isMobile ? "120" : "200"}
                width={isMobile ? "200" : "250"}
                color="#2B2A29"
                ariaLabel="dna-loading"
                wrapperStyle={{}}
                wrapperClass="dna-wrapper"
              />
            </div>
          ) : (
            <>
              <TextField
                margin="dense"
                id="prompt"
                label="Prompt (Optional)"
                type="text"
                placeholder="Enter Topic Ai Prompt Text"
                fullWidth
                autoComplete="off"
                InputLabelProps={{
                  style: { color: "black" }, // Style for label
                }}
                InputProps={{
                  sx: {
                    "&:focused": { borderColor: "black" }, // Border color when focused
                  },
                }}
                sx={{
                  mb: 1.2,
                  my: 2,
                  "& input:focus": {
                    borderColor: "black !important",
                  },
                  "& .MuiOutlinedInput-root.Mui-focused": {
                    "& fieldset": {
                      borderColor: "black !important",
                    },
                  },
                }}
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
              />
              <TextField
                margin="dense"
                id="code"
                label="Code"
                type="text"
                InputLabelProps={{
                  style: {
                    color: "black",
                  },
                }}
                sx={{
                  mb: 1.2,
                  "& input:focus": {
                    borderColor: "black !important",
                  },
                  "& .MuiOutlinedInput-root.Mui-focused": {
                    "& fieldset": {
                      borderColor: "black !important",
                    },
                  },
                }}
                fullWidth
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
              <TextField
                margin="dense"
                id="header"
                label="Header"
                sx={{
                  mb: 1.2,
                  "& input:focus": {
                    borderColor: "black !important",
                  },
                  "& .MuiOutlinedInput-root.Mui-focused": {
                    "& fieldset": {
                      borderColor: "black !important",
                    },
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: "black",
                  },
                }}
                type="text"
                fullWidth
                value={header}
                onChange={(e) => setHeader(e.target.value)}
              />
              <div>
                <Typography
                  variant="body1"
                  htmlFor="editor"
                  sx={{
                    my: 2,
                    color: "black",
                    "& input:focus": {
                      borderColor: "black !important",
                    },
                    "& .MuiOutlinedInput-root.Mui-focused": {
                      "& fieldset": {
                        borderColor: "black !important",
                      },
                    },
                  }}
                >
                  Description *
                </Typography>
                <CKEditor
                  editor={ClassicEditor}
                  data={description}
                  onChange={handleCKEditorChange}
                  config={{
                    toolbar: {
                      items: [
                        "heading",
                        "|",
                        "bold",
                        "italic",
                        "link",
                        "bulletedList",
                        "numberedList",
                        "|",
                        "blockQuote",
                        "undo",
                        "redo",
                        "|",
                        "fontFamily",
                        "fontColor",
                        "fontBackgroundColor",
                        "fontSize",
                      ],
                    },
                  }}
                  id="editor"
                />
              </div>
              <TextField
                margin="dense"
                id="videoLink"
                label="Video Link (Optional)"
                type="text"
                fullWidth
                autoComplete="off"
                value={videoLink}
                onChange={(e) => setVideoLink(e.target.value)}
                InputLabelProps={{
                  style: { color: "black" }, // Style for label
                }}
                InputProps={{
                  sx: {
                    "&:focused": { borderColor: "black" }, // Border color when focused
                  },
                }}
                sx={{
                  mb: 1.2,
                  my: 2,
                  "& input:focus": {
                    borderColor: "black !important",
                  },
                  "& .MuiOutlinedInput-root.Mui-focused": {
                    "& fieldset": {
                      borderColor: "black !important",
                    },
                  },
                }}
              />
              <Paper elevation={12} sx={{ padding: "20px", mt: 2 }}>
                <Grid container alignItems="center">
                  <input
                    ref={fileInputRef}
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={fileSelected}
                  />
                  <Button variant="contained" onClick={handleClick} sx={{ mr: 2 }}>
                    CHOOSE AN IMAGE (OPTIONAL)
                  </Button>
                  {file || imageURL ? (
                    <Typography variant="body1">
                      {file ? file.name : imageURL.substring(imageURL.lastIndexOf("/") + 1)}
                    </Typography>
                  ) : (
                    <Typography variant="body1" color="black">
                      No Image Choosen{" "}
                    </Typography>
                  )}
                  <Box
                    sx={{
                      marginLeft: "auto", // Pushes the CloseIcon to the right side
                      alignSelf: "center", // Centers vertically
                      cursor: "pointer",
                    }}
                  >
                    <Tooltip title="Remove Selected Image" arrow>
                      <IconButton
                        edge="end"
                        aria-label="add"
                        onClick={handleCancel}
                        sx={{ color: "black" }}
                        size="large"
                      >
                        <CloseIcon fontSize="large" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Grid>
              </Paper>
              {(file || imageURL) && (
                <Paper elevation={12} sx={{ padding: "20px", mt: 2 }}>
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="flex-start"
                  >
                    <Grid container justifyContent="center" alignItems="center" sx={{ mb: 2 }}>
                      <Typography variant="h6" component="h2" justifyContent="center">
                        Selected Image: {"  "}
                        {file ? file.name : imageURL.substring(imageURL.lastIndexOf("/") + 1)}
                      </Typography>
                    </Grid>
                    <Grid container justifyContent="center" alignItems="center">
                      <img
                        src={file ? URL.createObjectURL(file) : imageURL}
                        alt="Uploaded File"
                        style={{ maxWidth: "100%" }}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              )}
            </>
          )}
        </DialogContent>
      </PerfectScrollbar>

      <Divider sx={{ bgcolor: "black" }} />

      <DialogActions>
        <Button
          variant="outlined"
          color="primary"
          onClick={state.topicAction === "add" ? handleAdd : handleEdit}
          startIcon={state.topicAction === "add" ? <SaveIcon /> : <BorderColorIcon />}
        >
          {state.topicAction === "add" ? "Add" : "Edit"}
        </Button>
        <Button variant="outlined" color="error" onClick={handleClose} startIcon={<CloseIcon />}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddEditTopicModal;

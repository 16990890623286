import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import AddTimingModal from "./AddTimingModal";
import TimingsTable from "./TimingsTable";
import axios from "axios";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const WeeklyTimings = () => {
  const [timings, setTimings] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedTiming, setSelectedTiming] = useState(null);

  useEffect(() => {
    fetchAllTimings();
  }, []);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleCancelClick = () => {
    setSelectedTiming(null);
    handleCloseModal();
  };

  const handleSaveTiming = (newTiming) => {
    const adjustedTiming = {
      ...newTiming,
      from: convertTo12HourFormat(newTiming.from),
      to: convertTo12HourFormat(newTiming.to),
    };
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/company-timings/post/new/timings`, adjustedTiming)
      .then((response) => {
        if (response.status === 201) {
          console.log("New timing created:", response.data);
          toast.success("Timings Added Successfully");

          fetchAllTimings();
        } else {
          console.error("Error creating new timing. Unexpected status:", response.status);
        }
      })
      .catch((error) => {
        console.error("Error creating new timing:", error);
      });
  };

  const fetchAllTimings = () => {
    // Fetch all timings
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/company-timings/get/all/timings`)
      .then((response) => {
        if (response.status === 200) {
          console.log("All timings fetched successfully:", response.data);
          setTimings(response.data.data);
        } else {
          console.error("Error fetching timings. Unexpected status:", response.status);
        }
      })
      .catch((error) => {
        console.error("Error fetching timings:", error);
      });
  };

  const convertTo12HourFormat = (time) => {
    const [hours, minutes, ampm] = time.split(/[:\s]/);
    let hour = parseInt(hours, 10);
    const adjustedHour = hour % 12 || 12;
    return `${adjustedHour}:${minutes} ${ampm}`;
  };

  const handleEdit = (timing) => {
    setSelectedTiming(timing);
    setOpenModal(true);
    console.log("selectedTiming", selectedTiming);
  };

  const updateTiming = (updatedTiming) => {
    const adjustedTiming = {
      ...updatedTiming,
      from: convertTo12HourFormat(updatedTiming.from),
      to: convertTo12HourFormat(updatedTiming.to),
    };
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/company-timings/post/update/${selectedTiming.timeId}`,
        adjustedTiming,
      )
      .then((response) => {
        if (response.status === 200) {
          console.log("Timing updated successfully:", response.data);
          toast.success("Timing Edited Successfully");
          fetchAllTimings();
          setSelectedTiming(null);
          setOpenModal(false);
        } else {
          console.error("Error updating timing. Unexpected status:", response.status);
        }
      })
      .catch((error) => {
        console.error("Error updating timing:", error);
      });
  };

  const handleDeleteTiming = (timingId) => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}/api/company-timings/delete/${timingId}`)
      .then((response) => {
        if (response.status === 200) {
          console.log("Timing deleted successfully", response.data);
          toast.success("Timing Deleted Successfully");
          setTimings(timings.filter((timing) => timing._id !== timingId));
          setSelectedTiming(null);
        } else {
          console.error("Error deleting timing. Unexpected status:", response.status);
        }
      })
      .catch((error) => {
        console.error("Error deleting timing:", error);
      });
  };

  const handleOpenDeleteConfirmation = (timingId) => {
    console.log("TimingId", timingId);
    setSelectedTiming(timingId);

    Swal.fire({
      title: "Confirm Deletion",
      text: "Are you sure you want to delete this disease?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDeleteTiming(timingId);
      }
    });
  };

  return (
    <>
      <div style={{ textAlign: "right", marginBottom: "20px" }}>
        <Button
          onClick={handleOpenModal}
          variant="contained"
          sx={{
            color: "primary",
          }}
        >
          Add Timing
        </Button>
      </div>

      <AddTimingModal
        open={openModal}
        handleClose={handleCancelClick}
        handleSave={selectedTiming ? updateTiming : handleSaveTiming}
        selectedTiming={selectedTiming}
      />
      <TimingsTable
        timings={timings}
        handleUpdate={handleEdit}
        handleDelete={handleOpenDeleteConfirmation}
      />
    </>
  );
};

export default WeeklyTimings;

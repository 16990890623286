import React, { useState } from "react";
import { Dialog, DialogTitle, Divider, DialogContent, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddNewOfflineUserFields from "./AddNewOfflineUserFields";
import AddNewOfflineUserOTP from "./AddNewOfflineUserOTP";
import { motion } from "framer-motion";

const AddNewOfflineUser = ({
  isOpen,
  modalClose,
  userData,
  setUserData,
  loading,
  setLoading,
  isMobile,
}) => {
  const [showOTP, setShowOTP] = useState(false);
  const [numberChange, setNumberChange] = useState(false);
  const [component, setComponent] = useState("fields component");
  const [otp, setOtp] = useState("");

  const variants = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: 20 },
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Dialog
        onClose={modalClose}
        aria-labelledby="simple-dialog-title"
        open={isOpen}
        fullWidth
        maxWidth={isMobile ? "lg" : "md"}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "white",
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: isMobile ? "1.2rem" : "1.4rem",
            fontWeight: "bold",
          }}
        >
          <strong>Add Offline User</strong>
          <IconButton onClick={modalClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider sx={{ bgcolor: "black" }} />
        <DialogContent sx={{ overflow: "hidden" }}>
          {component === "fields component" && (
            <AddNewOfflineUserFields
              userData={userData}
              setUserData={setUserData}
              showOTP={showOTP}
              setShowOTP={setShowOTP}
              loading={loading}
              setLoading={setLoading}
              numberChange={numberChange}
              setNumberChange={setNumberChange}
              setComponent={setComponent}
              variants={variants}
              isMobile={isMobile}
            />
          )}
          {component === "otp component" && (
            <AddNewOfflineUserOTP
              userData={userData}
              setUserData={setUserData}
              otp={otp}
              setOtp={setOtp}
              loading={loading}
              setLoading={setLoading}
              setComponent={setComponent}
              modalClose={modalClose}
              variants={variants}
              isMobile={isMobile}
            />
          )}
        </DialogContent>
      </Dialog>
    </motion.div>
  );
};

export default AddNewOfflineUser;

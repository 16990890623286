import React, { useState, useEffect } from "react";
import axios from "axios";
import { DNA } from "react-loader-spinner";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { List, ListItem, ListItemText, Paper, Typography } from "@mui/material";

const CategoryList = ({ state, dispatch, isMobile }) => {
  const [categories, setCategories] = useState([]);
  const [showLoading, setShowLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setShowLoading(true);
        if (state.selectedCategoryGroupItem) {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/qna/get/all/category/${state.selectedCategoryGroupItem._id}`,
          );

          if (response && response.status === 200 && response.data && response.data.data) {
            setCategories(response.data.data);
          } else {
            setCategories([]);
            toast.error("Error fetching categories, Please Try after sometime");
          }
        } else {
          setCategories([]);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);

        if (error.response && [400, 404, 409, 500].includes(error.response.status)) {
          switch (error.response.status) {
            case 400:
              toast.error("Bad request. Please check your inputs");
              break;
            case 404:
              toast.error("Categories not found");
              break;
            case 409:
              toast.error("There is a conflict with the current state of the categories");
              break;
            case 500:
              toast.error("Server error while fetching categories, Please Try after sometime");
              break;
            default:
              toast.error("An error occurred while fetching categories, Please Try after sometime");
          }
        } else {
          toast.error("Error fetching categories, Please Contact Developer");
        }
        setCategories([]);
      }
      setShowLoading(false);
    };

    fetchData();
  }, [state.categoryRefreshFlag, state.selectedCategoryGroupItem, dispatch]);

  const handleItemClick = (item) => {
    dispatch({ type: "set_selected_category_item", payload: item });
    dispatch({ type: "set_selected_subcategory_item", payload: "" });
    dispatch({ type: "set_selected_question_item", payload: "" });
  };

  return (
    <div style={{ marginTop: isMobile ? 40 : undefined }}>
      {showLoading ? (
        <motion.div>
          <DNA
            visible={true}
            height={isMobile ? "120" : "200"}
            width={isMobile ? "200" : "250"}
            color="#2B2A29"
            ariaLabel="dna-loading"
            wrapperStyle={{}}
            wrapperClass="dna-wrapper"
          />
        </motion.div>
      ) : (
        <List>
          {categories && categories.length > 0 ? (
            categories.map((item, index) => (
              <Paper
                key={item._id}
                elevation={6}
                sx={{
                  borderRadius: "20px",
                  mb: 1,
                  mx: 1,
                  overflow: "hidden",
                }}
              >
                <ListItem
                  component="div"
                  key={index}
                  onClick={() => handleItemClick(item)}
                  sx={{
                    backgroundColor:
                      state.selectedCategoryItem && state.selectedCategoryItem._id === item._id
                        ? "#890F3C"
                        : "white",
                    "&:hover": {
                      backgroundColor: "#E22C6F",
                      cursor: "pointer",
                    },
                    userSelect: "none", // Prevent text selection
                  }}
                >
                  <ListItemText
                    primary={
                      <Typography
                        sx={{
                          ml: 2,
                          fontSize: isMobile ? "1rem" : "1.3rem",
                          color:
                            state.selectedCategoryItem &&
                            state.selectedCategoryItem._id === item._id
                              ? "white"
                              : "black",
                        }}
                      >
                        {item.categoryHeading}
                      </Typography>
                    }
                  />
                </ListItem>
              </Paper>
            ))
          ) : (
            <Typography
              variant="body1"
              align="center"
              isMobile={isMobile}
              sx={{
                fontSize: isMobile ? "1.4rem" : "2rem",
                mt: "3%",
              }}
            >
              {state.selectedCategoryGroupItem
                ? "No Category found."
                : "Please select an option from Category Group."}
            </Typography>
          )}
        </List>
      )}
    </div>
  );
};

export default CategoryList;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { List, ListItem, ListItemText, Paper, Typography } from "@mui/material";
import { DNA } from "react-loader-spinner";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const QuestionsList = ({ state, dispatch, isMobile }) => {
  const [questions, setQuestions] = useState([]);
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (state.selectedCategoryItem) {
          setShowLoading(true);

          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/qna/get/each/category/questions/${state.selectedCategoryItem._id}`,
          );
          if (response && response.status === 200 && response.data && response.data.data) {
            setQuestions(response.data.data);
          } else {
            setQuestions([]);
            toast.error("Error fetching questions, Please Try after sometime");
          }
        } else {
          setQuestions([]);
        }
      } catch (error) {
        console.error("Error fetching questions", error);

        if (error.response && [400, 404, 409, 500].includes(error.response.status)) {
          switch (error.response.status) {
            case 400:
              toast.error("Bad request. Please check your inputs");
              break;
            case 404:
              toast.error("Questions not found");
              break;
            case 409:
              toast.error("There is a conflict with the current state of the questions");
              break;
            case 500:
              toast.error("Server error while fetching questions, Please Try after sometime");
              break;
            default:
              toast.error("An error occurred while fetching questions, Please Try after sometime");
          }
        } else {
          toast.error("No Questions Found,  Please Contact developer");
        }
        setQuestions([]);
      }
      setShowLoading(false);
    };

    fetchData();
  }, [state.questionsRefreshFlag, state.selectedCategoryItem, dispatch]);

  const handleItemClick = (item) => {
    dispatch({ type: "set_selected_question_item", payload: item });
  };

  return (
    <>
      {showLoading ? (
        <motion.div>
          <DNA
            visible={true}
            height={isMobile ? "120" : "200"}
            width={isMobile ? "200" : "250"}
            color="#2B2A29"
            ariaLabel="dna-loading"
            wrapperStyle={{}}
            wrapperClass="dna-wrapper"
          />
        </motion.div>
      ) : (
        <List>
          {questions && questions.length > 0 ? (
            questions.map((item, index) => (
              <Paper
                key={item._id}
                elevation={6}
                sx={{
                  borderRadius: "20px",
                  mb: 1,
                  mx: 1,
                  overflow: "hidden",
                }}
              >
                <ListItem
                  component="div"
                  key={index}
                  onClick={() => handleItemClick(item)}
                  sx={{
                    backgroundColor:
                      state.selectedQuestionItem && state.selectedQuestionItem._id === item._id
                        ? "#890F3C"
                        : "white",
                    "&:hover": {
                      backgroundColor: "#E22C6F",
                      cursor: "pointer",
                    },
                    userSelect: "none",
                  }}
                >
                  <ListItemText
                    primary={
                      <Typography
                        sx={{
                          ml: 2,
                          fontSize: isMobile ? "1rem" : "1.3rem",
                          color:
                            state.selectedQuestionItem &&
                            state.selectedQuestionItem._id === item._id
                              ? "white"
                              : "black",
                        }}
                      >
                        {item.questiontext}
                      </Typography>
                    }
                  />
                </ListItem>
              </Paper>
            ))
          ) : (
            <Typography variant="body1" align="center" fontSize="1.6rem" mt="3%">
              {state.selectedCategoryItem
                ? "No questions found."
                : "Please select an option from Category."}
            </Typography>
          )}
        </List>
      )}
    </>
  );
};

export default QuestionsList;
